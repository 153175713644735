import React, { useState, useEffect } from 'react';
import styles from './BoletimEditComponent.module.css';
import apiAxios from "../../../api/apiAxios";
import useTenantId from "../../../hooks/getTenantId";
import GetTenantId from '../../../hooks/getTenantId';

const BoletimEditComponent = ({ initialData = {} }) => {
    const tenantId = GetTenantId();

    // Estados para os campos do formulário
    const [anoLetivo, setAnoLetivo] = useState(initialData.anoLetivo || new Date().getFullYear().toString());
    const [descricao, setDescricao] = useState(initialData.descricao || "");
    const [nivelEnsino, setNivelEnsino] = useState(initialData.nivelEnsino || "");
    const [status, setStatus] = useState(initialData.status || "Ativo");
    const [periodType, setPeriodType] = useState(initialData.periodType || "Bimestral");
    const [responsaveis, setResponsaveis] = useState(initialData.responsaveis || [{ nome: "", cargo: "" }]);
    const [observacoesGerais, setObservacoesGerais] = useState(initialData.observacoesGerais || "");
    const [dataEncerramento, setDataEncerramento] = useState(initialData.dataEncerramento || "");
    const [tipoAvaliacaoMax, setTipoAvaliacaoMax] = useState(initialData.tipoAvaliacaoMax || "AV3");
    const [tiposAvaliacao, setTiposAvaliacao] = useState(initialData.tiposAvaliacao || ["AV1", "AV2", "AV3"]);
    const [mediaGeral, setMediaGeral] = useState(initialData.mediaGeral || null);
    const [areas, setAreas] = useState(initialData.areas || []);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [turmasInfantil, setTurmasInfantil] = useState(initialData.turmasInfantil || []);


    useEffect(() => {
        console.log("Initial Data atualizado:", initialData);
        if (initialData) {
            setAnoLetivo(initialData.anoLetivo || new Date().getFullYear().toString());
            setDescricao(initialData.descricao || "");
            setNivelEnsino(initialData.nivelEnsino || "");
            setStatus(initialData.status || "Ativo");
            setPeriodType(initialData.periodType || "Bimestral");
            setResponsaveis(initialData.responsaveis || [{ nome: "", cargo: "" }]);
            setObservacoesGerais(initialData.observacoesGerais || "");
            setDataEncerramento(initialData.dataEncerramento || "");
            setTipoAvaliacaoMax(initialData.tipoAvaliacaoMax || "AV3");
            setTiposAvaliacao(initialData.tiposAvaliacao || ["AV1", "AV2", "AV3"]);
            setMediaGeral(initialData.mediaGeral || null);
            setAreas(initialData.areas || []);
            setTurmasInfantil(initialData.turmasInfantil || []);
        }
    }, [initialData]);
    


    const handleTipoAvaliacaoChange = (value) => {
        setTipoAvaliacaoMax(value);
        const avaliacaoNumero = parseInt(value.replace("AV", ""), 10);
        const novasAvaliacoes = Array.from({ length: avaliacaoNumero }, (_, i) => `AV${i + 1}`);
        setTiposAvaliacao(novasAvaliacoes);
    };

    const handleAddResponsavel = () => {
        setResponsaveis([...responsaveis, { nome: "", cargo: "" }]);
    };

    const handleRemoveResponsavel = (index) => {
        const updated = responsaveis.filter((_, idx) => idx !== index);
        setResponsaveis(updated);
    };

    const handleResponsavelChange = (index, field, value) => {
        const updated = [...responsaveis];
        updated[index][field] = value;
        setResponsaveis(updated);
    };

    const handleAddArea = () => {
        setAreas([
            ...areas,
            {
                nome: "",
                descricao: "",
                competencias: [],
                avaliacao: tiposAvaliacao.map((tipo) => ({ tipo, nota: null })),
            },
        ]);
    };

    const handleRemoveArea = (index) => {
        const updatedAreas = areas.filter((_, idx) => idx !== index);
        setAreas(updatedAreas);
    };

    const handleAreaChange = (index, field, value) => {
        const updatedAreas = [...areas];
        updatedAreas[index][field] = value;
        setAreas(updatedAreas);
    };

    const handleCompetenciaChange = (areaIndex, competenciaIndex, value) => {
        const updatedAreas = [...areas];
        updatedAreas[areaIndex].competencias[competenciaIndex] = value;
        setAreas(updatedAreas);
    };

    const handleAddCompetencia = (areaIndex) => {
        const updatedAreas = [...areas];
        updatedAreas[areaIndex].competencias.push("");
        setAreas(updatedAreas);
    };

    const handleSaveBoletim = async () => {
        setMessage("");
        setError("");
    
        try {
            const normalizedPeriodType =
                periodType === "Bimestral" ? "Bimestre" :
                periodType === "Semestral" ? "Semestre" : periodType;
    
            const payload = {
                tenantId,
                anoLetivo,
                descricao,
                nivelEnsino,
                status,
                periodType: normalizedPeriodType,
                responsaveis,
                observacoesGerais,
                dataEncerramento,
                tiposAvaliacao,
                areas,
                mediaGeral,
                turmasInfantil,
            };
    
            if (initialData._id) {
                await apiAxios.put(`/api/boletims/boletins/${initialData._id}`, payload);
    
                // Buscar os dados atualizados do boletim e atualizar o estado
                const updatedResponse = await apiAxios.get(`/api/boletims/boletins/${initialData._id}`);
                const updatedBoletim = updatedResponse.data.data;
    
                setAnoLetivo(updatedBoletim.anoLetivo);
                setDescricao(updatedBoletim.descricao);
                setNivelEnsino(updatedBoletim.nivelEnsino);
                setStatus(updatedBoletim.status);
                setPeriodType(updatedBoletim.periodType);
                setResponsaveis(updatedBoletim.responsaveis);
                setObservacoesGerais(updatedBoletim.observacoesGerais);
                setDataEncerramento(updatedBoletim.dataEncerramento);
                setTiposAvaliacao(updatedBoletim.tiposAvaliacao);
                setMediaGeral(updatedBoletim.mediaGeral);
                setAreas(updatedBoletim.areas);
                setTurmasInfantil(updatedBoletim.turmasInfantil);
    
                setMessage("Boletim atualizado com sucesso!");
            } else {
                setError("Erro: não foi possível identificar o boletim para edição.");
            }
        } catch (error) {
            setError("Erro ao salvar o boletim. Tente novamente.");
        }
    };
    
    

    const handleAddTurmaInfantil = () => {
        setTurmasInfantil([...turmasInfantil, { serie: "", turno: "" }]);
    };

    const handleRemoveTurmaInfantil = (index) => {
        const updated = turmasInfantil.filter((_, idx) => idx !== index);
        setTurmasInfantil(updated);
    };

    const handleTurmaChange = (index, field, value) => {
        const updated = [...turmasInfantil];
        updated[index][field] = value;
        setTurmasInfantil(updated);
    };


    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <label>Ano Letivo</label>
                <select value={anoLetivo} onChange={(e) => setAnoLetivo(e.target.value)}>
                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                    <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                </select>

                <label>Descrição</label>
                <textarea
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    placeholder="Digite a descrição"
                />

                <label>Nível de Ensino</label>
                <select value={nivelEnsino} onChange={(e) => setNivelEnsino(e.target.value)}>
                    <option value="">Selecione o nível</option>
                    <option value="Educação Infantil">Educação Infantil</option>
                    <option value="Fundamental I">Fundamental I</option>
                    <option value="Fundamental II">Fundamental II</option>
                    <option value="Ensino Médio">Ensino Médio</option>
                </select>

                {nivelEnsino === "Educação Infantil" && (
                    <>
                        <label>Turmas (Série e Turno)</label>
                        {turmasInfantil.map((turma, index) => (
                            <div key={index} className={styles.turmaSection}>
                                <select value={turma.serie} onChange={(e) => handleTurmaChange(index, "serie", e.target.value)}>
                                    <option value="">Selecione a Série</option>
                                    <option value="Infantil I">Infantil I</option>
                                    <option value="Infantil II">Infantil II</option>
                                    <option value="Infantil III">Infantil III</option>
                                    <option value="Infantil IV">Infantil IV</option>
                                    <option value="Infantil V">Infantil V</option>
                                </select>

                                <select value={turma.turno} onChange={(e) => handleTurmaChange(index, "turno", e.target.value)}>
                                    <option value="">Selecione o Turno</option>
                                    <option value="Manhã">Manhã</option>
                                    <option value="Tarde">Tarde</option>
                                </select>

                                <button type="button" onClick={() => handleRemoveTurmaInfantil(index)} className={styles.removeButton}>
                                    Remover
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddTurmaInfantil} className={styles.addButton}>
                            Adicionar Turma
                        </button>
                    </>
                )}

                <label>Status</label>
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="Ativo">Ativo</option>
                    <option value="Inativo">Inativo</option>
                </select>

                <label>Data de Encerramento</label>
                <input
                    type="date"
                    value={dataEncerramento || ""}
                    onChange={(e) => setDataEncerramento(e.target.value)}
                />

                {nivelEnsino === "Educação Infantil" ? (
                    <>
                        <label>Áreas</label>
                        {areas.map((area, index) => (
                            <div key={index} className={styles.areaSection}>
                                <input
                                    type="text"
                                    placeholder="Nome da área"
                                    value={area.nome}
                                    onChange={(e) => handleAreaChange(index, "nome", e.target.value)}
                                />
                                <textarea
                                    placeholder="Descrição"
                                    value={area.descricao}
                                    onChange={(e) => handleAreaChange(index, "descricao", e.target.value)}
                                />
                                <label>Competências</label>
                                {area.competencias.map((competencia, compIndex) => (
                                    <input
                                        key={compIndex}
                                        type="text"
                                        placeholder="Competência"
                                        value={competencia}
                                        onChange={(e) => handleCompetenciaChange(index, compIndex, e.target.value)}
                                    />
                                ))}
                                <button
                                    type="button"
                                    onClick={() => handleAddCompetencia(index)}
                                    className={styles.addButton}
                                >
                                    Adicionar Competência
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(index)}
                                    className={styles.removeButton}
                                >
                                    Remover Área
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddArea} className={styles.addButton}>
                            Adicionar Área
                        </button>
                    </>
                ) : (
                    <>
                        <label>Tipos de Avaliação</label>
                        <select
                            value={tipoAvaliacaoMax}
                            onChange={(e) => handleTipoAvaliacaoChange(e.target.value)}
                        >
                            <option value="AV1">AV1</option>
                            <option value="AV2">AV2</option>
                            <option value="AV3">AV3</option>
                            <option value="AV4">AV4</option>
                            <option value="AV5">AV5</option>
                        </select>

                        <ul>
                            {tiposAvaliacao.map((avaliacao, index) => (
                                <li key={index}>{avaliacao}</li>
                            ))}
                        </ul>

                        <label>Média Geral</label>
                        <input
                            type="number"
                            value={mediaGeral || ""}
                            onChange={(e) => setMediaGeral(Number(e.target.value))}
                            placeholder="Digite a média geral"
                        />
                    </>
                )}

                <button type="button" onClick={handleSaveBoletim} className={styles.submitButton}>
                    Salvar Boletim
                </button>
            </div>

            {error && <p className={`${styles.message} ${styles.error}`}>{error}</p>}
            {message && <p className={`${styles.message} ${styles.success}`}>{message}</p>}
        </div>
    );
};

export default BoletimEditComponent;
