import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarioStructure2.module.css";
import DefaultButton from "../buttons/DefaultButton";
import CustomSelect from "../selects/CustomSelect";
import IconButton from "../buttons/IconButton";
import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from './CalendarioStructure2.module.css';
import "./CalendarioStructure2.global.css";

const CalendarioStructure2 = ({
    onDateSelect,
    mode = "calendar",
    leftButtons = [],
    leftSelects = [],
    agendas = [],
    onAgendaClick
}) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(selectedDate);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (onDateSelect) {
            onDateSelect(date);
        }

        const agendaForDate = agendas.find(agenda => parseDate(agenda.date).toDateString() === date.toDateString());
        if (agendaForDate) {
            onAgendaClick(agendaForDate);
        }

        console.log("onAgendaClick:", onAgendaClick);

    };

    const goToToday = () => {
        const today = new Date();
        setSelectedDate(today);
        setCurrentMonth(today); // Garante que o mês atualiza corretamente
        if (onDateSelect) {
            onDateSelect(today);
        }
    };

    const changeMonth = (offset) => {
        setCurrentMonth((prevMonth) => {
            const newMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + offset, 1);
            return newMonth;
        });
    };

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split(" ")[0].split("/");
        return new Date(year, month - 1, day); // 🔥 Ajuste no índice do mês (0-based no JS)
    };

    const tileClassName = ({ date, view }) => {
        const classes = ["custom-tile"];
        const today = new Date();

        // ✅ Agora só marca o dia atual no mês correto
        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            classes.push("highlight-today");
        }

        if (
            date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear()
        ) {
            classes.push("highlight-selected");
        }

        if (date.getDay() === 0 || date.getDay() === 6) {
            classes.push("highlight-weekend");
        }

        if (view === "month" && date.getMonth() !== currentMonth.getMonth()) {
            classes.push("highlight-other-month");
        }

        const agendaForDate = agendas.find(agenda => parseDate(agenda.date).toDateString() === date.toDateString());
        if (agendaForDate) {
            classes.push(agendaForDate.agendastatus ? "highlight-agenda-active" : "highlight-agenda-inactive");
        }

        return classes.join(" ");
    };

    return (
        <div className={`agenda-calendario ${styles.calendar_container}`}>
            <div className={styles.custom_header}>
                <div className={styles.header}>
                    <div className={styles.right}>
                        <div>
                            <DefaultButton text="Hoje" onClick={goToToday} size="medium" backgroundColor="#ffffff" borderColor="#d1d5db" color="#374151" />
                        </div>
                        <IconButton onClick={() => changeMonth(-1)} icon={ChevronLeft} size="medium" backgroundColor="#ffffff" borderColor="#d1d5db" color="#374151" />
                        <h3>{currentMonth.toLocaleString("pt-BR", { month: "long", year: "numeric" }).replace(/^\w/, c => c.toUpperCase())}</h3>
                        <IconButton onClick={() => changeMonth(1)} icon={ChevronRight} size="medium" backgroundColor="#ffffff" borderColor="#d1d5db" color="#374151" />
                    </div>
                    <div className={styles.left}>
                        {leftSelects.map((select, index) => (
                            <CustomSelect key={index} {...select} style={{ width: "100%" }} />
                        ))}
                        <div className={styles.vertical_line}></div>
                        {leftButtons.map((btn, index) => (
                            <DefaultButton key={index} {...btn} />
                        ))}
                    </div>
                </div>
            </div>
            {mode === "calendar" ? (
                <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    locale="pt-BR"
                    className="custom-calendar"
                    showNavigation={false}
                    tileClassName={tileClassName}
                    activeStartDate={currentMonth} // 🔥 Agora muda corretamente o mês
                    height="100vh"
                />
            ) : (
                <div className="agenda-view">
                    <h3>Agenda do dia {selectedDate.toLocaleDateString("pt-BR")}</h3>
                    <p>Adicione eventos e atividades aqui.</p>
                </div>
            )}
        </div>
    );
};

export default CalendarioStructure2;
