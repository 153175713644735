import React, { useEffect, useState } from 'react';
import styles from './PlanosComponentCreate.module.css';
import apiAxios from '../../../api/apiAxios';
import { series, turno as turnos } from '../../../DataSeries';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetTenantId from '../../../hooks/getTenantId';

const PlanosComponentCreate = ({ onSave, onClose, accessLevel, allowedSeries, selectedSerie, selectedAno, selectedTurno }) => {
    const tenantId = GetTenantId();
    const seriesToShow = Array.isArray(allowedSeries) && allowedSeries.length > 0 ? allowedSeries : series;

    // Inicializa o estado usando os valores selecionados
    const [serie, setSerie] = useState(selectedSerie || seriesToShow[0] || '');
    const [ano, setAno] = useState(selectedAno || new Date().getFullYear());
    const [turnoSelecionado, setTurnoSelecionado] = useState(selectedTurno || turnos[0] || '');
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [materias, setMaterias] = useState([]);
    const [existingPlanos, setExistingPlanos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [anosDisponiveis, setAnosDisponiveis] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const mesesInicial = [
        { mes: 'Janeiro', plano: [] },
        { mes: 'Fevereiro', plano: [] },
        { mes: 'Março', plano: [] },
        { mes: 'Abril', plano: [] },
        { mes: 'Maio', plano: [] },
        { mes: 'Junho', plano: [] },
        { mes: 'Julho', plano: [] },
        { mes: 'Agosto', plano: [] },
        { mes: 'Setembro', plano: [] },
        { mes: 'Outubro', plano: [] },
        { mes: 'Novembro', plano: [] },
        { mes: 'Dezembro', plano: [] },
    ];

    const [meses, setMeses] = useState(mesesInicial);

    useEffect(() => {
        const fetchPlanos = async () => {
            try {
                const response = await apiAxios.get(`/api/planos/planos?tenantId=${tenantId}`);
                setExistingPlanos(response.data);

                // Extrair anos únicos dos planos existentes e adicionar ano atual e próximo
                const anosDosPlanos = response.data.map(plano => parseInt(plano.ano, 10));
                const anoAtual = new Date().getFullYear();
                const anoProximo = anoAtual + 1;

                // Adicionar ano atual e próximo ano se ainda não existirem na lista
                const anosUnicos = Array.from(new Set([...anosDosPlanos, anoAtual, anoProximo])).sort();
                setAnosDisponiveis(anosUnicos);

                // Definir o ano padrão como o ano do plano mais recente ou o ano atual
                const anoMaisRecente = anosDosPlanos.length > 0 ? Math.max(...anosDosPlanos) : anoAtual;
                setAno(anoMaisRecente);
            } catch (error) {
                toast.error('Erro ao buscar planos existentes.');
            } finally {
                setIsLoading(false);
            }
        };

        if (tenantId) {
            fetchPlanos();
        }
    }, [tenantId]);

    useEffect(() => {
        const planoExistente = existingPlanos.some(plano =>
            plano.serie === serie && plano.turno === turnoSelecionado && plano.ano === String(ano)
        );

        if (planoExistente) {
            toast.error('Já existe um plano para essa série, turno e ano!');
            setMaterias([]);
            setMeses(mesesInicial);
        } else if (tenantId && serie) {
            fetchMaterias();
        }
    }, [serie, turnoSelecionado, ano, existingPlanos]);

    const fetchMaterias = async () => {
        try {
            const response = await apiAxios.get(`/api/mat/materias`, {
                params: { tenantId, serie: serie.trim() },
            });
            const filteredMaterias = response.data[0]?.materia.map(materia => ({
                id: materia._id,
                materia: materia.materia,
            })) || [];
            setMaterias(filteredMaterias);
        } catch (error) {
            toast.error('Nenhuma matéria existente nessa série.');
        }
    };

    useEffect(() => {
        if (materias.length > 0) {
            const updatedMeses = meses.map(mes => ({
                ...mes,
                plano: materias.map(materia => ({
                    id: materia.id,
                    materia: materia.materia,
                    descricao: ''
                }))
            }));
            setMeses(updatedMeses);
        } else {
            setMeses(mesesInicial);
        }
    }, [materias]);

    const handleChangePlanejamento = (mesIndex, planoIndex, field, value) => {
        const newMeses = [...meses];
        newMeses[mesIndex].plano[planoIndex][field] = value;
        setMeses(newMeses);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) {
            return; // Impede múltiplos envios
        }

        const planoExistente = existingPlanos.some(plano =>
            plano.serie === serie && plano.turno === turnoSelecionado && plano.ano === String(ano)
        );

        if (planoExistente) {
            toast.error('Já existe um plano para essa série, turno e ano!');
            return;
        }

        const newPlano = { tenantId, titulo, descricao, planejamento: meses, serie, turno: turnoSelecionado, ano };

        try {
            setIsSubmitting(true); // Bloqueia múltiplos cliques
            const response = await apiAxios.post('/api/planos/planos', newPlano);
            toast.success('Plano criado com sucesso!');
            onSave(response.data);
        } catch (error) {
            toast.error('Erro ao criar o plano.');
        } finally {
            setIsSubmitting(false); // Reabilita o botão após a conclusão
        }
    };

    return (
        <div className={styles.formContainer}>
            <ToastContainer />
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.layout}>
                    <div className={styles.formGroup}>
                        <p>Informações básicas do plano</p>
                        <input
                            placeholder='Título'
                            type="text"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            maxLength={100}
                            required
                        />
                        <textarea
                            placeholder='Descrição'
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            maxLength={500}
                            required
                        />

                        {accessLevel === 'high' && (
                            <select value={serie} onChange={(e) => setSerie(e.target.value)} required>
                                {seriesToShow.map((serieOption, index) => (
                                    <option key={index} value={serieOption}>{serieOption}</option>
                                ))}
                            </select>
                        )}

                        {accessLevel === 'low' && (
                            <input type="text" value={serie} readOnly disabled />
                        )}

                        <select value={turnoSelecionado} onChange={(e) => setTurnoSelecionado(e.target.value)} required>
                            {turnos.map((turnoOption, index) => (
                                <option key={index} value={turnoOption}>{turnoOption}</option>
                            ))}
                        </select>

                        <select value={ano} onChange={(e) => setAno(e.target.value)} required>
                            {anosDisponiveis.map((anoOption, index) => (
                                <option key={index} value={anoOption}>{anoOption}</option>
                            ))}
                        </select>

                        {isLoading ? (
                            <div>Carregando...</div>
                        ) : (
                            materias.length === 0 ? (
                                <div></div>
                            ) : (
                                meses.map((mesItem, mesIndex) => (
                                    <div key={mesIndex} className={styles.planejamentoGroup}>
                                        <h3>Planejamento do mês: {mesItem.mes}</h3>
                                        {mesItem.plano.map((planoItem, planoIndex) => (
                                            <div key={planoIndex}>
                                                <input
                                                    type="text"
                                                    value={planoItem.materia}
                                                    readOnly
                                                    disabled
                                                />
                                                <textarea
                                                    placeholder='Descrição'
                                                    value={planoItem.descricao}
                                                    onChange={(e) => handleChangePlanejamento(mesIndex, planoIndex, 'descricao', e.target.value)}
                                                    maxLength={500}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))
                            )
                        )}
                    </div>
                    <div className={styles.controlButton}>
                        <button
                            type="submit"
                            className={styles.submitButton}
                            disabled={isSubmitting} // Desativa o botão durante o envio
                        >
                            {isSubmitting ? 'Criando...' : 'Criar Plano'}
                        </button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PlanosComponentCreate;
