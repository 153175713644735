import React, { useEffect, useState } from 'react';
import styles from './RequestsComponent.module.css';
import InterfaceStructure from '../../../shared/InterfaceStructure';
import { MdCalendarToday, MdKeyboardArrowLeft, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../../components/FormatNumber';
import { deleteRequestById, getAllRequests } from '../../../../actions/RequestActions';
import { FcApprove, FcDisapprove } from "react-icons/fc";
import { approveRequest } from '../../../../actions/UserActions';
import { IoMdTime } from "react-icons/io";
import { resendVerificationEmail } from '../../../../actions/RequestActions';
import { MdOutlineMarkEmailRead } from "react-icons/md"; // Ícone de envio de e-mail

const RequestsComponent = ({ itemsPages }) => {
    const itemsPagesList = itemsPages;

    const [data, setData] = useState([]);
    const [resendTimers, setResendTimers] = useState(() => {
        const storedTimers = JSON.parse(localStorage.getItem('resendTimers')) || {};
        return storedTimers;
    });

    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const IdUserLogged = tokenPayload?._id;



    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = await getAllRequests(tokenPayload.tenantId);
                const mappedRequests = requests
                    .filter(request => request._id !== IdUserLogged) // Filtra o usuário logado
                    .filter(request => !request.isAdmin && !request.isAdminMaster) // Filtra admin e adminmaster
                    .reverse()
                    .map(request => ({
                        id: request._id,
                        Number: formatPhoneNumber(request.phoneNumber),
                        user: request.firstName,
                        usersecond: request.lastName,
                        email: request.email,
                        admin: request.isAdmin,
                        coordenacao: request.isCoordenaçao,
                        nutricionista: request.isNutricionista,
                        Professor: request.isProfessor,
                        Aluno: request.isAluno,
                        Responsavel: request.isResponsavel,
                        emailVerified: request.emailVerified
                    }));
                setData(mappedRequests);
            } catch (error) {
                console.error("Erro ao obter solicitações:", error);
            }
        };
        fetchData();
    }, [tokenPayload.tenantId]);

    const handleApproveRequest = async (requestId) => {
        try {
            await approveRequest(requestId);
            setData(prevData => prevData.filter(request => request.id !== requestId));
        } catch (error) {
            console.error("Erro ao aprovar solicitação:", error);
        }
    };

    const handleDeleteRequest = async (requestId) => {
        try {
            await deleteRequestById(requestId);
            setData(prevData => prevData.filter(request => request.id !== requestId));
        } catch (error) {
            console.error("Erro ao excluir solicitação:", error);
        }
    };

    const columns = [
        {
            field: 'action',
            headerName: 'Ação',
            width: 400,
            renderCell: (params) => {
                const { email } = params.row;
                const timeLeft = resendTimers[email] || 0;

                return (
                    <div className={styles.actionContainer}>
                        {/* Botão de Aprovação (só aparece se emailVerified for true) */}
                        {params.row.emailVerified ? (
                            <FcApprove
                                className={styles.Aprove}
                                onClick={() => handleApproveRequest(params.row.id)}
                            />
                        ) : (
                            <div className={styles.timeVerify}>
                                <IoMdTime style={{ fontSize: "20px" }} />
                                {!timeLeft > 0 && (
                                    <span> Aguardando verificação</span>
                                )}
                                {/* Se o tempo ainda não expirou, mostra o contador */}
                                {timeLeft > 0 ? (
                                    <span className={styles.timer}> Reenviar em {timeLeft}s</span>
                                ) : (
                                    <button
                                        className={styles.resendButton}
                                        onClick={() => handleResendEmail(email)}
                                    >
                                        <MdOutlineMarkEmailRead style={{ fontSize: "18px", marginRight: "5px" }} />
                                        Reenviar Email
                                    </button>
                                )}
                            </div>
                        )}
                        {/* Botão de Reprovação (sempre visível) */}
                        <FcDisapprove
                            className={styles.Reprove}
                            onClick={() => handleDeleteRequest(params.row.id)}
                        />
                    </div>
                );
            },
        },
        { field: 'user', headerName: 'Nome', width: 200 },
        { field: 'usersecond', headerName: 'Sobrenome', width: 200 },
        { field: 'email', headerName: 'Email', width: 230 },
        { field: 'Number', headerName: 'Número', width: 180 },
        { field: 'coordenacao', headerName: 'Coordenação', width: 180 },
        { field: 'nutricionista', headerName: 'Nutricionista', width: 180 },
        { field: 'Professor', headerName: 'Professor', width: 180 },
        { field: 'Responsavel', headerName: 'Responsável', width: 180 },
        { field: 'Aluno', headerName: 'Aluno', width: 180 },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setResendTimers((prevTimers) => {
                const updatedTimers = { ...prevTimers };
                Object.keys(updatedTimers).forEach((email) => {
                    if (updatedTimers[email] > 0) {
                        updatedTimers[email] -= 1;
                    } else {
                        delete updatedTimers[email]; // Remove do estado quando o tempo expira
                    }
                });
                localStorage.setItem('resendTimers', JSON.stringify(updatedTimers));
                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleResendEmail = async (email) => {
        if (resendTimers[email] > 0) return; // Impede reenvio antes do tempo acabar

        try {
            await resendVerificationEmail(email);
            alert("Email de verificação reenviado com sucesso!");

            const expirationTime = 300; // 5 minutos (300 segundos)

            // Atualiza o estado e salva no localStorage
            setResendTimers(prevTimers => {
                const updatedTimers = { ...prevTimers, [email]: expirationTime };
                localStorage.setItem('resendTimers', JSON.stringify(updatedTimers));
                return updatedTimers;
            });

        } catch (error) {
            console.error("Erro ao reenviar e-mail:", error);
            alert("Erro ao reenviar o e-mail. Tente novamente.");
        }
    };
    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/'><MdKeyboardArrowLeft />Painel</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Solicitações de cadastro</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        ...
                    </div>
                </div>
                <div className={styles.userList}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={8}
                        disableSelectionOnClick
                        checkboxSelection
                    />
                </div>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
}

export default RequestsComponent