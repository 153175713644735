import { useState, useEffect } from 'react';
import apiAxios from '../../api/apiAxios'; // API centralizada
import moment from 'moment';

const API_URL = '/api/calendar'; // URL base da API

const useCalendario = (tenantId, date) => {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // ✅ Buscar todos os eventos automaticamente ao montar o componente
  useEffect(() => {
    if (tenantId) {
      fetchEventos(tenantId);
    }
  }, [tenantId]); // Executa apenas quando `tenantId` muda

  // ✅ Buscar eventos automaticamente ao mudar a data
  useEffect(() => {
    if (date && tenantId) {
      fetchEventosPorData(date, tenantId);
    }
  }, [date, tenantId]); // Só executa quando `date` ou `tenantId` mudarem

  const fetchEventos = async (tenantId) => {
    setLoading(true);
    try {
      const response = await apiAxios.get(`${API_URL}/all/${tenantId}`);

      const eventosCorrigidos = response.data.map(evento => ({
        ...evento,
        date: moment(evento.date, "DD/MM/YYYY").format("DD/MM/YYYY"), // ✅ Mantém apenas a data
        time: evento.time || "00:00:00"  // ✅ Mantém a hora separada
      }));

      setEventos(eventosCorrigidos);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Buscar evento por ID
  const fetchEventoById = async (id) => {
    setLoading(true);
    try {
      const response = await apiAxios.get(`${API_URL}/${id}`);
      return response.data;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Buscar eventos por data
  const fetchEventosPorData = async (date, tenantId) => {
    setLoading(true);
    try {
      const response = await apiAxios.get(`${API_URL}/by-date/${date}`, {
        params: { tenantId },
      });
      setEventos(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Criar um novo evento
  const criarEvento = async (eventoData) => {
    setLoading(true);

    console.log("🔴 Criando evento na API:", eventoData);
    try {
      const response = await apiAxios.post(API_URL, eventoData);
      setEventos((prev) => [...prev, response.data]); // Atualiza o estado
      return response.data;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Atualizar um evento existente
  const atualizarEvento = async (id, eventoData) => {
    setLoading(true);
    try {
      const response = await apiAxios.put(`${API_URL}/${id}`, eventoData);
      setEventos((prev) =>
        prev.map((evento) => (evento._id === id ? response.data : evento))
      );
      return response.data;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Deletar um evento
  const deletarEvento = async (id) => {
    setLoading(true);
    try {
      await apiAxios.delete(`${API_URL}/${id}`);
      setEventos((prev) => prev.filter((evento) => evento._id !== id));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const baixarCalendario = async (year, month) => {
    try {
      if (!tenantId) {
        throw new Error("Tenant ID não definido.");
      }

      const response = await apiAxios.get(`${API_URL}/generate-pdf/${tenantId}/${year}/${month}`, {
        responseType: 'blob', // Importante para downloads de arquivos
      });

      // Criar um link para download do arquivo PDF
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Calendario_${year}-${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Erro ao baixar calendário:", err); 
      setError(err.message);
    }
  };

  return {
    eventos,
    loading,
    error,
    fetchEventos,
    fetchEventoById,
    fetchEventosPorData,
    criarEvento,
    atualizarEvento,
    deletarEvento,
    baixarCalendario
  };
};

export default useCalendario;
