import React, { useState } from "react";
import styles from "./CustomSelect.module.css";

const CustomSelect = ({
    label = "Selecionar",
    options = [],
    value,
    onChange,
    size = "medium", // small, medium, large
    fullWidth = false,
    disabled = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`${styles.customSelectContainer} ${fullWidth ? styles.fullWidth : ""} ${isOpen ? styles.open : ""
                }`}
        >
            <select
                className={`${styles.customSelect} ${styles[size]} ${isOpen ? styles.active : ""}`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
            >
                <option value="" disabled hidden>
                    {label}
                </option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CustomSelect;
