import React, { useMemo } from 'react'; 
import { useSelector } from "react-redux";

const GetUserId = () => {
  const userInfo = useSelector(state => state.user.userInfo);

  // Evita que o userId seja recalculado constantemente
  const userId = useMemo(() => {
    if (!userInfo || !userInfo.token) return null; // Evita erro se token não existir
    return JSON.parse(atob(userInfo.token.split('.')[1]))._id; // 🔥 Agora retorna o userId
  }, [userInfo?.token]); // Só muda se `token` mudar

  return userId;
};

export default GetUserId;
