import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './BoletinsAlunosComponent.module.css';
import BasicPageStructure from '../../../shared/BasicPageStructure';
import InterfaceStructure from '../../../shared/InterfaceStructure';
import useTenantId from '../../../../hooks/getTenantId';
import { useSelector } from 'react-redux';
import { getAllUsers, getUserById } from '../../../../actions/UserActions';
import { turno as turnoOptions } from '../../../../DataSeries';
import { Pagination } from '@mui/material';
import apiAxios from '../../../../api/apiAxios';
import useUserRoles from '../../../useUserRoles/useUserRoles';
import useUserId from '../../../useUserId';
import GetTenantId from '../../../../hooks/getTenantId';

const ITEMS_PER_PAGE = 10;

const BoletinsAlunosComponent = ({ itemsPages, series = [], turno }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedTurno, setSelectedTurno] = useState('');
    const [nivelEnsino, setNivelEnsino] = useState('');
    const [turmas, setTurmas] = useState([]);
    const [seriesDisponiveis, setSeriesDisponiveis] = useState([]);
    const [turnosDisponiveis, setTurnosDisponiveis] = useState([]);

    const UserId = GetTenantId();
    const role = useUserRoles();
    const isProf = role?.roles?.isProfessor;

    const [Turma, setTurma] = useState([]);
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const { id } = useParams();

    const userInfo = useSelector((state) => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    const seriesPorNivel = {
        'Educação Infantil': ['Infantil I', 'Infantil II', 'Infantil III', 'Infantil IV', 'Infantil V'],
        'Fundamental I': ['1º ano', '2º ano', '3º ano', '4º ano', '5º ano'],
        'Fundamental II': ['6º ano', '7º ano', '8º ano', '9º ano'],
    };

    useEffect(() => {
        const fetchTurma = async () => {
            try {
                const userData = await getUserById(UserId);
                setTurma(userData?.professorInfo || []);
            } catch (error) {
                console.error("Erro ao buscar turmas do professor:", error);
            }
        };

        if (UserId) fetchTurma();
    }, [UserId]);

    useEffect(() => {
        if (isProf && Turma.length === 0) return;

        const fetchNivelEnsino = async () => {
            try {
                const response = await apiAxios.get(`/api/boletims/boletins/${id}`, {
                    params: { tenantId },
                });
                const boletim = response?.data?.data;
                setNivelEnsino(boletim?.nivelEnsino);
                setTurmas(boletim?.turmasInfantil || []);

                let seriesFiltradas = [];
                let turnos = [];

                switch (boletim?.nivelEnsino) {
                    case "Educação Infantil":
                        if (isProf) {
                            seriesFiltradas = boletim?.turmasInfantil
                                ?.filter(turma =>
                                    Turma.some(profTurma =>
                                        profTurma.serie.trim().toLowerCase() === turma.serie.trim().toLowerCase() &&
                                        profTurma.turno.trim().toLowerCase() === turma.turno.trim().toLowerCase()
                                    )
                                )
                                .map(turma => turma.serie) || [];

                            turnos = boletim?.turmasInfantil
                                ?.filter(turma =>
                                    Turma.some(profTurma =>
                                        profTurma.serie.trim().toLowerCase() === turma.serie.trim().toLowerCase() &&
                                        profTurma.turno.trim().toLowerCase() === turma.turno.trim().toLowerCase()
                                    )
                                )
                                .map(turma => turma.turno) || [];
                        } else {
                            seriesFiltradas = boletim?.turmasInfantil?.map(turma => turma.serie) || [];
                            turnos = boletim?.turmasInfantil?.map(turma => turma.turno) || [];
                        }
                        break;

                    case "Fundamental I":
                    case "Fundamental II":
                        if (isProf) {
                            seriesFiltradas = Turma.map(t => t.serie);
                            turnos = [...new Set(Turma.map(t => t.turno))];
                        } else {
                            seriesFiltradas = seriesPorNivel[boletim.nivelEnsino] || [];
                            turnos = [...new Set(boletim?.turmasInfantil?.map(turma => turma.turno))] || turnoOptions;
                        }
                        break;

                    default:
                        console.warn("Nível de ensino não reconhecido:", boletim?.nivelEnsino);
                        break;
                }

                console.log("Séries Disponíveis:", seriesFiltradas);
                console.log("Turnos Disponíveis:", turnos);

                setSeriesDisponiveis(seriesFiltradas);
                setTurnosDisponiveis([...new Set(turnos)]);
                setSelectedSeries(seriesFiltradas.length > 0 ? seriesFiltradas[0] : '');
                setSelectedTurno(turnos.length > 0 ? turnos[0] : '');
            } catch (error) {
                console.error('Erro ao buscar nível de ensino:', error);
                setSeriesDisponiveis([]);
                setTurnosDisponiveis([]);
                setSelectedSeries('');
                setSelectedTurno('');
            }
        };

        fetchNivelEnsino();
    }, [id, tenantId, isProf, Turma]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await getAllUsers(tokenPayload.tenantId);
                const filteredUsers = users
                    .filter((user) => user.isAluno)
                    .filter((user) => user._id !== tokenPayload._id)
                    .reverse()
                    .map((user) => ({
                        id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email || 'N/A',
                        matricula: user.matricula || 'N/A',
                        CPF: user.CPF,
                        phoneNumber: user.phoneNumber,
                        alunoInfo: user.alunoInfo,
                    }));

                setUsers(filteredUsers);
                setFilteredUsers(filteredUsers);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao obter usuários:', error);
                setError('Erro ao carregar os dados.');
                setLoading(false);
            }
        };

        fetchData();
    }, [userInfo, tokenPayload.tenantId]);

    useEffect(() => {
        if (selectedSeries) {
            handleFilter(selectedSeries, selectedTurno);
        }
    }, [selectedSeries, users]);

    useEffect(() => {
        if (!selectedTurno) {
            if (turno && typeof turno === 'string') {
                setSelectedTurno(turno);
            } else if (turnoOptions.length > 0) {
                setSelectedTurno(turnoOptions[0]);
            }
        }
    }, [turno, turnoOptions, selectedTurno]);

    const handleViewBoletim = (alunoId) => {
        navigate(`/boletins/${id}/aluno/${alunoId}`);
    };

    const handleFilter = (newSeries = selectedSeries, newTurno = selectedTurno) => {
        let updatedUsers = users;

        if (newSeries) {
            updatedUsers = updatedUsers.filter((user) => user.alunoInfo?.serie === newSeries);
        }

        if (newTurno && typeof newTurno === "string") {
            updatedUsers = updatedUsers.filter((user) => {
                const turnoUsuario = user.alunoInfo?.turno || "";
                return turnoUsuario.toLowerCase() === newTurno.toLowerCase();
            });
        }

        setFilteredUsers(updatedUsers);
        setCurrentPage(1);
    };

    useEffect(() => {
        handleFilter(selectedSeries, selectedTurno);
    }, [selectedSeries, selectedTurno, users]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentUsers = filteredUsers.slice(startIndex, startIndex + ITEMS_PER_PAGE);



    const dashboardContent = (
        <div className={styles.userList}>
            <div className={styles.filters}>
                <div>
                    <label>Série:</label>
                    <select value={selectedSeries} onChange={(e) => setSelectedSeries(e.target.value)}>
                        {seriesDisponiveis.length > 0 ? (
                            seriesDisponiveis.map((serie, index) => (
                                <option key={index} value={serie}>
                                    {serie}
                                </option>
                            ))
                        ) : (
                            <option value="">Nenhuma série disponível</option>
                        )}
                    </select>
                </div>

                <div>
                    <label>Turno:</label>
                    <select value={selectedTurno} onChange={(e) => setSelectedTurno(e.target.value)}>
                        {turnosDisponiveis.map((turnoOption, index) => (
                            <option key={index} value={turnoOption}>
                                {turnoOption}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {loading ? (
                <p>Carregando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : currentUsers.length > 0 ? (
                <>
                    {currentUsers.map((user) => (
                        <div key={user.id} className={`${styles.userItem} ${styles.animateItem}`}>
                            <p>
                                <strong>Nome:</strong> {user.firstName} {user.lastName}
                            </p>
                            <p>
                                <strong>Matrícula:</strong> {user.matricula || 'Não informado'}
                            </p>
                            <p>
                                <strong>Série:</strong> {user.alunoInfo?.serie || 'Não informado'}
                            </p>
                            <p>
                                <strong>Turno:</strong> {user.alunoInfo?.turno || 'Não informado'}
                            </p>
                            <button
                                className={styles.viewButton}
                                onClick={() => handleViewBoletim(user.id)}
                            >
                                Ver Boletim
                            </button>
                        </div>
                    ))}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={Math.ceil(filteredUsers.length / ITEMS_PER_PAGE)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            className={styles.pagination}
                        />
                    </div>
                </>
            ) : (
                <p>Nenhum usuário encontrado.</p>
            )}
        </div>
    );

    const buttons = [];
    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={true}
                    returnLabel={'Voltar'}
                    returnPath={'/boletim'}
                    namePage="Alunos"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BoletinsAlunosComponent;
