import React from 'react';
import CardapioComponent from '../../../components/pagesComponents/CardapioComponents/CardapioComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import GetTenantId from '../../../hooks/getTenantId';

const CardapioPage = () => {
  const tenantId = GetTenantId();
  const pagesList = itemsPagesWithExclusions('Cardápio',[]);

  return (
    <>
      <CardapioComponent tenantId={tenantId} accessLevel="high" PagesList={pagesList}/>
    </>
  );
}

export default CardapioPage;
