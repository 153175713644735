import React, { useEffect, useState } from 'react';
import styles from './CardapioComponent.module.css';
import InterfaceStructure from '../../shared/InterfaceStructure';
import { Link, useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Pagination } from '@mui/material';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import PopUpComponent from '../../shared/popUp/PopUpComponent';
import CardapioEditPage from './CardapioComponentEditPage';
import imgNotFound from '../../../assets/notFound/OIP.jpg';
import ItemCardapioComponent from './ItemCardapioComponent/itemCardapioComponent';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import MiniPopUpComponent from '../../shared/popUp/MiniPopUpComponent';
import { FaRegFileExcel } from "react-icons/fa";
import tinycolor from 'tinycolor2';
import { MdOutlinePictureAsPdf } from "react-icons/md";
import BasicPageStructure from '../../shared/BasicPageStructure';

// Função para obter a cor do texto baseado na cor de fundo
const getTextColor = (bgColor) => {
  const color = tinycolor(bgColor);
  return color.isLight() ? '#000000' : '#FFFFFF';
};

const CardapioComponent = ({ tenantId, accessLevel = 'low', PagesList }) => {
  const [cardapios, setCardapios] = useState([]);
  const [showDownloadConfirm, setShowDownloadConfirm] = useState(false);
  const [cardapioToDownload, setCardapioToDownload] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [yearsArray, setYearsArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false);
  const [isCreateItemPopUpOpen, setIsCreateItemPopUpOpen] = useState(false);
  const [selectedCardapio, setSelectedCardapio] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [cardapioToDelete, setCardapioToDelete] = useState(null);
  const navigate = useNavigate();

  const isHighAccess = accessLevel === 'high';
  const itemsPagesList = PagesList;

  const fetchCardapios = async () => {
    if (!tenantId) {
      console.error('Tenant ID está ausente');
      return;
    }
    try {
      const [cardapiosRes, itensRes] = await Promise.all([
        apiAxios.get(`/api/card/${tenantId}`),
        apiAxios.get(`/api/card/item/items/${tenantId}`)
      ]);

      const itensMap = itensRes.data.reduce((acc, item) => {
        acc[item._id] = item.group?.Color || '#007bff';
        return acc;
      }, {});

      const cardapiosComCor = cardapiosRes.data.map(cardapio => {
        if (cardapio.alimentacao) {
          cardapio.alimentacao.forEach(alimentacao => {
            if (alimentacao.conteudo) {
              alimentacao.conteudo.forEach(conteudo => {
                if (conteudo.itemCardapioDetails) {
                  conteudo.itemCardapioDetails.forEach(item => {
                    item.categoryColor = itensMap[item._id] || '#007bff';
                  });
                }
              });
            }
          });
        }
        return cardapio;
      });


      setCardapios(cardapiosComCor);

      const cardapioYears = cardapiosRes.data.map((cardapio) => {
        const date = new Date(cardapio.dateFormatted || cardapio.date);
        return date.getFullYear();
      });

      const uniqueYears = new Set(cardapioYears);
      uniqueYears.add(new Date().getFullYear());
      uniqueYears.add(new Date().getFullYear() + 1);

      setYearsArray(Array.from(uniqueYears).sort((a, b) => a - b));
    } catch (error) {
      console.error('Erro ao buscar cardápios:', error);
    }
  };

  useEffect(() => {
    fetchCardapios();
  }, [tenantId]);

  useEffect(() => {
    if (yearsArray.length > 0 && !yearsArray.includes(selectedYear)) {
      setSelectedYear(new Date().getFullYear());
    }
  }, [yearsArray]);

  const confirmDownload = (cardapioId) => {
    setCardapioToDownload(cardapioId);
    setShowDownloadConfirm(true);
  };

  const handleDownloadConfirm = async () => {
    if (!cardapioToDownload) return;
    try {
      const response = await apiAxios.get(`/api/card/${tenantId}/excel/${cardapioToDownload}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cardapio_${cardapioToDownload}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o Excel:', error);
    }
    setShowDownloadConfirm(false);
    setCardapioToDownload(null);
  };

  const handleDownloadCancel = () => {
    setShowDownloadConfirm(false);
    setCardapioToDownload(null);
  };

  const requestDelete = (cardapioId) => {
    setCardapioToDelete(cardapioId);
    setShowDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    if (!isHighAccess || !cardapioToDelete) return;
    try {
      await apiAxios.delete(`/api/card/${tenantId}/${cardapioToDelete}`);
      setCardapios(cardapios.filter((cardapio) => cardapio._id !== cardapioToDelete));
      setShowDeleteConfirm(false);
      setCardapioToDelete(null);
    } catch (error) {
      console.error('Erro ao deletar cardápio:', error);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false);
    setCardapioToDelete(null);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const openEditPopUp = (cardapio) => {
    setSelectedCardapio(cardapio);
    setIsEditPopUpOpen(true);
  };

  const closeEditPopUp = () => {
    setIsEditPopUpOpen(false);
    setSelectedCardapio(null);
  };

  const openCreateItemPopUp = () => {
    setIsCreateItemPopUpOpen(true);
  };

  const closeCreateItemPopUp = () => {
    setIsCreateItemPopUpOpen(false);
  };

  const handleSaveCardapio = async (updatedCardapio) => {
    await fetchCardapios();
    closeEditPopUp();
  };

  const renderObservacao = (observacao) => {
    try {
      const contentState = convertFromRaw(JSON.parse(observacao));
      const editorState = EditorState.createWithContent(contentState);
      return { __html: stateToHTML(editorState.getCurrentContent()) };
    } catch (error) {
      console.error("Erro ao renderizar observação:", error);
      return { __html: "Observação indisponível" };
    }
  };

  const filteredCardapios = cardapios.filter((cardapio) => {
    const cardapioDate = new Date(cardapio.dateFormatted || cardapio.date);
    return (
      cardapioDate.getMonth() === selectedMonth &&
      cardapioDate.getFullYear() === selectedYear
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCardapios.slice(indexOfFirstItem, indexOfLastItem);

  const daysOfWeek = [
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
  ];
  const months = Array.from({ length: 12 }, (v, k) => ({
    value: k,
    label: new Date(0, k).toLocaleString('default', { month: 'long' }),
  }));

  const handleDownloadPDF = async (cardapioId) => {
    if (!cardapioId) return;

    try {
      const response = await apiAxios.get(`/api/card/${tenantId}/pdf/${cardapioId}`, {
        responseType: 'blob', // Define a resposta como um arquivo
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cardapio_${cardapioId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error);
    }
  };

  const dashboardContent = (
    <>
        <div className={styles.listCardapio}>
          <div className={styles.filters}>
            <div>
              <label>Ano:</label>
              <select value={selectedYear} onChange={handleYearChange}>
                {yearsArray.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Mês:</label>
              <select value={selectedMonth} onChange={handleMonthChange}>
                {months.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {currentItems.length === 0 ? (
            <div className={styles.notFound}>
              <img src={imgNotFound} alt="Não encontrado" />
            </div>
          ) : (
            currentItems.map((cardapio) => (
              <div className={styles.table_all} key={cardapio._id}>

                <div className={styles.action_buttons}>
                  <button
                    onClick={() => handleDownloadPDF(cardapio._id)}
                    className={styles.downloadPdfButton}
                  >
                    <MdOutlinePictureAsPdf />
                  </button>
                  <button
                    onClick={() => confirmDownload(cardapio._id)}
                    className={styles.downloadExcelButton}
                  >
                    <FaRegFileExcel />
                  </button>
                  {isHighAccess && (
                    <>
                      <button
                        onClick={() => openEditPopUp(cardapio)}
                        className={styles.updateButton}
                      >
                        <MdEdit />
                      </button>
                      <button onClick={() => requestDelete(cardapio._id)}>
                        <MdDeleteOutline />
                      </button>
                    </>
                  )}
                </div>
                <h2>
                  {cardapio.Titulo} (
                  &nbsp;
                  {
                    new Date(
                      new Date(cardapio.dateFormatted || cardapio.date).getTime() +
                      new Date().getTimezoneOffset() * 60000
                    ).toLocaleDateString()
                  }
                  &nbsp;
                  à
                  &nbsp;
                  {
                    new Date(
                      new Date(cardapio.dateFormattedEnd || cardapio.date).getTime() +
                      new Date().getTimezoneOffset() * 60000
                    ).toLocaleDateString()
                  }
                  &nbsp;
                  )
                </h2>
                <div className={styles.tableOverflow}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Refeição</th>
                        {daysOfWeek.map((day, index) => (
                          <th key={index}>{day}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {cardapio.alimentacao.map((alimentacao, index) => (
                        <tr key={index}>
                          <td className={styles.refeicao}>{alimentacao.refeicao}</td>
                          {daysOfWeek.map((day, dayIndex) => {
                            const conteudo = alimentacao.conteudo.find(c => c.diaDaSemana === day);
                            return (
                              <td key={dayIndex}>
                                {conteudo && conteudo.itemCardapioDetails && conteudo.itemCardapioDetails.length > 0 ? (
                                  <ul className={styles.itemList}>
                                    {conteudo.itemCardapioDetails.map((item, itemIndex) => (
                                      <li
                                        key={itemIndex}
                                        style={{
                                          backgroundColor: item.categoryColor,
                                          color: getTextColor(item.categoryColor),
                                          padding: '5px 10px',
                                          borderRadius: '5px'
                                        }}
                                        className={styles.itemListItem}
                                      >
                                        {console.log(item)}
                                        {item.titulo || "Nome Indisponível"}
                                        <div style={{ padding: "4px 6px", paddingLeft: "0px", textDecoration: "none", background: "transparent", fontWeight: "400" }}>
                                          {item.descricao || ""}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                ) : 'N/A'}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {cardapio.observacao && (
                  <div
                    className={styles.observacao}
                    dangerouslySetInnerHTML={renderObservacao(cardapio.observacao)}
                  />
                )}
              </div>
            ))
          )}

        {currentItems.length > 0 && (
          <Pagination
            count={Math.ceil(filteredCardapios.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            className={styles.pagination}
          />
        )}
      </div>

      <PopUpComponent
        title="Editar Cardápio"
        isOpen={isEditPopUpOpen}
        onClose={closeEditPopUp}
      >
        <CardapioEditPage cardapio={selectedCardapio} onSave={handleSaveCardapio} />
      </PopUpComponent>

      <PopUpComponent
        title="Adicionar Item ao Cardápio"
        isOpen={isCreateItemPopUpOpen}
        onClose={closeCreateItemPopUp}
      >
        <ItemCardapioComponent onClose={closeCreateItemPopUp} tenantId={tenantId} />
      </PopUpComponent>

      {showDeleteConfirm && (
        <MiniPopUpComponent
          title="Confirmação de Exclusão"
          message="Tem certeza que deseja excluir este cardápio?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </>
  );

  const buttons = [
    {
      label: 'Adicionar Item',
      icon: IoIosAddCircleOutline,
      onClick: openCreateItemPopUp,
    },
    {
      label: 'Adicionar Cardápio',
      icon: IoIosAddCircleOutline,
      onClick: () => navigate('/cardapio/create'),
    },
  ];

  return (
    <div>
      <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={
        <BasicPageStructure
          content={dashboardContent}
          buttons={buttons}
          returnPage={false}
          namePage="Cardápio"
          accessType={accessLevel}
        />
      } />

      {showDownloadConfirm && (
        <MiniPopUpComponent
          title="🗋 Confirmação de Download"
          message="Tem certeza de que deseja baixar este arquivo em Excel?"
          onConfirm={handleDownloadConfirm}
          onCancel={handleDownloadCancel}
        />
      )}
    </div>
  );
};

export default CardapioComponent;
