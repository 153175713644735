import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './itemCardapioComponent.module.css';
import apiAxios from '../../../../api/apiAxios';
import { readableColor } from 'polished';
import CategoriaCardapioCreateComponent from './CategoriaCardapioCreateComponent';
import ItemCardapioCreateComponent from './ItemCardapioCreateComponent';
import ItemCardapioEditComponent from './ItemCardapioEditComponent';
import MiniPopUpComponent from '../../../shared/popUp/MiniPopUpComponent';
import { FaPlus } from 'react-icons/fa6';
import { RiSubtractFill } from 'react-icons/ri';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ItemCardapioComponent = ({ tenantId }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const categoryPopupRef = useRef(null);
  const openButtonRef = useRef(null);

  const [isItemPopupOpen, setIsItemPopupOpen] = useState(false);
  const [isItemClosing, setIsItemClosing] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const itemPopupRef = useRef(null);

  const [categories, setCategories] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showItemDeleteConfirmation, setShowItemDeleteConfirmation] = useState(false);

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});


  // Função para buscar categorias e itens
  const fetchCategoriesAndItems = useCallback(async () => {
    try {
      const response = await apiAxios.get(`/api/card/cat/groups/${tenantId}`);
      const categoriesData = response.data;

      const categoriesWithItems = await Promise.all(
        categoriesData.map(async (category) => {
          const itemsResponse = await apiAxios.get(
            `/api/card/item/items/${tenantId}/category/${category._id}`
          );
          const items = itemsResponse.data;
          return { ...category, items };
        })
      );

      setCategories(categoriesWithItems);
    } catch (error) {
      console.error('Erro ao buscar categorias e itens:', error);
    }
  }, [tenantId]);

  useEffect(() => {
    fetchCategoriesAndItems();
  }, [fetchCategoriesAndItems]);

  const deleteCategory = async () => {
    try {
      if (categoryToDelete) {
        await apiAxios.delete(`/api/card/cat/groups/${tenantId}/${categoryToDelete}`);
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category._id !== categoryToDelete)
        );
        setShowConfirmationPopup(false);
      }
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
    }
  };

  const deleteItem = async () => {
    try {
      if (itemToDelete) {
        await apiAxios.delete(`/api/card/item/items/${tenantId}/${itemToDelete}`);
        setCategories((prevCategories) =>
          prevCategories.map((category) => ({
            ...category,
            items: category.items.filter((item) => item._id !== itemToDelete)
          }))
        );
        setShowItemDeleteConfirmation(false);
        setItemToDelete(null);
      }
    } catch (error) {
      console.error('Erro ao deletar item:', error);
    }
  };

  const handleDeleteClick = (categoryId) => {
    setCategoryToDelete(categoryId);
    setShowConfirmationPopup(true);
  };

  const handleDeleteItemClick = (itemId) => {
    setItemToDelete(itemId);
    setShowItemDeleteConfirmation(true);
  };

  const handleEditItemClick = (item) => {
    setItemToEdit(item);
    setIsEditPopupOpen(true);
  };

  const cancelDelete = () => {
    setShowConfirmationPopup(false);
    setCategoryToDelete(null);
  };

  const cancelItemDelete = () => {
    setShowItemDeleteConfirmation(false);
    setItemToDelete(null);
  };

  const toggleCategoryPopup = (event) => {
    event.stopPropagation();
    if (isPopupOpen) {
      handleCategoryClose();
    } else {
      setIsPopupOpen(true);
    }
  };

  const toggleCategoryExpansion = (categoryId) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };


  const handleCategoryClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setIsPopupOpen(false);
      setIsClosing(false);
    }, 300);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        categoryPopupRef.current &&
        !categoryPopupRef.current.contains(event.target) &&
        openButtonRef.current &&
        !openButtonRef.current.contains(event.target)
      ) {
        handleCategoryClose();
      }
    },
    [handleCategoryClose]
  );

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen, handleClickOutside]);

  const toggleItemPopup = (category) => {
    setSelectedCategory(category);
    if (isItemPopupOpen) {
      handleItemClose();
    } else {
      setIsItemPopupOpen(true);
    }
  };

  const handleItemClose = useCallback(() => {
    setIsItemClosing(true);
    setTimeout(() => {
      setIsItemPopupOpen(false);
      setIsItemClosing(false);
      setSelectedCategory(null);
    }, 300);
  }, []);

  const handleClickOutsideItem = useCallback(
    (event) => {
      if (itemPopupRef.current && !itemPopupRef.current.contains(event.target)) {
        handleItemClose();
      }
    },
    [handleItemClose]
  );

  useEffect(() => {
    if (isItemPopupOpen) {
      document.addEventListener('mousedown', handleClickOutsideItem);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideItem);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideItem);
    };
  }, [isItemPopupOpen, handleClickOutsideItem]);

  return (
    <>
      <div className={styles.actions}>
        <button
          onClick={toggleCategoryPopup}
          className={styles.openButton}
          ref={openButtonRef}
          aria-haspopup="dialog"
          aria-expanded={isPopupOpen}
        >
          +
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.categoryList}>
          {categories.length > 0 ? (
            <ul>
              {categories.map((category) => (
                <li key={category._id}>
                  <div
                    style={{
                      background: category.Color,
                      color: readableColor(category.Color)
                    }}
                    className={styles.action}
                  >
                    <div className={styles.right} onClick={() => toggleCategoryExpansion(category._id)}>
                      {expandedCategories[category._id] ? <FaChevronUp /> : <FaChevronDown />}
                      <strong>{category.Titulo}</strong>
                    </div>
                    <div className={styles.left}>
                      <button
                        onClick={() => toggleItemPopup(category)}
                        style={{ color: readableColor(category.Color) }}
                      >
                        <FaPlus />
                      </button>
                      <button onClick={() => handleDeleteClick(category._id)}>
                        <RiSubtractFill
                          style={{ color: readableColor(category.Color) }}
                        />
                      </button>
                    </div>
                  </div>
                  {expandedCategories[category._id] && (
                    <ul>
                      {category.items && category.items.length > 0 ? (
                        category.items.map((item) => (
                          <li key={item._id} className={styles.item}>
                            <div className={styles.itemContent}>
                              {item.imagem && (
                                <img
                                  src={item.imagem}
                                  alt={item.titulo}
                                  className={styles.itemImage}
                                />
                              )}
                              <div className={styles.itemDetails}>
                                <div className={styles.itemDetailsActions}>
                                  <button
                                    className={styles.updateButton}
                                    onClick={() => handleEditItemClick(item)}
                                  >
                                    <MdEdit />
                                  </button>
                                  <button onClick={() => handleDeleteItemClick(item._id)}>
                                    <MdDeleteOutline />
                                  </button>
                                </div>
                                <h3>{item.titulo}</h3>
                                {item.descricao && <p>{item.descricao}</p>}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className={styles.noItems}>Nenhum item nesta categoria.</li>
                      )}
                    </ul>
                  )}

                </li>
              ))}
            </ul>
          ) : (
            <p>Nenhuma categoria disponível.</p>
          )}
        </div>

        {showItemDeleteConfirmation && (
          <MiniPopUpComponent
            title="Confirmar exclusão"
            message="Tem certeza que deseja deletar este item?"
            onConfirm={deleteItem}
            onCancel={cancelItemDelete}
          />
        )}

        {showConfirmationPopup && (
          <MiniPopUpComponent
            title="Confirmar exclusão"
            message="Tem certeza que deseja deletar esta categoria?"
            onConfirm={deleteCategory}
            onCancel={cancelDelete}
          />
        )}

        {isEditPopupOpen && itemToEdit && (
          <div
            className={`${styles.popup} ${isItemClosing ? styles.closing : ''}`}
            role="dialog"
            aria-modal="true"
            tabIndex="-1"
          >
            <ItemCardapioEditComponent
              tenantId={tenantId}
              item={itemToEdit}
              onClose={() => setIsEditPopupOpen(false)}
              setCategories={setCategories}
            />
          </div>
        )}

        {isPopupOpen && (
          <div
            ref={categoryPopupRef}
            className={`${styles.popup} ${isClosing ? styles.closing : ''}`}
            role="dialog"
            aria-modal="true"
            tabIndex="-1"
            onClick={(event) => event.stopPropagation()}
          >
            <CategoriaCardapioCreateComponent
              tenantId={tenantId}
              onClose={handleCategoryClose}
              categories={categories}
              setCategories={setCategories}
              onCategoryAdded={fetchCategoriesAndItems} // Atualiza automaticamente após adicionar categoria
            />
          </div>
        )}

        {isItemPopupOpen && selectedCategory && (
          <div
            ref={itemPopupRef}
            className={`${styles.popup} ${isItemClosing ? styles.closing : ''}`}
            role="dialog"
            aria-modal="true"
            tabIndex="-1"
          >
            <ItemCardapioCreateComponent
              tenantId={tenantId}
              onClose={handleItemClose}
              category={selectedCategory}
              setCategories={setCategories}
              onItemAdded={fetchCategoriesAndItems} // Atualiza automaticamente após adicionar item
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemCardapioComponent;
