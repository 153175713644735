import React, { useEffect, useState } from 'react';
import NotificacoesComponent from '../../../components/pagesComponents/NotificacoesComponents/NotificacoesComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';

const NotificacoesProfessorPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Notificações', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio','Banco de horas']);
  const isAuthenticated = useSelector(state => state.user.userInfo?.token);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = isAuthenticated && JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload?.tenantId;
  const Id = tokenPayload?._id;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiAxios.get(`/api/user/users/${tenantId}`);
        const user = response?.data?.users?.find((u) => u._id === Id);
        console.log('User fetched:', user);
        setUser(user);
      } catch (error) {
        console.error('Error retrieving user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [tenantId, Id]);

  // Extração das séries e turnos do professorInfo
  const seriesRecebidas = user?.professorInfo?.map(info => info.serie) || [];
  const turnosRecebidas = user?.professorInfo?.map(info => info.turno) || [];

  console.log("Séries Recebidas:", seriesRecebidas);
  console.log("Turnos Recebidos:", turnosRecebidas);

  return (
    <NotificacoesComponent 
      itemsPages={itemsPagesList}  
      seriesRecebidas={seriesRecebidas} 
      turnosRecebidas={turnosRecebidas} 
    />
  );
};

export default NotificacoesProfessorPage;
