import { useState, useEffect } from "react";
import apiAxios from "../../api/apiAxios";

const API_URL = '/api/calendar/category'; // URL base da API

const useCategoryCalendario = (tenantId) => {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 🚀 Buscar categorias ao montar o componente
  useEffect(() => {
    if (!tenantId) return; // 🔥 Garante que não tente buscar sem um tenantId
    fetchCategorias();
  }, [tenantId]);

  // 🟢 Buscar categorias do backend
  const fetchCategorias = async () => {
    setLoading(true);
    try {
      const response = await apiAxios.get(`${API_URL}/${tenantId}`);
      setCategorias(response.data);
    } catch (err) {
      setError(err.message);
      console.error("Erro ao buscar categorias:", err);
    } finally {
      setLoading(false);
    }
  };

  // 🔥 Criar uma nova categoria
  const criarCategoria = async (novaCategoria) => {
    setLoading(true);
    try {
      const response = await apiAxios.post(`${API_URL}`, {
        tenantId,
        category: {
          name: novaCategoria.name,
          color: novaCategoria.color, // Permite definir a cor
        },
      });
      setCategorias((prev) => [...prev, response.data]); // Atualiza o estado local
      return response.data;
    } catch (err) {
      setError(err.message);
      console.error("Erro ao criar categoria:", err);
    } finally {
      setLoading(false);
    }
  };

  return {
    categorias,
    loading,
    error,
    fetchCategorias,
    criarCategoria,
  };
};

export default useCategoryCalendario;
