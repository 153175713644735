import React from "react";
import styles from "./SectionStructure.module.css";

const SectionStructure = ({ children, fixedHeight = false, height = "300px" }) => {
  return (
    <div className={`${styles.section} ${fixedHeight ? styles.fixed : styles.flexible}`} style={fixedHeight ? { height } : {}}>
      {children}
    </div>
  );
};

export default SectionStructure;
