import React, { useState, useEffect } from 'react';
import styles from './BoletimComponent.module.css';
import InterfaceStructure from '../../shared/InterfaceStructure';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import BasicPageStructure from '../../shared/BasicPageStructure';
import { IoIosAddCircleOutline } from "react-icons/io";
import PopUpComponent from '../../shared/popUp/PopUpComponent';
import BoletimCreateComponent from './BoletimCreateComponent';
import { Pagination } from '@mui/material';
import { MdEdit, MdDeleteOutline } from 'react-icons/md';
import BoletimEditComponent from './BoletimEditComponent';
import MiniPopUpComponent from '../../shared/popUp/MiniPopUpComponent';
import { getUserById } from '../../../actions/UserActions';
import useUserId from '../../useUserId';
import useUserRoles from '../../useUserRoles/useUserRoles';
import GetTenantId from '../../../hooks/getTenantId';

const ITEMS_PER_PAGE = 10;

const BoletimComponent = ({ PagesList, series, turno, accessLevel }) => {
    const [boletins, setBoletins] = useState([]);
    const [filteredBoletins, setFilteredBoletins] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [anosDisponiveis, setAnosDisponiveis] = useState([]);
    const [niveisEnsinoDisponiveis, setNiveisEnsinoDisponiveis] = useState([]);
    const [statusDisponiveis, setStatusDisponiveis] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [nivelEnsinoSelecionado, setNivelEnsinoSelecionado] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [editTarget, setEditTarget] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const navigate = useNavigate();
    const tenantId = GetTenantId();
    const UserId = useUserId();
    const role = useUserRoles();
    const isProf = role?.roles?.isProfessor;
    const [Turma, setTurma] = useState([]);

    useEffect(() => {
        const fetchTurma = async () => {
            try {
                const userData = await getUserById(UserId); // Aguarda a resposta
                setTurma(userData?.professorInfo); // Armazena no estado
            } catch (error) {
                console.error("Erro ao buscar usuário:", error);
            }
        };

        if (UserId) {
            fetchTurma(); // Chama a função assíncrona
        }
    }, [UserId]);

    const determinarNivelEnsino = (serie) => {
        const niveisEnsino = {
            "Educação Infantil": ["Infantil I", "Infantil II", "Infantil III", "Infantil IV", "Infantil V"],
            "Fundamental I": ["1º Ano", "2º Ano", "3º Ano", "4º Ano", "5º Ano"],
            "Fundamental II": ["6º Ano", "7º Ano", "8º Ano", "9º Ano"],
            "Ensino Médio": ["1ª Série", "2ª Série", "3ª Série"],
        };

        const normalize = (text) => text?.trim().toLowerCase();

        if (!serie || serie.length === 0) {
            return Object.keys(niveisEnsino);
        }

        if (Array.isArray(serie)) {
            const normalizedSeries = serie.map(normalize);

            const niveis = normalizedSeries.reduce((acc, s) => {
                for (const [nivel, series] of Object.entries(niveisEnsino)) {
                    if (series.map(normalize).includes(s) && !acc.includes(nivel)) {
                        acc.push(nivel);
                    }
                }
                return acc;
            }, []);

            return niveis.length > 0 ? niveis : ["Nível de ensino não identificado"];
        }

        const normalizedSerie = normalize(serie);
        for (const [nivel, series] of Object.entries(niveisEnsino)) {
            if (series.map(normalize).includes(normalizedSerie)) {
                return nivel;
            }
        }

        return "Nível de ensino não identificado";
    };

    useEffect(() => {
        const fetchBoletins = async () => {
            try {
                const response = await apiAxios.get('/api/boletims/boletins', {
                    params: { tenantId },
                });

                if (response.data && response.data.data) {
                    setBoletins(response.data.data);

                    const anos = [...new Set(response.data.data.map((boletim) => boletim.anoLetivo))];
                    setAnosDisponiveis(anos);

                    const niveis = determinarNivelEnsino(series);
                    setNiveisEnsinoDisponiveis(niveis);

                    const status = [...new Set(response.data.data.map((boletim) => boletim.status))];
                    setStatusDisponiveis(status);

                    setAnoSelecionado(anos[0] || '');
                    setNivelEnsinoSelecionado(niveis[0] || '');
                } else {
                    console.error('Resposta da API inválida:', response.data);
                    setBoletins([]);
                }
            } catch (error) {
                console.error('Erro ao buscar os boletins:', error);
                setBoletins([]);
            }
        };

        fetchBoletins();
    }, [tenantId, series]);

    useEffect(() => {
        let filtered = boletins;

        // Filtra pelo ano letivo, se estiver selecionado
        if (anoSelecionado) {
            filtered = filtered.filter((boletim) => boletim.anoLetivo === anoSelecionado);
        }

        // Filtra pelo nível de ensino, se estiver selecionado
        if (nivelEnsinoSelecionado) {
            filtered = filtered.filter((boletim) => boletim.nivelEnsino === nivelEnsinoSelecionado);
        }

        // Filtra pelo status, se estiver selecionado
        if (statusSelecionado) {
            filtered = filtered.filter((boletim) => boletim.status === statusSelecionado);
        }

        // 🚀 Se for professor, filtra os boletins que têm turmas compatíveis com as do professor
        if (isProf && Turma && Turma.length > 0) {
            filtered = filtered.filter((boletim) =>
                boletim.turmasInfantil.some((turma) =>
                    Turma.some(
                        (t) => t.serie === turma.serie && t.turno === turma.turno
                    )
                )
            );
        }

        setFilteredBoletins(filtered);
        setCurrentPage(1);
    }, [anoSelecionado, nivelEnsinoSelecionado, statusSelecionado, boletins, isProf, Turma]);


    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentItems = filteredBoletins.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleEdit = (boletim) => {
        setEditTarget(boletim); // Define o boletim selecionado para edição
        setEditPopupOpen(true); // Abre o pop-up de edição
    };

    const handleDelete = (id) => {
        setShowDeleteConfirm(true);
        setDeleteTarget(id);
    };

    const confirmDelete = async () => {
        try {
            await apiAxios.delete(`/api/boletims/boletins/${deleteTarget}`);
            setBoletins((prev) => prev.filter((boletim) => boletim._id !== deleteTarget));
            setShowDeleteConfirm(false);
        } catch (error) {
            console.error('Erro ao deletar boletim:', error);
        }
    };

    const cancelDelete = () => {
        setShowDeleteConfirm(false);
        setDeleteTarget(null);
    };

    const buttons = accessLevel === "professor" ? [] : [
        {
            label: 'Adicionar Boletim',
            icon: IoIosAddCircleOutline,
            onClick: () => setIsPopupOpen(true),
        },
    ];

    const dashboardContent = (
        <div className={styles.boletinsList}>
            <div className={styles.filters}>
                <div>
                    <label htmlFor="filterAno">Ano Letivo:</label>
                    <select
                        id="filterAno"
                        value={anoSelecionado}
                        onChange={(e) => setAnoSelecionado(e.target.value)}
                    >
                        <option value="">Todos os anos</option>
                        {anosDisponiveis.map((ano) => (
                            <option key={ano} value={ano}>
                                {ano}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="filterNivelEnsino">Nível de Ensino:</label>
                    <select
                        id="filterNivelEnsino"
                        value={nivelEnsinoSelecionado}
                        onChange={(e) => setNivelEnsinoSelecionado(e.target.value)}
                    >
                        {niveisEnsinoDisponiveis.map((nivel) => (
                            <option key={nivel} value={nivel}>
                                {nivel}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="filterStatus">Status:</label>
                    <select
                        id="filterStatus"
                        value={statusSelecionado}
                        onChange={(e) => setStatusSelecionado(e.target.value)}
                    >
                        <option value="">Todos os status</option>
                        {statusDisponiveis.map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {currentItems && currentItems.length > 0 ? (
                <div className={styles.boletinsContainer}>
                    {currentItems.map((boletim) => (
                        <div key={boletim._id} className={styles.boletimItem}>
                            <div className={styles.contentBoletimItem}>
                                {accessLevel === 'professor' ?
                                    <div style={{ paddingTop: "20px" }}></div>
                                    :
                                    <div className={styles.action_buttons}>
                                        <button
                                            onClick={() => handleEdit(boletim)}
                                            className={styles.updateButton}
                                        >
                                            <MdEdit />
                                        </button>
                                        <button onClick={() => handleDelete(boletim._id)}>
                                            <MdDeleteOutline />
                                        </button>
                                    </div>
                                }

                                <div className={styles.header}>
                                    <h3>{boletim.anoLetivo} - {boletim.nivelEnsino}</h3>
                                    <p className={styles.criadoPor} style={{ fontWeight: "800" }}><span style={{ color: "rgb(68, 63, 63)", fontWeight: "600" }}>Criado por:</span> {boletim.criadoPor || 'N/A'}</p>
                                </div>
                                <p className={styles.descricao}>{boletim.descricao}</p>
                                <p className={styles.dataCriacao}>Criado em: {boletim.dataCriacao}</p>
                                <p className={styles.status}>Status: {boletim.status}</p>
                                <p className={styles.semestre}>Periodo: {boletim.periodType}</p>
                                {boletim.nivelEnsino === "Educação Infantil" && boletim.turmasInfantil && boletim.turmasInfantil.length > 0 && (
                                    <div className={styles.turmasInfantil}>
                                        <h4>Turmas:</h4>
                                        <ul>
                                            {boletim.turmasInfantil.map((turma, index) => (
                                                <li key={index}>
                                                    {turma.serie} - {turma.turno}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {boletim.nivelEnsino === "Educação Infantil" && boletim.areas && (
                                    <div className={styles.areas}>
                                        <h4>Áreas:</h4>
                                        <ul>
                                            {boletim.areas.map((area, index) => (
                                                <li key={index}>
                                                    <strong>{area.nome}</strong>: {area.descricao}
                                                    {area.competencias && area.competencias.length > 0 && (
                                                        <ul>
                                                            {area.competencias.map((competencia, compIndex) => (
                                                                <li key={compIndex}>{competencia}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {boletim.nivelEnsino !== "Educação Infantil" && boletim.tiposAvaliacao && (
                                    <div className={styles.tiposAvaliacao}>
                                        <h4>Tipos de Avaliação:</h4>
                                        <ul>
                                            {boletim.tiposAvaliacao.map((tipo, index) => (
                                                <li key={index}>{tipo}</li>
                                            ))}
                                        </ul>
                                        {boletim.mediaGeral !== null && (
                                            <p className={styles.mediaGeral}>
                                                <strong>Média Geral:</strong> {boletim.mediaGeral}
                                            </p>
                                        )}
                                    </div>
                                )}

                                {boletim.responsaveis && (
                                    <div className={styles.responsaveis}>
                                        <h4>Responsáveis:</h4>
                                        <ul>
                                            {boletim.responsaveis.map((responsavel, index) => (
                                                <li key={index}>
                                                    {responsavel.nome} - {responsavel.cargo || "Sem cargo"}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {boletim.observacoesGerais && (
                                    <p className={styles.observacoes}>
                                        Observações: {boletim.observacoesGerais}
                                    </p>
                                )}
                                <button
                                    className={styles.visualizarButton}
                                    onClick={() => navigate(`/boletins/${boletim._id}`)}
                                >
                                    Visualizar Boletins dos alunos
                                </button>
                            </div>
                        </div>
                    ))}
                    <Pagination
                        count={Math.ceil(filteredBoletins.length / ITEMS_PER_PAGE)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        className={styles.pagination}
                        style={{ paddingBottom: "20px", paddingTop: "20px" }}
                    />
                </div>
            ) : (
                <p>Não há boletins disponíveis para os filtros selecionados.</p>
            )}
        </div>
    );

    return (
        <>
            <InterfaceStructure
                itemsPages={PagesList}
                dashboardContent={
                    <BasicPageStructure
                        content={dashboardContent}
                        buttons={buttons}
                        returnPage={false}
                        namePage="Boletins"
                        accessType="administrador"
                    />
                }
            />

            {/* Popup para criar boletim */}
            <PopUpComponent
                title="Criar Boletim"
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
            >
                <BoletimCreateComponent />
            </PopUpComponent>

            {/* Popup para editar boletim */}
            {editPopupOpen && editTarget && (
                <PopUpComponent
                    title="Editar Boletim"
                    isOpen={editPopupOpen}
                    onClose={() => setEditPopupOpen(false)}
                >
                    <BoletimEditComponent initialData={editTarget} />
                </PopUpComponent>
            )}


            {/* Confirmação de exclusão */}
            {showDeleteConfirm && (
                <MiniPopUpComponent
                    title="Confirmação de Exclusão"
                    message="Tem certeza que deseja excluir este boletim?"
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                />
            )}
        </>
    );
};

export default BoletimComponent;
