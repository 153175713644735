import { useState, useEffect } from 'react';
import moment from 'moment';
import extractTextFromBlocks from '../../../utils/extractTextFromBlocks';

const useCalendarioState = (tenantId, useCalendario, useCategoryCalendario) => {
    const [dataSelecionada, setDataSelecionada] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupCriarCategoriaOpen, setIsPopupCriarCategoriaOpen] = useState(false);
    const [isPopupGerenciarCategoriaOpen, setIsPopupGerenciarCategoriaOpen] = useState(false);
    const [isPopupCategoriaOpen, setIsPopupCategoriaOpen] = useState(false);
    const [isPopupVisualizacaoOpen, setIsPopupVisualizacaoOpen] = useState(false);
    const [eventoSelecionado, setEventoSelecionado] = useState(null);
    const [isPopupEdicaoOpen, setIsPopupEdicaoOpen] = useState(false);
    const [eventoEditando, setEventoEditando] = useState(null);
    const [isPopupConfirmacaoOpen, setIsPopupConfirmacaoOpen] = useState(false);
    const [eventoParaDeletar, setEventoParaDeletar] = useState(null);
    const [novoEvento, setNovoEvento] = useState({
        Titulo: "",
        descricao: "",
        date: "",
        time: "00:00",
        categoria: ""
    });
    const [mesAtual, setMesAtual] = useState(moment().format("MM")); 
    const [anoAtual, setAnoAtual] = useState(moment().format("YYYY")); 
    const { eventos, loading, error, criarEvento, atualizarEvento, deletarEvento, fetchEventoById, baixarCalendario } = useCalendario(tenantId, dataSelecionada);

    const { categorias, fetchCategorias } = useCategoryCalendario(tenantId);

    useEffect(() => {
        fetchCategorias(); // 🔥 Busca categorias ao montar o componente
    }, [tenantId]);

    useEffect(() => {
        console.log("Categorias carregadas:", categorias);
    }, [categorias]);

    const handleVisualizarEvento = async (evento) => {
        if (!evento) return;

        try {
            const eventoDetalhado = await fetchEventoById(evento.id);
            console.log(eventoDetalhado);
            setEventoSelecionado({
                ...eventoDetalhado,
                descricao: extractTextFromBlocks(eventoDetalhado.descricao),
                dataFormatada: eventoDetalhado.date,
                horaFormatada: eventoDetalhado.time,
            });
            setIsPopupVisualizacaoOpen(true);
        } catch (error) {
            console.error("Erro ao buscar evento por ID:", error);
        }
    };

    console.log("Evento selecionado:", eventoSelecionado)

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define qual estado deve ser atualizado
        const setState = isPopupEdicaoOpen ? setEventoEditando : setNovoEvento;

        setState((prev) => {
            switch (name) {
                case "Titulo":
                    return { ...prev, Titulo: value };
                case "descricao":
                    return { ...prev, descricao: value };
                case "date":
                    return { ...prev, date: value };
                case "time":
                    return { ...prev, time: value || "00:00" }; // Garante um valor padrão
                case "categoria":
                    return { ...prev, categoria: value };
                default:
                    console.warn(`Campo não tratado: ${name}`);
                    return prev; // Mantém o estado inalterado se o campo não estiver no switch
            }
        });

        console.log(`📌 Campo alterado: ${name}, Novo valor: ${value}`);
    };


    const handleEditarEvento = (evento) => {
        if (!evento) return;

        console.log("✏️ Editando evento:", evento);

        setEventoEditando({
            id: evento._id || evento.id,  // Garante que o ID seja capturado corretamente
            Titulo: evento.Titulo || evento.title || "",  // Garante que o título seja preenchido
            descricao: evento.descricao || "",
            date: evento.date ? moment(evento.date, "DD/MM/YYYY").format("YYYY-MM-DD") : "",  // Converte para formato aceito pelo input type=date
            time: evento.time || evento.horaFormatada || "00:00",  // Usa um valor padrão caso esteja vazio
            categoria: evento.categoria || evento.categoriaName || "",  // Certifica-se de que a categoria seja preenchida corretamente
        });

        setIsPopupEdicaoOpen(true);
    };


    const handleSalvarEdicao = async () => {
        if (!eventoEditando.id || !eventoEditando.Titulo || !eventoEditando.date || !eventoEditando.time || !eventoEditando.categoria) {
            console.error("❌ Erro: Campos obrigatórios não preenchidos.", eventoEditando);
            return;
        }

        const eventoAtualizado = {
            id: eventoEditando.id,
            tenantId, // Verifique se está pegando o tenantId corretamente
            Titulo: eventoEditando.Titulo,
            descricao: eventoEditando.descricao,
            categoria: eventoEditando.categoria,
            date: moment(eventoEditando.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
            time: eventoEditando.time !== "00:00" ? eventoEditando.time : "00:00",
        };

        console.log("📤 Enviando atualização do evento para o backend:", eventoAtualizado);

        try {
            const response = await atualizarEvento(eventoAtualizado); // Envia os dados para o backend
            console.log("✅ Evento atualizado com sucesso:", response);

            // Fecha o modal e limpa o estado
            setIsPopupEdicaoOpen(false);
            setEventoEditando(null);
        } catch (error) {
            console.error("❌ Erro ao atualizar evento:", error.response?.data || error.message);
        }
    };

    const handleDeletarEvento = (evento) => {
        if (!evento) {
            console.error("Erro: Evento indefinido ao tentar deletar.");
            return;
        }

        // Garantindo que o ID seja pego corretamente
        const eventoId = evento.id || evento._id;

        if (!eventoId) {
            console.error("Erro: Tentativa de deletar um evento sem ID válido.", evento);
            return;
        }

        console.log("Evento para deletar definido:", eventoId, evento);
        setEventoParaDeletar({ id: eventoId, title: evento.Titulo });
        setIsPopupConfirmacaoOpen(true);
    };

    useEffect(() => {
        console.log("Estado do popup de confirmação:", isPopupConfirmacaoOpen);
    }, [isPopupConfirmacaoOpen]);


    const confirmarDelecao = async () => {
        if (!eventoParaDeletar || !eventoParaDeletar.id) {
            console.error("Erro: Nenhum evento válido para deletar.");
            return;
        }

        console.log(`Deletando evento com ID: ${eventoParaDeletar.id}`);

        try {
            await deletarEvento(eventoParaDeletar.id);
            setIsPopupConfirmacaoOpen(false);
            setEventoParaDeletar(null);
        } catch (error) {
            console.error("Erro ao excluir evento:", error);
        }
    };

    const handleSalvarEvento = async () => {
        if (!novoEvento.Titulo || !novoEvento.date || !novoEvento.time || !novoEvento.categoria) {
            console.error("Erro: Campos obrigatórios não preenchidos.", novoEvento);
            return;
        }

        const eventoFormatado = {
            tenantId, // Certifique-se de que está pegando o tenantId corretamente
            Titulo: novoEvento.Titulo,
            descricao: novoEvento.descricao,
            categoria: novoEvento.categoria,
            date: moment(novoEvento.date, "YYYY-MM-DD").format("YYYY-MM-DD"), // Confirma que a data está no formato correto
            time: novoEvento.time !== "00:00" ? novoEvento.time : "00:00",
        };

        console.log("📤 Enviando evento para o backend:", eventoFormatado);

        try {
            const response = await criarEvento(eventoFormatado); // Envia para o backend
            console.log("✅ Evento salvo com sucesso:", response);
            setIsPopupOpen(false);
            setNovoEvento({ Titulo: "", descricao: "", date: "", time: "00:00", categoria: "" });
        } catch (error) {
            console.error("❌ Erro ao salvar evento:", error.response?.data || error.message);
        }
    };

    const handleBaixarCalendario = async () => {
        try {
            console.log(`📥 Baixando calendário de ${mesAtual}/${anoAtual}`);
            await baixarCalendario(anoAtual, mesAtual);
        } catch (error) {
            console.error("Erro ao baixar o calendário:", error);
        }
    };

    return {
        eventos,
        loading,
        error,
        criarEvento,
        atualizarEvento,
        deletarEvento,
        dataSelecionada,
        setDataSelecionada,
        isPopupOpen,
        setIsPopupOpen,
        isPopupCriarCategoriaOpen,  // 🔹 Certifique-se de que está aqui
        setIsPopupCriarCategoriaOpen,  // 🔹 Certifique-se de que está aqui
        isPopupGerenciarCategoriaOpen,  // 🔹 Certifique-se de que está aqui
        setIsPopupGerenciarCategoriaOpen,  // 🔹 Certifique-se de que está aqui
        anoAtual,
        setAnoAtual,
        handleBaixarCalendario,
        isPopupCategoriaOpen,
        setIsPopupCategoriaOpen,
        isPopupVisualizacaoOpen,
        setIsPopupVisualizacaoOpen,
        eventoSelecionado,
        setEventoSelecionado,
        isPopupEdicaoOpen,
        setIsPopupEdicaoOpen,
        eventoEditando,
        setEventoEditando,
        isPopupConfirmacaoOpen,
        setIsPopupConfirmacaoOpen,
        eventoParaDeletar,
        setEventoParaDeletar,
        handleVisualizarEvento,
        handleEditarEvento,
        handleDeletarEvento,
        confirmarDelecao,
        novoEvento,
        setNovoEvento,
        handleInputChange,
        categorias,
        handleSalvarEvento
    };


};

export default useCalendarioState;
