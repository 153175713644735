// src/pages/FichasPage.js

import React from 'react';
import styles from './FichasPage.module.css';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import FichasComponent from '../../../components/pagesComponents/fichasComponents/FichasComponent';
import GetTenantId from '../../../hooks/getTenantId';

const FichasPage = () => {
    const tenantId = GetTenantId();  // Obtendo tenantId
    const itemsPagesList = itemsPagesWithExclusions('Fichas', []);

    return (
        <FichasComponent PagesList={itemsPagesList} tenantId={tenantId} />
    );
};

export default FichasPage;
