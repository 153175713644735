import React, { useEffect, useState } from 'react';
import apiAxios from '../../../api/apiAxios';
import styles from './AddUserComponent.module.css';
import { series } from '../../../DataSeries';
import { turno } from '../../../DataSeries';
import Message from '../tools/Message';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';
import { FaRegSave } from 'react-icons/fa';
import { FaSearch } from "react-icons/fa";
import GetTenantId from '../../../hooks/getTenantId';

const accessLabels = {
    isResponsavel: 'Responsável',
    isAluno: 'Aluno',
    isCoordenaçao: 'Coordenação',
    isNutricionista: 'Nutricionista',
    isProfessor: 'Professor',
    isSecretaria: 'Secretária'
};

const AddUserComponent = ({ AcessLevel = 'low' }) => {
    const tenantId = GetTenantId();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        CPF: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        access: '',
        alunoInfo: { serie: '', turno: '' },
        responsavelInfo: [],
        professorInfo: []
    });
    const [alunos, setAlunos] = useState([]); // Estado separado para gerenciar os alunos


    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [loading, setLoading] = useState(false);

    const validateCPF = (cpf) => {
        const cleanCPF = cpf.replace(/\D/g, '');
        return cleanCPF.length === 11;
    };

    const buscarResponsavelPorCPF = async (CPF) => {
        if (!CPF) {
            console.error('Erro: CPF vazio.');
            alert('Por favor, insira um CPF válido.');
            return;
        }

        if (!validateCPF(CPF)) {
            console.error('Erro: CPF inválido.');
            alert('CPF deve ter exatamente 11 dígitos.');
            return;
        }

        try {
            setLoading(true);
            const cleanCPF = CPF.replace(/\D/g, ''); // Remove caracteres não numéricos
            console.log('Buscando responsável com CPF:', cleanCPF);

            // Requisição para buscar responsável pelo CPF
            const { data: responsavel } = await apiAxios.get(`/api/user/users/search-by-cpf/${cleanCPF}`);
            console.log('Dados do responsável recebidos:', responsavel);

            // Validar se responsavel.user existe
            if (responsavel?.user) {
                const { user } = responsavel;
                console.log('Dados do usuário:', user);

                // Validar se há informações suficientes para criar um aluno
                if (user.alunoInfo && user.alunoInfo.serie && user.alunoInfo.turno) {
                    const novoAluno = {
                        nomeAluno: `${user.firstName} ${user.lastName}`,
                        serie: user.alunoInfo.serie,
                        turno: user.alunoInfo.turno,
                        CPF: user.CPF || '',
                        _id: user.alunoInfo._id || '',
                    };

                    console.log('Novo aluno extraído:', novoAluno);

                    // Atualizar formData sem sobrescrever valores existentes
                    setFormData((prevState) => {
                        const idsExistentes = prevState.responsavelInfo.map((item) => item._id);
                        if (idsExistentes.includes(novoAluno._id)) {
                            console.warn('Aluno já adicionado:', novoAluno);
                            return prevState; // Não adiciona duplicatas
                        }

                        const updatedResponsavelInfo = [...prevState.responsavelInfo, novoAluno];
                        console.log('Atualizando responsavelInfo:', updatedResponsavelInfo);

                        return {
                            ...prevState,
                            responsavelInfo: updatedResponsavelInfo,
                        };
                    });

                    setMessage('Responsável encontrado e informações adicionadas!');
                    setMessageType('success');
                } else {
                    console.warn('Informações insuficientes para criar um aluno.');
                    setMessage('Responsável não possui informações de aluno válidas.');
                    setMessageType('error');
                }
            } else {
                console.error('Estrutura de dados inesperada:', responsavel);
                setMessage('Responsável não encontrado ou dados inválidos.');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Erro ao buscar responsável pelo CPF:', error);
            setMessage(`Erro ao buscar responsável: ${error.message}`);
            setMessageType('error');
        } finally {
            setLoading(false);
        }
    };




    useEffect(() => {
        let timer;
        if (message) {
            timer = setTimeout(() => {
                setMessage('');
                setMessageType('');
            }, 15000); // 15 segundos
        }
        return () => clearTimeout(timer);
    }, [message]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'CPF') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 11) {
                setFormData({
                    ...formData,
                    [name]: digitsOnly
                });
            }
        } else if (name === 'phoneNumber') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 13) {
                setFormData({
                    ...formData,
                    [name]: digitsOnly
                });
            }
        } else if (name.startsWith('alunoInfo')) {
            const key = name.split('[')[1].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                alunoInfo: {
                    ...prevState.alunoInfo,
                    [key]: value
                }
            }));
        } else if (name.startsWith('responsavelInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                responsavelInfo: prevState.responsavelInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else if (name.startsWith('professorInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                professorInfo: prevState.professorInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleAccessChange = (e) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            access: value,
            alunoInfo: value === 'isAluno' ? prevState.alunoInfo : { serie: '', turno: '' },
            responsavelInfo: value === 'isResponsavel' ? prevState.responsavelInfo : [],
            professorInfo: value === 'isProfessor' ? (prevState.professorInfo.length ? prevState.professorInfo : [{ serie: '', turno: '' }]) : []
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setMessage('');
        setMessageType('');

        let errorMessages = [];

        // Validação das senhas
        if (formData.password !== formData.confirmPassword) {
            errorMessages.push('As senhas não coincidem.');
        }

        // Validação dos campos obrigatórios
        if (!formData.firstName) {
            errorMessages.push('O nome é obrigatório.');
        }
        if (!formData.lastName) {
            errorMessages.push('O sobrenome é obrigatório.');
        }
        if (!formData.password) {
            errorMessages.push('A senha é obrigatória.');
        }
        if (!formData.confirmPassword) {
            errorMessages.push('A confirmação de senha é obrigatória.');
        }
        if (!formData.access) {
            errorMessages.push('O tipo de acesso é obrigatório.');
        }

        // Validação do CPF (sempre, independentemente do tipo de acesso)
        if (!formData.CPF) {
            errorMessages.push('O CPF é obrigatório.');
        } else if (formData.CPF.length < 11) {
            errorMessages.push('CPF incompleto.');
        }

        // Validação do número de telefone (apenas se o acesso não for 'isAluno')
        if (formData.access !== 'isAluno') {
            if (!formData.phoneNumber) {
                errorMessages.push('O número de telefone é obrigatório.');
            } else if (formData.phoneNumber.length < 13) {
                errorMessages.push('Número de telefone incompleto.');
            }
        }

        // Se houver erros, exiba as mensagens e interrompa o envio
        if (errorMessages.length > 0) {
            setMessage(errorMessages);
            setMessageType('error');
            return;
        }

        const userType = formData.access === 'isAluno' ? 'aluno' :
            formData.access === 'isProfessor' ? 'professor' :
                formData.access === 'isResponsavel' ? 'responsavel' :
                    formData.access === 'isCoordenaçao' ? 'coordenacao' :
                        formData.access === 'isNutricionista' ? 'nutricionista' :
                            formData.access === 'isSecretaria' ? 'secretaria' : '';

        const { access, alunoInfo, responsavelInfo, professorInfo, confirmPassword, ...basicData } = formData;

        const newUserData = {
            ...basicData,
            userType,
            userInfo: access === 'isAluno' ? alunoInfo :
                access === 'isResponsavel' ? responsavelInfo :
                    access === 'isProfessor' ? professorInfo : undefined
        };

        try {
            await apiAxios.post(`/api/user/${tenantId}/register`, newUserData);
            setMessage('Usuário criado com sucesso!');
            setMessageType('success');
            // Limpar o formulário
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                CPF: '',
                phoneNumber: '',
                password: '',
                confirmPassword: '',
                access: '',
                alunoInfo: { serie: '', turno: '' },
                responsavelInfo: [],
                professorInfo: []
            });
        } catch (error) {
            setMessage('Erro ao criar usuário: ' + error.message);
            setMessageType('error');
        }
    };

    const addResponsavelInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            responsavelInfo: [
                ...prevState.responsavelInfo,
                { nomeAluno: '', serie: '', turno: '', CPF: '' }
            ]
        }));
    };

    const addProfessorInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            professorInfo: [
                ...prevState.professorInfo,
                { serie: '', turno: '' }
            ]
        }));
    };

    const removeProfessorInfo = (index) => {
        setFormData(prevState => ({
            ...prevState,
            professorInfo: prevState.professorInfo.filter((_, idx) => idx !== index)
        }));
    };

    const accessibleTypes = AcessLevel === 'high'
        ? ['isAluno', 'isProfessor', 'isResponsavel', 'isCoordenaçao', 'isNutricionista', 'isSecretaria']
        : ['isAluno', 'isProfessor','isResponsavel'];

    return (
        <>
            {message && (
                <Message type={messageType} message={message} />
            )}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.layout}>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder='Nome'
                    />
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder='Sobrenome'
                    />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='Email'
                    />
                    <input
                        type="text"
                        name="CPF"
                        value={formData.CPF}
                        onChange={handleChange}
                        placeholder='CPF'
                    />
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder='Número de Telefone'
                    />
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder='Senha'
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder='Confirmar Senha'
                    />

                    <p>Tipo de Acesso:</p>
                    <div className={styles.checkboxGroup}>
                        {Object.keys(accessLabels).map(accessType => (
                            accessibleTypes.includes(accessType) && (
                                <label key={accessType} className={styles.checkboxLabel}>
                                    <input
                                        type="radio"
                                        name="access"
                                        value={accessType}
                                        checked={formData.access === accessType}
                                        onChange={handleAccessChange}
                                    />
                                    {accessLabels[accessType]}
                                </label>
                            )
                        ))}
                    </div>

                    {formData.access === 'isAluno' && (
                        <div>
                            <select
                                name="alunoInfo[serie]"
                                value={formData.alunoInfo.serie}
                                onChange={handleChange}
                            >
                                <option value="">Selecione a Série</option>
                                {series.map((serie) => (
                                    <option key={serie} value={serie}>{serie}</option>
                                ))}
                            </select>
                            <select
                                name="alunoInfo[turno]"
                                value={formData.alunoInfo.turno}
                                onChange={handleChange}
                            >
                                <option value="">Selecione o Turno</option>
                                {turno.map((turnoOption) => (
                                    <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                ))}
                            </select>
                        </div>
                    )}

                    {formData.access === 'isProfessor' && (
                        <div>
                            <p>Informações do Professor:</p>
                            {formData.professorInfo.map((info, index) => (
                                <div key={index} className={styles.turmaContainer}>
                                    {/* Título da Turma */}
                                    <p className={styles.turmaTitle}>
                                        {info.serie && info.turno ? `${info.serie} - ${info.turno}` : `Turma ${index + 1}`}
                                    </p>

                                    <div className={styles.turmaFields}>
                                        <select
                                            name={`professorInfo[${index}][serie]`}
                                            value={info.serie}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione a Série</option>
                                            {series.map((serie) => (
                                                <option key={serie} value={serie}>{serie}</option>
                                            ))}
                                        </select>
                                        <select
                                            name={`professorInfo[${index}][turno]`}
                                            value={info.turno}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Turno</option>
                                            {turno.map((turnoOption) => (
                                                <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                            ))}
                                        </select>
                                        {/* Botão de Remover Turma */}
                                        <button type="button" onClick={() => removeProfessorInfo(index)} className={styles.removeButton}>
                                            <IoMdRemoveCircleOutline /> Remover Turma
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button type="button" onClick={addProfessorInfo} className={styles.addButton}>
                                <IoIosAddCircleOutline /> Adicionar Turma
                            </button>
                        </div>
                    )}
                    {formData.access === 'isResponsavel' && (
                        <div>
                            <h3>Adicionar Responsável</h3>
                            <div className={styles.searchWrapper}>
                                <input
                                    type="text"
                                    className={styles.searchBarInput}
                                    placeholder="Digite o CPF do responsável"
                                    value={formData.responsavelCPF || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 11) {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                responsavelCPF: value,
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            buscarResponsavelPorCPF(formData.responsavelCPF);
                                        }
                                    }}
                                />
                                <button
                                    type="button"
                                    className={styles.saveButton}
                                    style={{ marginTop: "10px", gap: "5px" }}
                                    onClick={() => buscarResponsavelPorCPF(formData.responsavelCPF)}
                                >
                                    <FaSearch />Buscar
                                </button>
                            </div>
                        </div>
                    )}

                    {formData.responsavelInfo.length > 0 && (
                        <div>
                            <h3>Informações do Responsável e Alunos:</h3>
                            <div className={styles.alunosList}>
                                {formData.responsavelInfo.map((info, index) => (
                                    <div key={index} className={styles.alunoCard}>
                                        <h4>Aluno {index + 1}</h4>
                                        <p><strong>Nome:</strong> {info.nomeAluno}</p>
                                        <p><strong>Série:</strong> {info.serie}</p>
                                        <p><strong>Turno:</strong> {info.turno}</p>
                                        <p><strong>CPF:</strong> {info.CPF}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className={styles.layout2}>
                        <button type="submit" className={styles.saveButton}>
                            <FaRegSave /> Criar Usuário
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddUserComponent;
