import React, { useState } from 'react';
import styles from './CreateSecretariaAtividade.module.css';
import { MdKeyboardArrowLeft, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from "react-icons/md";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { MdCalendarToday, MdOutlineClass } from "react-icons/md";
import { LuApple } from "react-icons/lu";
import { RiMegaphoneLine } from "react-icons/ri";
import { TfiAgenda } from 'react-icons/tfi';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';
import { series, turno } from "../../../DataSeries";
import { MdDeleteOutline } from "react-icons/md";
import { IoChatboxEllipsesOutline } from 'react-icons/io5';

const CreateSecretariaAtividade = () => {
    const [titulo, setTitulo] = useState('');
    const [materia, setMateria] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [tempoLimite, setTempoLimite] = useState('');
    const [serie, setSerie] = useState('');
    const [turnoSelecionado, setTurnoSelecionado] = useState('');
    const [questoes, setQuestoes] = useState([{ questao: '', respostas: [{ item: 'A', resposta: '' }, { item: 'B', resposta: '' }, { item: 'C', resposta: '' }, { item: 'D', resposta: '' }, { item: 'E', resposta: '' }], itemCorreto: '' }]);
    const navigate = useNavigate();

    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    const handleAddQuestao = () => {
        setQuestoes([...questoes, { questao: '', respostas: [{ item: 'A', resposta: '' }, { item: 'B', resposta: '' }, { item: 'C', resposta: '' }, { item: 'D', resposta: '' }, { item: 'E', resposta: '' }], itemCorreto: '' }]);
    };

    const handleRemoveQuestao = (index) => {
        const newQuestoes = questoes.filter((_, i) => i !== index);
        setQuestoes(newQuestoes);
    };

    const handleQuestaoChange = (index, field, value) => {
        const newQuestoes = [...questoes];
        newQuestoes[index][field] = value;
        setQuestoes(newQuestoes);
    };

    const handleRespostaChange = (questaoIndex, respostaIndex, value) => {
        const newQuestoes = [...questoes];
        newQuestoes[questaoIndex].respostas[respostaIndex].resposta = value;
        setQuestoes(newQuestoes);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const novaAtividade = { titulo, materia, comentarios, tempoLimite: Number(tempoLimite), serie, turno: turnoSelecionado, questoes };
        try {
            await apiAxios.post(`/api/atv/${tokenPayload.tenantId}`, novaAtividade);
            navigate('/atividades')
        } catch (error) {
            console.error('Erro ao criar a atividade:', error);
        }
    };

    const handleTempoLimiteChange = (e) => {
        const newValue = e.target.value;
        // Limita a entrada a no máximo 2 dígitos
        if (/^\d{0,2}$/.test(newValue)) {
            setTempoLimite(newValue);
        }
    };

    const itemsPages = [
        {
            id: 1,
            name: "Painel",
            icon: <MdOutlineSpaceDashboard />,
            isSelect: false,
            link: "/"
        },
        {
            id: 2,
            name: "Agenda",
            icon: <TfiAgenda />,
            isSelect: false,
            link: "/agenda"
        },
        {
            id: 3,
            name: "Planos",
            icon: <MdOutlineClass />,
            isSelect: false,
            link: "/planos"
        },
        {
            id: 4,
            name: "Calendário",
            icon: <MdCalendarToday />,
            isSelect: false,
            link: "/calendario"
        },
        {
            id: 5,
            name: "Cardápio",
            icon: <LuApple />,
            isSelect: false,
            link: "/cardapio"
        },
        {
            id: 6,
            name: "Informes",
            icon: <RiMegaphoneLine />,
            isSelect: false,
            link: "/informes"
        },
        {
            id: 7,
            name: "Atividades",
            icon: <PiPencilSimpleLineBold />,
            isSelect: false,
            link: "/atividades"
        },
        {
            id: 8,
            name: "Chat",
            icon: <IoChatboxEllipsesOutline />,
            isSelect: false,
            link: "/chat"
        },
        { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/atividades'><MdKeyboardArrowLeft />Atividades</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Criar Atividade</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <p>Informações Básicas da Avaliação</p>
                    <div className={styles.BasicInfoForm}>
                        <input type="text" placeholder="Título" value={titulo} onChange={(e) => setTitulo(e.target.value)} required />
                        <input type="text" placeholder="Matéria" value={materia} onChange={(e) => setMateria(e.target.value)} required />
                        <input type="text" placeholder="Comentários" value={comentarios} onChange={(e) => setComentarios(e.target.value)} />
                        <input type="number" onChange={handleTempoLimiteChange} placeholder="Tempo Limite (minutos)" value={tempoLimite} required />
                        <select value={serie} placeholder="Série" onChange={(e) => setSerie(e.target.value)} required>
                            <option>Selecione uma série</option>
                            {series.map((serie) => (
                                <option key={serie} value={serie}>{serie}</option>
                            ))}
                        </select>
                        <select value={turnoSelecionado} placeholder="Turno" onChange={(e) => setTurnoSelecionado(e.target.value)} required>
                            <option>Selecione um turno</option>
                            {turno.map((turno) => (
                                <option key={turno} value={turno}>{turno}</option>
                            ))}
                        </select>
                    </div>
                    <p>Questões da Avaliação</p>
                    <div className={styles.questoesAll}>
                        {questoes.map((questao, questaoIndex) => (
                            <div key={questaoIndex} className={styles.questao}>
                                <div className={styles.TopArea}>
                                    <button type="button" onClick={() => handleRemoveQuestao(questaoIndex)} className={styles.removeButton}><MdDeleteOutline /></button>
                                </div>
                                <div className={styles.questionelement}>
                                    <label>Questão {questaoIndex + 1}</label>
                                    <input type="text" placeholder='Titulo da questão' value={questao.questao} onChange={(e) => handleQuestaoChange(questaoIndex, 'questao', e.target.value)} required />
                                </div>
                                <div className={styles.respostas}>
                                    {questao.respostas.map((resposta, respostaIndex) => (
                                        <div key={respostaIndex}>
                                            <label>{resposta.item}) </label>
                                            <input type="text" value={resposta.resposta} onChange={(e) => handleRespostaChange(questaoIndex, respostaIndex, e.target.value)} required />
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.itemCorreto}>
                                    <label>Resposta Correta (item)</label>
                                    <select value={questao.itemCorreto} onChange={(e) => handleQuestaoChange(questaoIndex, 'itemCorreto', e.target.value)} required>
                                        <option value="">Selecione a resposta correta</option>
                                        {questao.respostas.map((resposta, respostaIndex) => (
                                            <option key={respostaIndex} value={resposta.item}>{resposta.item}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.botoesDeControle}>
                        <button type="button" onClick={handleAddQuestao}>Adicionar Questão</button>
                        <button className={styles.add} type="submit">Criar Atividade</button>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
        </div>
    );
};

export default CreateSecretariaAtividade;
