import { useState, useEffect } from 'react';
import apiAxios from '../../api/apiAxios';
import { useSnackbar } from 'notistack';

const API_URL = '/api/mat/';

const useMaterias = ({ tenantId, serie }) => {
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchMaterias = async () => {
    setLoading(true);
    try {
      const response = await apiAxios.get(`${API_URL}materias`, {
        params: { tenantId, serie },
      });
      setMaterias(response.data);
    } catch (error) {
      enqueueSnackbar('Erro ao carregar matérias.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const createMateria = async (newMateria) => {
    try {
      const response = await apiAxios.post(`${API_URL}materias`, newMateria);
      setMaterias((prev) => [...prev, response.data]);
      enqueueSnackbar('Matéria criada com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao criar matéria.', { variant: 'error' });
    }
  };

  const updateMateria = async (id, updatedData) => {
    try {
      const response = await apiAxios.put(`${API_URL}materias/${id}`, updatedData);
      setMaterias((prev) =>
        prev.map((mat) => (mat._id === id ? response.data : mat))
      );
      enqueueSnackbar('Matéria atualizada com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar matéria.', { variant: 'error' });
    }
  };

  const deleteMateria = async (id) => {
    try {
      await apiAxios.delete(`${API_URL}materias/${id}`);
      setMaterias((prev) => prev.filter((mat) => mat._id !== id));
      enqueueSnackbar('Matéria deletada com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao deletar matéria.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (tenantId && serie) {
      fetchMaterias();
    }
  }, [tenantId, serie]);

  return {
    materias,
    loading,
    fetchMaterias,
    createMateria,
    updateMateria,
    deleteMateria,
  };
};

export default useMaterias;
