import styles from './CreateHorarioComponent.module.css';
import useTenantId from '../../../hooks/getTenantId';
import apiAxios from '../../../api/apiAxios';
import { series, turno } from "../../../DataSeries";
import { MdDeleteOutline } from "react-icons/md";
import { IoClose } from 'react-icons/io5';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Message from '../tools/Message';
import GetTenantId from '../../../hooks/getTenantId';

const EditHorarioComponent = ({ horarioId, onHorarioUpdated }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [horario, setHorario] = useState({
        serie: '',
        turno: '',
        publico: false, // Novo campo ajustado
        week: [{
            week_day: 'Segunda-feira',
            TimeStart: '',
            TimeEnd: '',
            materias: [{ TimeStart: '', TimeEnd: '', title: '', conteudo: '' }]
        }]
    });
    const [message, setMessage] = useState(null);
    const tenantId = GetTenantId();

    useEffect(() => {
        if (horarioId) {
            const fetchHorario = async () => {
                try {
                    const response = await apiAxios.get(`/api/horarios/horarios/${horarioId}`);
                    setHorario(response.data);
                } catch (error) {
                    setMessage({ type: 'error', text: 'Erro ao carregar horário.' });
                }
            };

            fetchHorario();
        }
    }, [horarioId, tenantId]);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [message]);

    const handleUpdateHorario = async () => {
        const { serie, turno, week, publico } = horario;

        for (let i = 0; i < week.length; i++) {
            if (!week[i].week_day || !week[i].TimeStart || !week[i].TimeEnd || !week[i].materias[0].title) {
                setMessage({ type: 'warning', text: 'Preencha todos os campos obrigatórios.' });
                return;
            }
        }

        try {
            const response = await apiAxios.put(`/api/horarios/horarios/${horarioId}`, {
                tenantId,
                ...horario
            });
            onHorarioUpdated(response.data);
            setMessage({ type: 'success', text: 'Horário atualizado com sucesso!' });
        } catch (error) {
            setMessage({ type: 'error', text: 'Erro ao atualizar horário.' });
        }
    };

    const handleChange = (e, weekIndex = null, materiaIndex = null) => {
        const { name, value, type, checked } = e.target;
        const updatedHorario = { ...horario };

        const inputValue = type === 'checkbox' ? checked : value;

        if (weekIndex !== null && materiaIndex === null) {
            updatedHorario.week[weekIndex][name] = inputValue;
        } else if (weekIndex !== null && materiaIndex !== null) {
            updatedHorario.week[weekIndex].materias[materiaIndex][name] = inputValue;
        } else {
            updatedHorario[name] = inputValue;
        }

        setHorario(updatedHorario);
    };

    const handleAddMateria = (weekIndex) => {
        const updatedHorario = { ...horario };
        if (updatedHorario.week[weekIndex].materias.length < 30) {
            updatedHorario.week[weekIndex].materias.push({ TimeStart: '', TimeEnd: '', title: '', conteudo: '' });
            setHorario(updatedHorario);
        } else {
            setMessage({ type: 'warning', text: 'Você não pode adicionar mais de 30 matérias.' });
        }
    };

    const handleRemoveMateria = (weekIndex, materiaIndex) => {
        const updatedHorario = { ...horario };
        if (updatedHorario.week[weekIndex].materias.length > 1) {
            updatedHorario.week[weekIndex].materias.splice(materiaIndex, 1);
            setHorario(updatedHorario);
        } else {
            setMessage({ type: 'warning', text: 'Você deve manter pelo menos 1 matéria.' });
        }
    };

    const handleAddDay = () => {
        setHorario({
            ...horario,
            week: [
                ...horario.week,
                {
                    week_day: 'Segunda-feira',
                    TimeStart: '',
                    TimeEnd: '',
                    materias: [{ TimeStart: '', TimeEnd: '', title: '', conteudo: '' }]
                }
            ]
        });
    };

    const handleRemoveDay = (index) => {
        if (horario.week.length > 1) {
            setHorario({
                ...horario,
                week: horario.week.filter((_, i) => i !== index)
            });
        } else {
            setMessage({ type: 'warning', text: 'Você deve manter pelo menos 1 dia.' });
        }
    };

    return (
        <>
            {message && <Message type={message.type} message={message.text} />}
            <div className={styles.createHorario}>
                <div className={styles.createHorario_top}>
                    <select
                        name="serie"
                        value={horario.serie}
                        onChange={handleChange}
                    >
                        <option value="">Selecione a série</option>
                        {series.map((serie) => (
                            <option key={serie} value={serie}>
                                {serie}
                            </option>
                        ))}
                    </select>
                    <select
                        name="turno"
                        value={horario.turno}
                        onChange={handleChange}
                    >
                        <option value="">Selecione o turno</option>
                        {turno.map((turno) => (
                            <option key={turno} value={turno}>
                                {turno}
                            </option>
                        ))}
                    </select>
                    <div>
                        <label>Horário público:</label>
                        <input
                            type="checkbox"
                            name="publico"
                            checked={horario.publico}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {horario.week && horario.week.map((day, weekIndex) => (
                    <div className={styles.createHorario_bottom} key={weekIndex}>
                        <h3>{day.week_day || `Dia ${weekIndex + 1}`}</h3>
                        <div className={styles.deleteDayWeek}>
                            <button type="button" onClick={() => handleRemoveDay(weekIndex)}><MdDeleteOutline /></button>
                        </div>
                        <select
                            name="week_day"
                            value={day.week_day}
                            onChange={(e) => handleChange(e, weekIndex)}
                        >
                            <option value="Segunda-feira">Segunda-feira</option>
                            <option value="Terça-feira">Terça-feira</option>
                            <option value="Quarta-feira">Quarta-feira</option>
                            <option value="Quinta-feira">Quinta-feira</option>
                            <option value="Sexta-feira">Sexta-feira</option>
                            <option value="Sábado">Sábado</option>
                            <option value="Domingo">Domingo</option>
                        </select>
                        <input
                            type="time"
                            name="TimeStart"
                            value={day.TimeStart}
                            onChange={(e) => handleChange(e, weekIndex)}
                        />
                        <input
                            type="time"
                            name="TimeEnd"
                            value={day.TimeEnd}
                            onChange={(e) => handleChange(e, weekIndex)}
                        />
                        <div className={styles.materiaContainer}>
                            {day.materias.map((materia, materiaIndex) => (
                                <div key={materiaIndex} className={styles.materiaContainer_item}>
                                    <div className={styles.materiaContainer_item_delete}>
                                        <button type="button" onClick={() => handleRemoveMateria(weekIndex, materiaIndex)}><IoClose /></button>
                                    </div>
                                    <div>
                                        <label>Título:</label>
                                        <input
                                            name="title"
                                            value={materia.title}
                                            onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                        />
                                    </div>
                                    <div>
                                        <label>Hora Início da Matéria:</label>
                                        <input
                                            type="time"
                                            name="TimeStart"
                                            value={materia.TimeStart}
                                            onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                        />
                                    </div>
                                    <div>
                                        <label>Hora Fim da Matéria:</label>
                                        <input
                                            type="time"
                                            name="TimeEnd"
                                            value={materia.TimeEnd}
                                            onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                        />
                                    </div>
                                    <div>
                                        <label>Conteúdo:</label>
                                        <input
                                            type="text"
                                            name="conteudo"
                                            value={materia.conteudo}
                                            onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <button className={styles.addMateria} type="button" onClick={() => handleAddMateria(weekIndex)}>Adicionar</button>
                        </div>
                    </div>
                ))}
                <div className={styles.action_buttons}>
                    <button className={styles.send} type="button" onClick={handleUpdateHorario}>Atualizar Horário</button>
                    <button type="button" onClick={handleAddDay}>Adicionar dia da semana</button>
                </div>
            </div>
        </>
    );
};

export default EditHorarioComponent;
