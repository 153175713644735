import moment from 'moment';

const formatarEventos = (eventos) => {
    return eventos
        .map(evento => {
            if (!evento.date || !evento.time) return null;

            const parsedDate = moment(`${evento.date} ${evento.time}`, "DD/MM/YYYY HH:mm");
            if (!parsedDate.isValid()) return null;

            return {
                id: evento._id,
                title: evento.Titulo,
                descricao: evento.descricao,
                start: parsedDate.toDate(),
                end: evento.endDate ? moment(`${evento.date} ${evento.endDate}`, "DD/MM/YYYY HH:mm").toDate() : null,
                color: evento.categoriaColor || '#3788d8',
                categoriaName: evento.categoriaName,
                dataFormatada: evento.date,
                horaFormatada: evento.time
            };
        })
        .filter(evento => evento !== null);
};

export default formatarEventos;
