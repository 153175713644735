import React from "react";
import styles from "./popUp2Component.module.css";
import CustomInput from "../inputs/CustomInput";
import DefaultButton from "../buttons/DefaultButton";
import { X } from "lucide-react";

const PopUp2Component = ({
    isOpen,
    title,
    description,
    inputs = [],
    buttons = [],
    onClose
}) => {
    if (!isOpen) return null;

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.popup} onClick={(e) => e.stopPropagation()}>

                {/* Cabeçalho */}
                <div className={styles.header}>
                    <h2>{title}</h2>
                    <button className={styles.closeButton} onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                {/* Descrição opcional */}
                {description && <p className={styles.description}>{description}</p>}

                <div className={styles.inputContainer}>
                    {inputs.map((input, index) => (
                        <CustomInput key={index} {...input} fullWidth style={{ marginBottom: "12px" }} />
                    ))}
                </div>

                <div className={styles.footer}>
                    {buttons.map((button, index) => (
                        <DefaultButton 
                            key={index} 
                            {...button} 
                            style={{ 
                                backgroundColor: button.backgroundColor || "var(--primary-color)", // Cor padrão 
                                color: button.color || "#ffffff", // Texto branco por padrão
                                marginBottom: "12px" 
                            }} 
                        />
                    ))}
                </div>

            </div>
        </div>
    );
};

export default PopUp2Component;
