import React, { useEffect, useState, useRef } from 'react';
import BasicPageStructure from '../../../shared/BasicPageStructure';
import { useParams } from 'react-router-dom';
import InterfaceStructure from '../../../shared/InterfaceStructure';
import apiAxios from '../../../../api/apiAxios';
import Loading from '../../../Loading/Loading';
import BoletimComponent from './BoletimComponent';  // Importando o componente do boletim
import BoletimInfantilComponent from './BoletimInfantilComponent';  // Importando o componente do relatório infantil
import styles from './BoletimAlunoDetailComponent.module.css';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import { useSnackbar } from 'notistack';
import GetTenantId from '../../../../hooks/getTenantId';
import useBoletins from '../../../../hooks/boletins/useBoletins';
import { FaSyncAlt } from 'react-icons/fa';

const BoletimAlunoDetailComponent = ({ itemsPages }) => {
    const [boletimAluno, setBoletimAluno] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tenantId = GetTenantId();
    const isCreatingRef = useRef(false);
    const { enqueueSnackbar } = useSnackbar(); // Inicializando notistack
    const [isDownloading, setIsDownloading] = useState(false); // Estado para bloquear o botão
    const [downloadTimeLeft, setDownloadTimeLeft] = useState(0); // Tempo restante
    const { boletins, loading: loadingBoletins, getBoletimById, criarBoletim, atualizarBoletim, deletarBoletim, sincronizarBoletimAluno, verificarSincronizacaoBoletimAluno } = useBoletins();
    const [precisaSincronizar, setPrecisaSincronizar] = useState(false);

    const { boletimid, id } = useParams();
    const boletimId = boletimid;
    const alunoId = id;

    useEffect(() => {
        const fetchBoletimAluno = async () => {
            setLoading(true);
            try {
                const response = await apiAxios.get('/api/boletims/boletins/aluno', {
                    params: { tenantId, boletimId, alunoId },
                });

                if (response.data && response.data.data.length > 0) {
                    // setBoletimAluno(response.data.data[0]);
                    const alunoBoletimEncontrado = response.data.data[0];
                    setBoletimAluno(alunoBoletimEncontrado);

                    // Verifica se precisa sincronizar
                    const precisaSync = await verificarSincronizacaoBoletimAluno({
                        tenantId,
                        boletimId,
                        alunoId,
                    });
                    setPrecisaSincronizar(precisaSync);
                    console.log("precisaSync", precisaSync);
                } else {
                    if (!isCreatingRef.current) {
                        isCreatingRef.current = true;
                        await createBoletimAluno();
                    }
                }
            } catch (err) {
                console.error('Erro ao buscar ou criar boletim do aluno:', err.response?.data || err.message);
                setError('Erro ao carregar o boletim do aluno.');
            } finally {
                setLoading(false);
            }
        };

        fetchBoletimAluno();
    }, [tenantId, boletimId, alunoId]);

    const createBoletimAluno = async () => {
        try {
            const boletimResponse = await apiAxios.get(`/api/boletims/boletins/${boletimId}`);
            const alunoResponse = await apiAxios.get(`/api/user/user/${alunoId}`);
            const aluno = alunoResponse.data.user;

            const serie = aluno.alunoInfo?.serie || "Sem série";
            const nivelEnsino = boletimResponse?.data?.data?.nivelEnsino;
            const periodType = boletimResponse?.data?.data?.periodType?.toLowerCase();

            console.log("Tipo de período recebido:", periodType);

            // Definir os períodos do boletim (bimestres ou semestres)
            const getPeriodos = (type) => {
                switch (type) {
                    case "bimestre":
                        return ["1º Bimestre", "2º Bimestre", "3º Bimestre", "4º Bimestre"];
                    case "trimestre":
                        return ["1º Trimestre", "2º Trimestre", "3º Trimestre", "4º Trimestre"];
                    case "semestre":
                        return ["1º Semestre", "2º Semestre"];
                    case "quadrimestre":
                        return ["1º Quadrimestre", "2º Quadrimestre", "3º Quadrimestre"];
                    case "anual":
                    case "ano letivo integral":
                        return ["Ano Letivo"];
                    default:
                        return []; // Retorna um array vazio se o tipo de período não for reconhecido
                }
            };


            const periodos = getPeriodos(periodType);

            if (!periodos.length) {
                throw new Error(`Tipo de período inválido ou não definido: ${periodType}`);
            }

            if (nivelEnsino === "Educação Infantil") {
                const periodos = getPeriodos(periodType); // Usa o tipo de período correto

                if (!periodos.length) {
                    throw new Error(`Tipo de período inválido ou não definido: ${periodType}`);
                }

                // ✅ Criar o relatório infantil corretamente
                const relatorio = {
                    tenantId,
                    boletimId,
                    alunoId,
                    matricula: aluno.matricula || "Sem matrícula",
                    serie,
                    turno: aluno.alunoInfo?.turno || "Sem turno",
                    nivelEnsino,
                    relatorios: boletimResponse?.data?.data?.areas?.map((areaGeral) => ({
                        areaGeral: {
                            nome: areaGeral.nome || "Área Geral não definida",
                            descricao: areaGeral.descricao || "",
                        },
                        areasEspecificas: [
                            {
                                competencias: areaGeral.competencias.map((competencia) => ({
                                    competencia: competencia || "Competência não definida",
                                    periodos: periodos.map((periodo) => ({
                                        periodo,
                                        tipo: "", // Valor padrão "Em Progresso"
                                    })),
                                })),
                            },
                        ],
                    })) || [],
                };

                const createResponse = await apiAxios.post("/api/boletims/boletins/aluno", relatorio);
                console.log("✅ Relatório Infantil Criado:", createResponse.data);
                setBoletimAluno(createResponse.data);
                return;
            }

            if (nivelEnsino === "Fundamental I" || nivelEnsino === "Fundamental II") {
                // ✅ Criar boletim com **bimestres**
                const materiasResponse = await fetchMateriasBySerie(serie);

                const materias = Array.isArray(materiasResponse) && materiasResponse[0]?.materia
                    ? materiasResponse[0].materia.map((materiaObj) => materiaObj.materia)
                    : [];

                const tiposAvaliacao = boletimResponse?.data?.data?.tiposAvaliacao || ["AV1", "AV2", "AV3"];

                // Criar estrutura com bimestres e matérias
                const bimestres = periodos.map((bimestre) => ({
                    bimestre,
                    notas: materias.map((materiaNome) => ({
                        materia: materiaNome,
                        avaliacoes: tiposAvaliacao.map((tipo) => ({
                            tipo,
                            nota: null,
                        })),
                        media: null,
                        status: "Pendente",
                    })),
                }));

                const novoBoletimAluno = {
                    tenantId,
                    boletimId,
                    alunoId,
                    matricula: aluno.matricula || "Sem matrícula",
                    serie,
                    turno: aluno.alunoInfo?.turno || "Sem turno",
                    nivelEnsino,
                    bimestres, // 🚀 Agora as notas estão organizadas corretamente por bimestre
                };

                const createResponse = await apiAxios.post("/api/boletims/boletins/aluno", novoBoletimAluno);
                const updatedResponse = await apiAxios.get("/api/boletims/boletins/aluno", {
                    params: { tenantId, boletimId, alunoId },
                });
                setBoletimAluno(updatedResponse.data.data[0]);
            }
        } catch (err) {
            console.error("Erro ao criar boletim do aluno:", err.response?.data || err.message);
            setError("Erro ao criar boletim do aluno.");
        } finally {
            isCreatingRef.current = false;
        }
    };

    const fetchMateriasBySerie = async (serie) => {
        try {
            const response = await apiAxios.get(`/api/mat/materias`, {
                params: { tenantId, serie },
            });
            return response.data || [];
        } catch (err) {
            console.error('Erro ao buscar matérias pela série:', err.response?.data || err.message);
            return [
                { materia: 'Matemática', description: 'Matéria padrão', color: '#FF0000' },
                { materia: 'Português', description: 'Matéria padrão', color: '#00FF00' },
            ];
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    const handleDownloadPDF = async () => {
        if (isDownloading) {
            enqueueSnackbar("⏳ Aguarde antes de baixar outro PDF.", { variant: "warning" });
            return;
        }

        setIsDownloading(true); // Bloqueia imediatamente para evitar múltiplos cliques

        try {
            if (!boletimAluno) {
                enqueueSnackbar('Boletim não encontrado!', { variant: 'error' });
                setIsDownloading(false);
                return;
            }

            const nivelEnsino = boletimAluno.nivelEnsino;
            const pdfUrl =
                nivelEnsino === "Educação Infantil"
                    ? `/api/boletims/boletins/${tenantId}/${alunoId}/infantil/pdf/${boletimAluno?.boletimId?.anoLetivo}/${boletimId}`
                    : `/api/boletims/boletins/${tenantId}/${alunoId}/fundamental/pdf/${boletimAluno?.boletimId?.anoLetivo}/${boletimId}`;


            const response = await apiAxios.get(pdfUrl, {
                responseType: 'blob',
            });

            // Criar um link para download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `boletim_${alunoId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            enqueueSnackbar("✅ Boletim baixado com sucesso!", { variant: "success" });

            // Ativar o bloqueio do botão por 2 minutos
            setDownloadTimeLeft(120); // 2 minutos (120 segundos)

            const interval = setInterval(() => {
                setDownloadTimeLeft((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsDownloading(false); // Libera o botão após o tempo
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (error) {
            enqueueSnackbar("❌ Erro ao baixar o boletim!", { variant: "error" });
            console.error('❌ Erro ao baixar o PDF:', error);
            setIsDownloading(false); // Libera o botão em caso de erro
        }
    };


    const dashboardContent = (
        <div className={styles.container}>
            {precisaSincronizar && (
                <div className={styles.sincronizacaoAviso}>
                    🔄 <strong>Este boletim possui atualizações disponíveis. Clique em "Sincronizar" para aplicar.</strong>
                </div>
            )}
            {loading ? (
                <Loading />
            ) : !boletimAluno ? (
                <p>Boletim ou Relatório não encontrado.</p>
            ) : (
                <>
                    <h2 className={styles.boletimTitulo}>Detalhes do Boletim ou Relatório</h2>
                    <div className={styles.alunoInfo}>
                        <p><strong>Nome:</strong> {boletimAluno?.alunoId?.firstName + " " + boletimAluno?.alunoId?.lastName}</p>
                        <p><strong>Matrícula:</strong> {boletimAluno.matricula}</p>
                        <p><strong>Série:</strong> {boletimAluno.serie}</p>
                        <p><strong>Turno:</strong> {boletimAluno.turno}</p>
                        <p><strong>Nível de Ensino:</strong> {boletimAluno.nivelEnsino}</p>
                    </div>
                    {boletimAluno.nivelEnsino === 'Educação Infantil' ? (
                        <BoletimInfantilComponent
                            boletimAluno={boletimAluno}
                            tenantId={tenantId}
                            boletimId={boletimId}
                            alunoId={alunoId}
                        />
                    ) : boletimAluno?.bimestres && boletimAluno?.bimestres?.length > 0 ? (
                        <BoletimComponent
                            boletimAluno={boletimAluno}
                            tenantId={tenantId}
                            boletimId={boletimId}
                            alunoId={alunoId}
                        />
                    ) : (
                        <p>Nenhuma nota disponível para este boletim.</p>
                    )}
                </>
            )}
        </div>
    );

    const handleSincronizarBoletim = async () => {
        try {
            const data = await sincronizarBoletimAluno({
                tenantId,
                boletimId,
                alunoId,
            });

            enqueueSnackbar("✅ Boletim sincronizado com sucesso!", { variant: "success" });
            setBoletimAluno(data); // atualiza com o retorno sincronizado
            setTimeout(() => {
                window.location.reload(); // Recarrega a página após 1s
            }, 1000);
        } catch (err) {
            console.error("Erro ao sincronizar boletim:", err);
            enqueueSnackbar("❌ Erro ao sincronizar o boletim!", { variant: "error" });
        }
    };


    const buttons = [
        {
            label: 'Sincronizar',
            onClick: handleSincronizarBoletim,
            icon: FaSyncAlt
        },
        {
            label: isDownloading ? `Aguarde ${downloadTimeLeft}s` : 'Baixar PDF',
            icon: MdOutlinePictureAsPdf,
            onClick: handleDownloadPDF,
            disabled: isDownloading, // Desativa o botão enquanto está no tempo de espera
        },
    ];

    return (
        <InterfaceStructure
            itemsPages={itemsPages || []}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={true}
                    returnLabel="Voltar"
                    returnPath={`/boletins/${boletimId}`}
                    namePage="Detalhes do Boletim"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BoletimAlunoDetailComponent;
