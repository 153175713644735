import { useState, useEffect } from "react";
import apiAxios from "../../api/apiAxios";

const baseUrl = "/api/avisos";

const useNotificacoes = (filtrosIniciais = {}) => {
    const [notificacoes, setNotificacoes] = useState([]);
    const [loading, setLoading] = useState(false);

    // 1. Buscar notificações
    const fetchNotificacoes = async (filtros = filtrosIniciais) => {
        setLoading(true);
        try {
            const response = await apiAxios.get(baseUrl, { params: filtros });
            setNotificacoes(response.data || []);
        } catch (error) {
            console.error("❌ Erro ao buscar notificações:", error);
            setNotificacoes([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotificacoes();
    }, []);

    const getAvisoPorId = async (id) => {
        try {
            const response = await apiAxios.get(`${baseUrl}/${id}`);
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao buscar aviso:", error);
            return null;
        }
    };

    const criarAviso = async (dados) => {
        try {
            const response = await apiAxios.post(baseUrl, dados);
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao criar aviso:", error);
            throw error;
        }
    };

    const editarAviso = async (id, dados) => {
        try {
            const response = await apiAxios.patch(`${baseUrl}/${id}`, dados);
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao editar aviso:", error);
            throw error;
        }
    };

    const deletarAviso = async (id) => {
        try {
            await apiAxios.delete(`${baseUrl}/${id}`);
        } catch (error) {
            console.error("❌ Erro ao deletar aviso:", error);
            throw error;
        }
    };

    const marcarComoVisualizado = async (id, usuarioId) => {
        try {
            const response = await apiAxios.post(`${baseUrl}/${id}/visualizar`, { usuarioId });
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao marcar como visualizado:", error);
            throw error;
        }
    };

    const interagirComAviso = async (id, usuarioId, tipo) => {
        try {
            const response = await apiAxios.post(`${baseUrl}/${id}/interagir`, { usuarioId, tipo });
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao interagir com aviso:", error);
            throw error;
        }
    };

    const getEstatisticas = async (id) => {
        try {
            const response = await apiAxios.get(`${baseUrl}/${id}/estatisticas`);
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao buscar estatísticas:", error);
            return null;
        }
    };

    const liberarAviso = async (id) => {
        try {
            const response = await apiAxios.patch(`${baseUrl}/${id}/liberar`);
            return response.data;
        } catch (error) {
            console.error("❌ Erro ao liberar aviso:", error);
            throw error;
        }
    };

    return {
        notificacoes,
        loading,
        fetchNotificacoes,
        getAvisoPorId,
        criarAviso,
        editarAviso,
        deletarAviso,
        marcarComoVisualizado,
        interagirComAviso,
        getEstatisticas,
        liberarAviso, // 🆕 Função de liberação com envio de e-mail
    };
};

export default useNotificacoes;
