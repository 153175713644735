import { useEffect, useState } from "react";
import apiAxios from "../api/apiAxios";

const UseUserById = (userId) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastFetchedId, setLastFetchedId] = useState(null); // 🔥 Evita chamadas repetidas

    // 🔥 Função para buscar usuário pelo ID
    const getUserById = async (id) => {
        if (!id || id === lastFetchedId) return; // ✅ Evita requisições desnecessárias
        setLoading(true);

        try {
            const response = await apiAxios.get(`/api/user/user/${id}`);
            setUser(response.data.user); // ✅ Pegando os dados do servidor
            setError(null);
            setLastFetchedId(id); // ✅ Atualiza o ID da última requisição bem-sucedida
        } catch (err) {
            console.error("❌ Erro ao buscar usuário:", err);
            setError(err.response?.data?.message || "Erro ao carregar usuário.");
        }

        setLoading(false);
    };

    // 🔥 useEffect para buscar automaticamente ao montar o hook
    useEffect(() => {
        if (userId) {
            getUserById(userId);
        }
    }, [userId]); // ✅ Só roda quando userId mudar

    

    return { user, loading, error, getUserById };
};

export default UseUserById;
