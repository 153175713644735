import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Card, CardContent, Typography, Button, Chip, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import apiAxios from "../../../api/apiAxios";
import styles from "./BancoDeHorasDetailComponent.module.css";
import InterfaceStructure from "../../shared/InterfaceStructure";
import BasicPageStructure from "../../shared/BasicPageStructure";
import { IoIosClose } from "react-icons/io";
import Loading from "../../Loading/Loading";
import useUserId from "../../useUserId";
import { getUserById } from "../../../actions/UserActions";

const BancoDeHorasDetailComponent = ({ itemsPages }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAdjustPopupOpen, setIsAdjustPopupOpen] = useState(false);
    const [dataLancamento, setDataLancamento] = useState('');

    // Estados para controle dos pop-ups
    const [isCreditPopupOpen, setIsCreditPopupOpen] = useState(false);
    const [isDebitPopupOpen, setIsDebitPopupOpen] = useState(false);

    // Estados para os inputs de tempo
    const [dias, setDias] = useState("");
    const [horas, setHoras] = useState("");
    const [minutos, setMinutos] = useState("");

    const [descricao, setDescricao] = useState("");
    const [aprovadoPor, setAprovadoPor] = useState("");

    const UserId = useUserId();
    const [User, setUser] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await getUserById(UserId); // Aguarda a resposta
                setUser(userData); // Armazena no estado
            } catch (error) {
                console.error("Erro ao buscar usuário:", error);
            }
        };

        if (UserId) {
            fetchUser(); // Chama a função assíncrona
        }
    }, [UserId]);

    const fetchUsuario = useCallback(async () => {
        setLoading(true);
        try {
            const response = await apiAxios.get(`/api/banco_de_horas/colaborador/${id}`);
            setUsuario(response.data);
        } catch (error) {
            console.error("Erro ao buscar detalhes do usuário:", error);
            setUsuario(null);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchUsuario();
    }, [fetchUsuario]);


    useEffect(() => {
        if (selectedRows.length !== 1 || !usuario?.registros) {
            return;
        }

        const registroSelecionado = usuario.registros.find(r => r._id === selectedRows[0]);

        if (registroSelecionado) {
            const [d, h, m] = registroSelecionado.horas.split(":"); // Divide o tempo recebido do back
            setDias(d || "00");
            setHoras(h || "00");
            setMinutos(m || "00");
            setDescricao(registroSelecionado.descricao || "");
            setAprovadoPor(registroSelecionado.aprovadoPor || "");
        }
    }, [selectedRows, usuario?.registros]);

    const getStatusColor = (status) => {
        const statusColors = {
            "Aprovado": "success",
            "Pendente": "warning",
            "Rejeitado": "error"
        };
        return statusColors[status] || "default";
    };

    if (loading) return <div className={styles.loading}><Loading /></div>;
    if (!usuario) return <div className={styles.error}>Usuário não encontrado.</div>;

    // 🚀 Formata os inputs para o padrão "dd:hh:mm"
    const formatarTempo = () => {
        return `${dias.padStart(2, "0")}:${horas.padStart(2, "0")}:${minutos.padStart(2, "0")}`;
    };

    // 📌 Creditar horas
    const handleCreditarHoras = async () => {
        const horasFormatadas = formatarTempo();
        try {
            await apiAxios.post(`/api/banco_de_horas/${id}/creditar`, { horas: horasFormatadas, descricao, aprovadoPor: User.firstName + " " + User.lastName, data: dataLancamento });
            alert("Horas creditadas com sucesso!");
            setIsCreditPopupOpen(false);
            fetchUsuario(); // Atualiza os dados sem precisar recarregar a página
        } catch (error) {
            console.error("Erro ao creditar horas:", error);
            alert("Erro ao creditar horas. Verifique os dados.");
        }
    };

    // 📌 Debitar horas
    const handleDebitarHoras = async () => {
        const horasFormatadas = formatarTempo();
        try {
            await apiAxios.post(`/api/banco_de_horas/${id}/debitar`, { horas: horasFormatadas, descricao, aprovadoPor: User.firstName + " " + User.lastName, data: dataLancamento });
            alert("Horas debitadas com sucesso!");
            setIsDebitPopupOpen(false);
            fetchUsuario(); // Atualiza os dados sem precisar recarregar a página
        } catch (error) {
            console.error("Erro ao debitar horas:", error);
            alert("Erro ao debitar horas. Verifique os dados.");
        }
    };

    const handleDeleteRegistro = async () => {
        try {
            if (!selectedRows[0]) {
                alert("Nenhum registro selecionado para excluir.");
                return;
            }

            const confirmDelete = window.confirm("Tem certeza que deseja excluir este registro?");
            if (!confirmDelete) return;

            await apiAxios.delete(`/api/banco_de_horas/${id}/registros/${selectedRows[0]}`);
            alert("Registro excluído com sucesso!");
            setSelectedRows([]); // Remove a seleção
            fetchUsuario(); // Atualiza a tabela após exclusão
        } catch (error) {
            console.error("Erro ao excluir registro:", error);
            alert("Erro ao excluir registro.");
        }
    };


    const handleSelectionChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
    };

    // 📊 Configuração do gráfico de linha
    const chartData = {
        labels: (usuario.registros || []).map((h) => new Date(h.data).toLocaleDateString()),
        datasets: [
            {
                label: "Saldo de Horas",
                data: (usuario.registros || []).map((h) => {
                    const [dias, horas, minutos] = h.horas.split(":").map(Number);
                    return dias * 24 + horas + minutos / 60; // Converte para horas decimais
                }),
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 2,
                fill: true,
            }
        ]
    };

    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    buttons={[]}
                    content={
                        <div className={styles.container}>
                            <div className={styles.header}>
                                <Typography variant="h5">{usuario.colaboradorNome || "Sem Nome"}</Typography>
                                <Chip label={usuario.status || "Sem Status"} color={getStatusColor(usuario.status)} className={styles.status} />
                            </div>

                            {/* Cards Informativos */}
                            <div className={styles.details}>
                                <Card className={styles.card}>
                                    <CardContent>
                                        <Typography variant="h6">Cargo</Typography>
                                        <Typography variant="h4">{usuario.cargo || "Não informado"}</Typography>
                                    </CardContent>
                                </Card>
                                <Card className={styles.card}>
                                    <CardContent>
                                        <Typography variant="h6">Saldo de Horas</Typography>
                                        {usuario.saldoHoras ? (
                                            parseFloat(usuario.saldoHoras.replace(":", ".")) < 0 ? (
                                                <Typography variant="h4" color="error">
                                                    Débito de {usuario.saldoHoras.replace("-", "")}h
                                                </Typography>
                                            ) : (
                                                <Typography variant="h4" color="green">
                                                    Crédito total de {usuario.saldoHoras}
                                                </Typography>
                                            )
                                        ) : (
                                            <Typography variant="h4">00:00:00h</Typography>
                                        )}
                                    </CardContent>
                                </Card>

                            </div>

                            {/* Gráfico */}
                            <div className={styles.chartContainer}>
                                <Typography style={{ paddingLeft: "7px", color: "rgb(163, 26, 26)", fontSize: "25px" }} variant="h6">Evolução do Saldo de Horas</Typography>
                                <Line data={chartData} />
                            </div>

                            {selectedRows.length > 0 && (
                                <div className={styles.actionButtons}>
                                    <div className={styles.actionsLeft}>
                                        {selectedRows.length === 1 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setIsAdjustPopupOpen(true)} // Agora o popup só abre ao clicar no botão
                                            >
                                                Ajustar Selecionado
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={handleDeleteRegistro}
                                        >
                                            Excluir Registro
                                        </Button>
                                    </div>
                                    <button onClick={() => setSelectedRows([])} className={styles.closeButton}>
                                        <IoIosClose size={24} />
                                    </button>
                                </div>
                            )}

                            {/* Tabela de Histórico */}
                            <div className={styles.historico}>
                                <div className={styles.top}>
                                    <Typography style={{ paddingLeft: "7px", color: "rgb(163, 26, 26)", fontSize: "25px" }} variant="h6">Histórico de Movimentações</Typography>
                                    {/* Botões de ação */}
                                    <div className={styles.actions}>
                                        <Button variant="contained" color="primary" onClick={() => setIsCreditPopupOpen(true)}>
                                            Creditar Hora
                                        </Button>

                                        <Button variant="contained" color="secondary" onClick={() => setIsDebitPopupOpen(true)}>
                                            Debitar Hora
                                        </Button>
                                    </div>

                                </div>
                                <DataGrid
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelectionChange}
                                    rowSelectionModel={selectedRows}
                                    rows={(usuario.registros || []).map((h, index) => ({
                                        id: h._id || index,
                                        data: new Date(h.data).toLocaleString('pt-BR', {
                                            timeZone: 'UTC', // <--- FORÇA UTC
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        }),
                                        horas: h.horas,
                                        tipo: h.tipo,
                                        descricao: h.descricao,
                                        status: h.status,
                                        aprovadoPor: h.aprovadoPor || "N/A"
                                    }))}
                                    columns={[
                                        { field: "data", headerName: "Data", flex: 1 },
                                        { field: "horas", headerName: "Horas", flex: 1 },
                                        { field: "tipo", headerName: "Tipo", flex: 1 },
                                        { field: "descricao", headerName: "Descrição", flex: 1 },
                                        { field: "status", headerName: "Status", flex: 1 },
                                        { field: "aprovadoPor", headerName: "Ajustado Por", flex: 1 },
                                    ]}
                                    pageSize={5}
                                />
                            </div>

                            <Dialog open={isAdjustPopupOpen} onClose={() => setIsAdjustPopupOpen(false)}>
                                <DialogTitle>Ajustar Selecionado</DialogTitle>
                                <DialogContent style={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "10px" }}>
                                    {/* Campo para Dias */}
                                    <TextField
                                        label="Dias"
                                        type="number"
                                        fullWidth
                                        value={dias}
                                        onChange={(e) => setDias(e.target.value)}
                                    />

                                    {/* Campo para Horas */}
                                    <TextField
                                        label="Horas"
                                        type="number"
                                        fullWidth
                                        value={horas}
                                        onChange={(e) => setHoras(e.target.value)}
                                    />

                                    {/* Campo para Minutos */}
                                    <TextField
                                        label="Minutos"
                                        type="number"
                                        fullWidth
                                        value={minutos}
                                        onChange={(e) => setMinutos(e.target.value)}
                                    />

                                    {/* 🔹 Agora o usuário pode escrever o motivo do ajuste */}
                                    <TextField
                                        label="Motivo do Ajuste"
                                        fullWidth
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                    />

                                    <TextField
                                        label="Data"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={dataLancamento}
                                        onChange={(e) => setDataLancamento(e.target.value)}
                                    />

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setIsAdjustPopupOpen(false)}>Cancelar</Button>
                                    <Button
                                        onClick={async () => {
                                            try {
                                                const registroId = selectedRows[0]; // O ID do registro selecionado
                                                await apiAxios.put(`/api/banco_de_horas/${id}/registros/${registroId}/ajustar`, {
                                                    horasAjustadas: `${dias.padStart(2, "0")}:${horas.padStart(2, "0")}:${minutos.padStart(2, "0")}`,
                                                    motivo: descricao, // Agora pega o motivo digitado pelo usuário
                                                    ajustadoPor: `${User.firstName} ${User.lastName}`,
                                                    data: dataLancamento
                                                });
                                                alert("Registro ajustado com sucesso!");
                                            } catch (error) {
                                                console.error("Erro ao ajustar registro:", error);
                                                alert("Erro ao ajustar registro.");
                                            }
                                        }}
                                        color="primary"
                                    >
                                        Confirmar Ajuste
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            {/* Pop-up para Creditar Hora */}
                            <Dialog style={{}} open={isCreditPopupOpen} onClose={() => setIsCreditPopupOpen(false)}>
                                <DialogTitle>Creditar Hora</DialogTitle>
                                <DialogContent style={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "10px" }}>
                                    <TextField label="Dias" type="number" fullWidth value={dias} onChange={(e) => setDias(e.target.value)} />
                                    <TextField label="Horas" type="number" fullWidth value={horas} onChange={(e) => setHoras(e.target.value)} />
                                    <TextField label="Minutos" type="number" fullWidth value={minutos} onChange={(e) => setMinutos(e.target.value)} />
                                    <TextField label="Descrição" fullWidth value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                    <TextField
                                        label="Data"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={dataLancamento}
                                        onChange={(e) => setDataLancamento(e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setIsCreditPopupOpen(false)}>Cancelar</Button>
                                    <Button onClick={handleCreditarHoras} color="primary">Confirmar</Button>
                                </DialogActions>
                            </Dialog>

                            <Button style={{ marginBottom: "10px", background: "#c4292e" }} variant="contained" onClick={() => navigate(-1)}>Voltar</Button>

                            {/* Pop-up para Debitar Hora */}
                            <Dialog open={isDebitPopupOpen} onClose={() => setIsDebitPopupOpen(false)}>
                                <DialogTitle>Debitar Hora</DialogTitle>
                                <DialogContent style={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "10px" }}>
                                    <TextField label="Dias" type="number" fullWidth value={dias} onChange={(e) => setDias(e.target.value)} />
                                    <TextField label="Horas" type="number" fullWidth value={horas} onChange={(e) => setHoras(e.target.value)} />
                                    <TextField label="Minutos" type="number" fullWidth value={minutos} onChange={(e) => setMinutos(e.target.value)} />
                                    <TextField label="Descrição" fullWidth value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                    <TextField
                                        label="Data"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={dataLancamento}
                                        onChange={(e) => setDataLancamento(e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setIsDebitPopupOpen(false)}>Cancelar</Button>
                                    <Button onClick={handleDebitarHoras} color="secondary">Confirmar</Button>
                                </DialogActions>
                            </Dialog>
                        </div >
                    }
                    returnPage={true}
                    returnLabel="Voltar"
                    returnPath={`/banco_de_horas`}
                    namePage="Banco de Horas"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BancoDeHorasDetailComponent;
