import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CalendarioComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioComponent';

const CalendarioProfessorPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Calendário', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio','Banco de horas']);

  return (
    <div>
      <CalendarioComponent itemsPages={itemsPagesList} accessLevel="low" />
    </div>
  );
};

export default CalendarioProfessorPage;
