// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD36AtsKu_RpclR9UlLnkb0u4jz1i09KHw",
  authDomain: "educacaoalternativa360.firebaseapp.com",
  projectId: "educacaoalternativa360",
  storageBucket: "educacaoalternativa360.appspot.com",
  messagingSenderId: "3396927899",
  appId: "1:3396927899:web:f543b65c49160cec26a13e",
  measurementId: "G-E27MX9XBDZ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);


export { app, auth, provider, signInWithPopup };