import React, { useState } from "react";
import styles from "./popUp4Component.module.css";
import { X } from "lucide-react";
import DefaultButton from "../buttons/DefaultButton";

const PopUp4Component = ({ isOpen, title, message, onConfirm, onCancel }) => {
    const [inputValue, setInputValue] = useState(""); // Estado para armazenar o texto digitado
    const confirmText = "DELETAR"; // Palavra-chave para confirmação

    if (!isOpen) return null;

    return (
        <div className={styles.overlay} onClick={onCancel}>
            <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
                {/* Cabeçalho */}
                <div className={styles.header}>
                    <h2>{title}</h2>
                    <button className={styles.closeButton} onClick={onCancel}>
                        <X size={20} />
                    </button>
                </div>

                {/* Mensagem */}
                <p className={styles.message}>{message}</p>

                {/* Campo de confirmação */}
                <p className={styles.warning}>
                    Esta ação é irreversível. Digite <strong>{confirmText}</strong> para confirmar.
                </p>
                <input
                    type="text"
                    className={styles.input}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={`Digite "${confirmText}"`}
                />

                {/* Botões de Ação */}
                <div className={styles.footer}>
                    <DefaultButton 
                        text="Cancelar" 
                        onClick={onCancel} 
                        backgroundColor="#ffffff" 
                        color="#374151" 
                        borderColor="#d1d5db"
                    />
                    <DefaultButton 
                        text="Confirmar" 
                        onClick={onConfirm} 
                        backgroundColor={inputValue === confirmText ? "#dc2626" : "#9ca3af"} 
                        color="#ffffff"
                        disabled={inputValue !== confirmText} // Desativa até que o usuário digite corretamente
                    />
                </div>
            </div>
        </div>
    );
};

export default PopUp4Component;
