import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import styles from './EditCoordenacaoUserPage.module.css';
import { MdKeyboardArrowLeft} from 'react-icons/md';
import apiAxios from '../../api/apiAxios';
import { Link, useParams } from 'react-router-dom';
import InterfaceStructure from '../../components/shared/InterfaceStructure';
import { series, turno } from '../../DataSeries';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import GetTenantId from '../../hooks/getTenantId';

const accessLabels = {
    isResponsavel: 'Responsável',
    isAluno: 'Aluno',
    isCoordenaçao: 'Coordenação',
    isNutricionista: 'Nutricionista',
    isProfessor: 'Professor',
    isSecretaria: 'Secretária'
};

const itemsPagesList = itemsPagesWithExclusions('', ['Agenda','Informes','Atividades','Chat','Cardápio','Banco de horas']);

const EditCoordenacaoUserPage = () => {
    const { id } = useParams();
    const tenantId = GetTenantId();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        cpf: '',
        phoneNumber: '',
        access: '',
        alunoInfo: { serie: '', turno: ''},
        responsavelInfo: [],
        professorInfo: []
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${id}`);
                const { user } = response.data;
                if (user) {
                    setFormData({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        cpf: user.CPF,
                        phoneNumber: user.phoneNumber,
                        access: determineAccess(user),
                        alunoInfo: user.alunoInfo || { serie: '', turno: ''},
                        responsavelInfo: user.responsavelInfo.length ? user.responsavelInfo : [{ nomeAluno: '', serie: '', turno: '', CPF: '' }],
                        professorInfo: user.professorInfo.length ? user.professorInfo : [{ serie: '', turno: '' }]
                    });
                } else {
                    enqueueSnackbar('Nenhum usuário encontrado.', { variant: 'warning' });
                }
            } catch (error) {
                enqueueSnackbar('Erro ao carregar dados do usuário: ' + error.message, { variant: 'error' });
            }
        };
        fetchUserData();
    }, [id, enqueueSnackbar]);

    const determineAccess = (user) => {
        if (user.isResponsavel) return 'isResponsavel';
        if (user.isAluno) return 'isAluno';
        if (user.isCoordenaçao) return 'isCoordenaçao';
        if (user.isNutricionista) return 'isNutricionista';
        if (user.isProfessor) return 'isProfessor';
        if (user.isSecretaria) return 'isSecretaria';
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('alunoInfo')) {
            const key = name.split('[')[1].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                alunoInfo: {
                    ...prevState.alunoInfo,
                    [key]: value
                }
            }));
        } else if (name.startsWith('responsavelInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                responsavelInfo: prevState.responsavelInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else if (name.startsWith('professorInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                professorInfo: prevState.professorInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleAccessChange = (e) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            access: value,
            alunoInfo: value === 'isAluno' ? prevState.alunoInfo : { serie: '', turno: ''},
            responsavelInfo: value === 'isResponsavel' ? (prevState.responsavelInfo.length ? prevState.responsavelInfo : [{ nomeAluno: '', serie: '', turno: '', CPF: ''}]) : [],
            professorInfo: value === 'isProfessor' ? (prevState.professorInfo.length ? prevState.professorInfo : [{ serie: '', turno: '' }]) : []
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { access, alunoInfo, responsavelInfo, professorInfo, ...basicData } = formData;

        const updatedData = {
            ...basicData,
            isResponsavel: access === 'isResponsavel',
            isAluno: access === 'isAluno',
            isCoordenaçao: access === 'isCoordenaçao',
            isNutricionista: access === 'isNutricionista',
            isProfessor: access === 'isProfessor',
            isSecretaria: access === 'isSecretaria',
            alunoInfo: access === 'isAluno' ? alunoInfo : undefined,
            responsavelInfo: access === 'isResponsavel' ? responsavelInfo : undefined,
            professorInfo: access === 'isProfessor' ? professorInfo : undefined
        };

        try {
            await apiAxios.put(`/api/user/users/access/${id}`, updatedData);
            enqueueSnackbar('Usuário atualizado com sucesso!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Erro ao atualizar usuário: ' + error.message, { variant: 'error' });
        }
    };

    const addResponsavelInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            responsavelInfo: [...prevState.responsavelInfo, { nomeAluno: '', serie: '', turno: '', CPF: ''}]
        }));
    };

    const addProfessorInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            professorInfo: [...prevState.professorInfo, { serie: '', turno: '' }]
        }));
    };

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/usuarios'><MdKeyboardArrowLeft />Usuários</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Editar Usuário</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        ...
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.layout}>
                        <p>Informações Básicas do Usuário:</p>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                            placeholder='Nome'
                        />
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                            placeholder='Sobrenome'
                        />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            placeholder='Email'
                        />
                        <input
                            type="text"
                            name="cpf"
                            value={formData.cpf}
                            onChange={handleChange}
                            required
                            placeholder='CPF'
                        />
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                            placeholder='Número de Telefone'
                        />
                        <p>Tipo de Acesso:</p>
                        <div className={styles.checkboxGroup}>
                            {['isResponsavel', 'isAluno', 'isNutricionista', 'isProfessor'].map(accessType => (
                                <label key={accessType} className={styles.checkboxLabel}>
                                    <input
                                        type="radio"
                                        name="access"
                                        value={accessType}
                                        checked={formData.access === accessType}
                                        onChange={handleAccessChange}
                                    />
                                    {accessLabels[accessType]}
                                </label>
                            ))}
                        </div>
                        {formData.access === 'isAluno' && (
                            <div className={styles.layout}>
                                <p>Informações do Aluno:</p>
                                <select
                                    name="alunoInfo[serie]"
                                    value={formData.alunoInfo.serie}
                                    onChange={handleChange}
                                >
                                    <option value="">Selecione a Série</option>
                                    {series.map((serie) => (
                                        <option key={serie} value={serie}>{serie}</option>
                                    ))}
                                </select>
                                <select
                                    name="alunoInfo[turno]"
                                    value={formData.alunoInfo.turno}
                                    onChange={handleChange}
                                >
                                    <option value="">Selecione o Turno</option>
                                    {turno.map((turnoOption) => (
                                        <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {formData.access === 'isResponsavel' && (
                            <div className={styles.layout}>
                                <p>Informações do Responsável:</p>
                                {formData.responsavelInfo.map((info, index) => (
                                    <div key={index} className={styles.responsavelInfo}>
                                        <input
                                            type="text"
                                            name={`responsavelInfo[${index}][nomeAluno]`}
                                            value={info.nomeAluno}
                                            onChange={handleChange}
                                            placeholder='Nome do Aluno'
                                        />
                                        <select
                                            name={`responsavelInfo[${index}][serie]`}
                                            value={info.serie}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione a Série</option>
                                            {series.map((serie) => (
                                                <option key={serie} value={serie}>{serie}</option>
                                            ))}
                                        </select>
                                        <select
                                            name={`responsavelInfo[${index}][turno]`}
                                            value={info.turno}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Turno</option>
                                            {turno.map((turnoOption) => (
                                                <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            name={`responsavelInfo[${index}][CPF]`}
                                            value={info.CPF}
                                            onChange={handleChange}
                                            placeholder='CPF'
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {formData.access === 'isProfessor' && (
                            <div className={styles.layout}>
                                <p>Informações do Professor:</p>
                                {formData.professorInfo.map((info, index) => (
                                    <div key={index} className={styles.professorInfo}>
                                        <select
                                            name={`professorInfo[${index}][serie]`}
                                            value={info.serie}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione a Série</option>
                                            {series.map((serie) => (
                                                <option key={serie} value={serie}>{serie}</option>
                                            ))}
                                        </select>
                                        <select
                                            name={`professorInfo[${index}][turno]`}
                                            value={info.turno}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Turno</option>
                                            {turno.map((turnoOption) => (
                                                <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                            ))}
                                        </select>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                    <div className={styles.layout2}>
                        <button type="submit">Atualizar Usuário</button>
                        {formData.access === 'isProfessor' && (
                            <button style={{ color: "gray", background: "white", border: "1px solid gray" }} type="button" onClick={addProfessorInfo}>Adicionar Professor</button>
                        )}

                        {formData.access === 'isResponsavel' && (
                            <button style={{ color: "gray", background: "white", border: "1px solid gray" }} type="button" onClick={addResponsavelInfo}>Adicionar Responsável</button>
                        )}

                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
}

export default EditCoordenacaoUserPage;
