import React, { useState, useEffect } from "react"; 
import { TextField, MenuItem, Button, CircularProgress, Paper } from "@mui/material";
import apiAxios from "../../../api/apiAxios";
import styles from "./BancoDeHorasCreateComponent.module.css";
import Message from "../tools/Message";
import { getUserById } from "../../../actions/UserActions";
import useUserId from "../../useUserId";
import GetTenantId from "../../../hooks/getTenantId";

const BancoDeHorasEditComponent = ({ onClose, onRefresh, registroParaEditar }) => {
    const tenantId = GetTenantId();
    const UserId = useUserId();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: "", text: "" });
    const [user, setUser] = useState(null);

    // Estado inicial do formulário (vazio até carregar os dados)
    const [formData, setFormData] = useState({
        colaboradorNome: "",
        cargo: "",
        status: "Pendente",
    });

    // 🚀 Atualiza o estado do formulário quando `registroParaEditar` for carregado
    useEffect(() => {
        if (registroParaEditar) {
            setFormData({
                colaboradorNome: registroParaEditar.colaboradorNome || "",
                cargo: registroParaEditar.cargo || "",
                status: registroParaEditar.status || "Pendente",
            });
        }
    }, [registroParaEditar]);

    // 🚀 Buscar dados do usuário logado
    useEffect(() => {
        if (!UserId) return;

        const fetchUser = async () => {
            try {
                const userData = await getUserById(UserId);
                setUser(userData);
            } catch (error) {
                console.error("❌ Erro ao buscar usuário:", error);
            }
        };

        fetchUser();
    }, [UserId]);

    // 📌 Atualiza o estado ao mudar os inputs
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // ❌ Fechar o popup
    const handleCancel = () => {
        if (onClose) {
            onClose();
        }
    };

    // ✅ Submeter a edição
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true);
        setMessage({ type: "", text: "" });

        if (!formData.colaboradorNome || !formData.cargo) {
            setMessage({ type: "warning", text: "Preencha todos os campos obrigatórios." });
            setLoading(false);
            return;
        }

        try {
            await apiAxios.put(`/api/banco_de_horas/${registroParaEditar._id}`, {
                tenantId,
                colaboradorNome: formData.colaboradorNome,
                cargo: formData.cargo,
                status: formData.status,
                atualizadoPor: `${user?.firstName} ${user?.lastName}`,
            });

            setMessage({ type: "success", text: "Dados atualizados com sucesso!" });

            if (onRefresh) onRefresh();

            setTimeout(() => {
                if (onClose) onClose();
            }, 2000);
        } catch (error) {
            console.error("Erro ao atualizar colaborador:", error);
            setMessage({ type: "error", text: "Erro ao atualizar os dados." });
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
        <Paper style={{ border: "none", boxShadow: "none" }} className={styles.container} elevation={3}>
            {message.text && <Message type={message.type} message={message.text} />}

            <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                    label="Nome do Colaborador"
                    name="colaboradorNome"
                    value={formData.colaboradorNome}
                    onChange={handleChange}
                    fullWidth
                    required
                />

                <TextField
                    label="Cargo"
                    name="cargo"
                    value={formData.cargo}
                    onChange={handleChange}
                    fullWidth
                    required
                />

                <TextField
                    label="Status"
                    name="status"
                    select
                    value={formData.status}
                    onChange={handleChange}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: { disablePortal: true } }}
                >
                    <MenuItem value="Aprovado">Aprovado</MenuItem>
                    <MenuItem value="Pendente">Pendente</MenuItem>
                    <MenuItem value="Rejeitado">Rejeitado</MenuItem>
                </TextField>

                <div className={styles.actions}>
                    <Button onClick={handleCancel} variant="outlined" className={styles.cancelBtn}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : "Salvar Alterações"}
                    </Button>
                </div>
            </form>
        </Paper>
    );
};

export default BancoDeHorasEditComponent;
