import React, { useState, useEffect, useCallback } from 'react';
import styles from './CalendarioSecretariaCreatePage.module.css';
import { MdKeyboardArrowLeft, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from "react-icons/md";
import { LuApple } from "react-icons/lu";
import { MdCalendarToday } from "react-icons/md";
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { Link, useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../hooks/getTenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RiMegaphoneLine } from 'react-icons/ri';
import { PiPencilSimpleLineBold } from 'react-icons/pi';
import { TfiAgenda } from 'react-icons/tfi';
import GetTenantId from '../../../hooks/getTenantId';

const CalendarioSecretariaCreatePage = () => {
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState(EditorState.createEmpty()); // Utilizando EditorState para o estado da descrição
    const [categoriaId, setCategoriaId] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const tenantId = GetTenantId();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
                console.log("Categorias recebidas do servidor:", response.data);
                setCategorias(response.data);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };
        fetchCategorias();
    }, [tenantId]);

    // Utilizando useCallback para otimizar o onChange do react-draft-wysiwyg
    const handleDescricaoChange = useCallback((editorState) => {
        setDescricao(editorState);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoriaId) {
            console.error('ID da Categoria não pode ser vazio');
            return;
        }

        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");

        // Convertendo o conteúdo para JSON para salvar no banco de dados, se necessário
        const contentState = descricao.getCurrentContent();
        const rawContent = convertToRaw(contentState);

        const novoEvento = {
            tenantId: tenantId,
            Titulo: titulo,
            descricao: JSON.stringify(rawContent), // Salvando como JSON
            categoria: categoriaId,
            date: formattedDate
        };
        console.log(novoEvento);
        try {
            const response = await apiAxios.post('/api/calendar/', novoEvento);
            console.log(response);
            navigate('/calendario');
        } catch (error) {
            console.error('Erro ao criar evento no calendário:', error);
        }
    };

    const itemsPages = [
        {
            id: 1,
            name: "Painel",
            icon: <MdOutlineSpaceDashboard />,
            isSelect: false,
            link: "/"
          },
          {
            id: 2,
            name: "Agenda",
            icon: <TfiAgenda />,
            isSelect: false,
            link: "/agenda"
          },
          {
            id: 3,
            name: "Planos",
            icon: <MdOutlineClass />,
            isSelect: false,
            link: "/planos"
          },
          {
            id: 4,
            name: "Calendário",
            icon: <MdCalendarToday />,
            isSelect: false,
            link: "/calendario"
          },
          {
            id: 5,
            name: "Cardápio",
            icon: <LuApple />,
            isSelect: false,
            link: "/cardapio"
          },
          {
            id: 6,
            name: "Informes",
            icon: <RiMegaphoneLine />,
            isSelect: false,
            link: "/informes"
          },
          {
            id: 7,
            name: "Atividades",
            icon: <PiPencilSimpleLineBold />,
            isSelect: false,
            link: "/atividades"
          },
          { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    const selectedCategoria = categorias.find(categoria => categoria._id === categoriaId);
    const placeholderText = selectedCategoria ? <><span
        style={{
            display: 'inline-block',
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: selectedCategoria.category.color,
            marginRight: 10
        }}
    ></span>{selectedCategoria.category.name}</> : "Selecione uma categoria";

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/calendario'><MdKeyboardArrowLeft />Calendário</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Criar Evento no Calendário</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.BasicInfoForm}>
                        <p>Informações Básicas do Evento</p>
                        <input
                            type="text"
                            placeholder="Título do Evento"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            required
                        />
                        <Select
                            value={selectedCategoria ? { value: selectedCategoria._id, label: selectedCategoria.category.name } : null}
                            onChange={(option) => {
                                console.log("Categoria selecionada:", option);
                                setCategoriaId(option ? option.value : null);
                            }}
                            options={categorias.map(categoria => ({
                                value: categoria._id,
                                label: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                backgroundColor: categoria.category.color,
                                                marginRight: 10
                                            }}
                                        ></span>
                                        {categoria.category.name}
                                    </div>
                                )
                            }))}
                            placeholder={placeholderText}
                            className={styles.reactSelect}
                            isClearable
                        />
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="dd/MM/yyyy HH:mm"
                            placeholderText="Selecione a data e hora"
                            className={styles.datePicker}
                            required
                        />
                        <Editor
                            editorState={descricao}
                            onEditorStateChange={handleDescricaoChange}
                            wrapperClassName={styles.editorWrapper}
                            editorClassName={styles.editor}
                            toolbarClassName={styles.toolbar}
                            placeholder="Digite a descrição do evento..."
                            toolbar={{
                                options: ['inline', 'fontSize', 'list'],
                                inline: { options: ['bold', 'italic', 'underline'] },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                list: { options: ['unordered', 'ordered'] }
                            }}
                        />
                        <div className={styles.botoesDeControle}>
                            <button type="submit">Criar Evento</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
        </div>
    );
};

export default CalendarioSecretariaCreatePage;
