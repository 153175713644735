import React from "react";
import styles from "./DefaultButton.module.css";

const DefaultButton = ({ 
  text, 
  icon: Icon, 
  color = "#ffffff", 
  backgroundColor = "var(--primary-color)", 
  borderColor = "var(--primary-color)", 
  size = "medium", 
  onClick, 
  style = {} 
}) => {
  return (
    <button 
      className={`${styles.defaultButton} ${styles[size]} ${Icon ? styles.withIcon : styles.noIcon}`} 
      style={{ color, backgroundColor, border: `1px solid ${borderColor}`, ...style }} 
      onClick={onClick}
    >
      {Icon && <span className={styles.icon}><Icon /></span>} 
      <span className={styles.buttonText}>{text}</span>
    </button>
  );
};

export default DefaultButton;
