import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signin, signinWithGoogleHigh } from '../actions/UserActions';
import styles from './LoginPage.module.css'
import logo from '../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png'
import { Link } from 'react-router-dom';
import { PiStudentFill } from "react-icons/pi";
import SendEmailChangePass from '../components/SendEmailChangePass';
import Message from '../components/pagesComponents/tools/Message';
import { FcGoogle } from "react-icons/fc";
import useTenants from '../hooks/useTenants'; // 🔥 Importa o hook corretamente

const LoginPage = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    // 🔥 Usa o hook `useTenants` para pegar os tenants
    const { tenants, loading, error } = useTenants();

    const toggleModal = () => {
        setIsOpenModal(!isOpenModal);
    };

    const handleLogin = async () => {
        const errors = [];

        if (!email) errors.push("O campo de email é obrigatório.");
        if (!password) errors.push("O campo de senha é obrigatório.");
        if (!tenantId) errors.push("Você deve selecionar uma escola.");

        if (errors.length > 0) {
            setMessage(errors);
            setMessageType('warning');
            return;
        }

        try {
            const response = await dispatch(signin(email, password, tenantId));
            const data = response.payload;
            if (data.status === "success") {
                setMessage('Login realizado com sucesso!');
                setMessageType('success');
            } else {
                setMessage(data.message || 'Erro ao realizar login.');
                setMessageType('error');
            }
        } catch (error) {
            setMessage('Erro ao realizar login. Verifique suas credenciais.');
            setMessageType('error');
        }
    };

    const handleGoogleLoginHigh = async () => {
        try {
            dispatch(signinWithGoogleHigh());
        } catch (error) {
            console.error("Erro ao fazer login com Google", error);
        }
    };

    return (
        <div className={styles.all}>
            <div className={styles.formLogin}>
                {message && <Message type={messageType} message={message} />}
                <img className={styles.logo} src={logo} alt="logo" />
                <form>
                    <div>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label>Senha:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className={styles.recuperacao}>
                        <Link onClick={toggleModal}>Esqueceu sua senha?</Link>
                    </div>
                    <div>
                        <label>Sua escola:</label>
                        <select value={tenantId} onChange={(e) => setTenantId(e.target.value)}>
                            <option value="">Selecione a sua escola</option>
                            {loading ? (
                                <option disabled>Carregando escolas...</option>
                            ) : error ? (
                                <option disabled>Erro ao carregar escolas</option>
                            ) : tenants.length > 0 ? (
                                tenants.map(tenant => (
                                    <option key={tenant._id} value={tenant._id}>{tenant.SchoolName}</option>
                                ))
                            ) : (
                                <option disabled>Nenhuma escola encontrada</option>
                            )}
                        </select>
                    </div>
                    <button type="button" onClick={handleLogin}>Entrar</button>
                </form>
                <div className={styles.googleLoginContainer}>
                    <div className={styles.orContainer}>
                        <span className={styles.line}></span>
                        <p className={styles.orText}>ou</p>
                        <span className={styles.line}></span>
                    </div>

                    <button className={styles.googleButton} onClick={handleGoogleLoginHigh}>
                        <FcGoogle className={styles.googleIcon} />
                        Login com Google
                    </button>
                </div>
            </div>
            <div className={styles.GotAcess}>
                <p>Ainda não tem uma conta?</p> 
                <a href='http://acesso.educacaoalternativa360.com.br/escola'>Solicitar acesso</a>
            </div>
            <div className={styles.footerLogin}>
                <p>©Alternativo Colaço Soluções Tecnologicas.</p>
                <p>Todos os direitos reservados.</p>
            </div>
            <div className={styles.isAluno}>
                <Link to="http://web.educacaoalternativa360.com.br">
                    <PiStudentFill />
                    <p>Aluno</p>
                </Link>
            </div>
            {isOpenModal && <SendEmailChangePass CloseModal={toggleModal} />}
        </div>
    );
};

export default LoginPage;
