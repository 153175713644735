import React, { useState, useEffect } from 'react';
import styles from './EditFormComponent.module.css';
import { useSnackbar } from 'notistack'; // Importa o hook useSnackbar
import apiAxios from '../../../api/apiAxios';
import { useNavigate } from 'react-router-dom'; // Para navegação
import { itemsPages } from '../../itemsPage/itemsPage';
import GetTenantId from '../../../hooks/getTenantId';

const EditFormCompornent = ({ form }) => {
    const { enqueueSnackbar } = useSnackbar(); // Hook para exibir notificações
    const tenantId = GetTenantId();
    const navigate = useNavigate(); // Para redirecionar após a atualização

    const [localForm, setLocalForm] = useState(form);
    const [field, setField] = useState({
        name: '',
        type: 'text',
        required: false,
        placeholder: ''
    });

    useEffect(() => {
        setLocalForm(form);
    }, [form]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalForm({
            ...localForm,
            [name]: value
        });
    };

    const handleFieldChange = (index, e) => {
        const { name, value, checked } = e.target;
        const newFields = [...localForm.fields];
        newFields[index] = {
            ...newFields[index],
            [name]: name === 'required' ? checked : value
        };
        setLocalForm({ ...localForm, fields: newFields });
    };

    const addField = () => {
        if (field.name.trim()) {
            setLocalForm(prevForm => ({
                ...prevForm,
                fields: [...prevForm.fields, field]
            }));
            setField({ name: '', type: 'text', required: false, placeholder: '' });
        } else {
            enqueueSnackbar('Nome do campo é obrigatório.', { variant: 'warning' });
        }
    };

    const removeField = (index) => {
        const newFields = localForm.fields.filter((_, i) => i !== index);
        setLocalForm({ ...localForm, fields: newFields });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await apiAxios.put(`/api/forms/${localForm._id}`, localForm); // Alterar para PUT
            enqueueSnackbar('Formulário atualizado com sucesso!', { variant: 'success' });
            navigate('/fichas'); // Navegar de volta para a página de fichas após a atualização
        } catch (error) {
            console.error('Erro ao atualizar formulário:', error);
            enqueueSnackbar('Erro ao atualizar o formulário.', { variant: 'error' });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.form}>
                <h2>Informações básicas</h2>
                <div className={styles.group}>
                    <label>Título:</label>
                    <input
                        type="text"
                        name="title"
                        value={localForm.title}
                        onChange={handleInputChange}
                        placeholder="Título do formulário"
                        required
                    />
                </div>
                <div className={styles.group}>
                    <label>Descrição:</label>
                    <input
                        type="text"
                        name="description"
                        value={localForm.description}
                        onChange={handleInputChange}
                        placeholder="Descrição do formulário"
                    />
                </div>
                <div className={styles.group}>
                    <label>Campanha Pública:</label>
                    <input
                        type="checkbox"
                        name="PublicCampaign"
                        checked={localForm.PublicCampaign}
                        onChange={e => setLocalForm({ ...localForm, PublicCampaign: e.target.checked })}
                        style={{ width: "5%" }}
                    />
                </div>
                {localForm.PublicCampaign && (
                    <div className={styles.group}>
                        <label>Limite de Campanha:</label>
                        <input
                            type="number"
                            name="CampaignLimit"
                            value={localForm.CampaignLimit}
                            onChange={(e) => {
                                const value = Math.max(0, Math.min(500, Number(e.target.value)));
                                setLocalForm(prevForm => ({
                                    ...prevForm,
                                    CampaignLimit: value
                                }));
                            }}
                            placeholder="Limite de campanha"
                            min="0"
                            max="500"
                        />
                    </div>
                )}
                <h2>Adicionar Campos</h2>
                <div className={styles.group}>
                    <label>Nome do Campo:</label>
                    <input
                        type="text"
                        name="name"
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        placeholder="Ex: Qual o seu nome ? | Qual o seu CPF ?"
                    />
                </div>
                <div className={styles.group}>
                    <label>Legenda do campo:</label>
                    <input
                        type="text"
                        name="placeholder"
                        value={field.placeholder}
                        onChange={e => setField({ ...field, placeholder: e.target.value })}
                        placeholder="Ex: nome | xxx.xxx.xxx.xx"
                    />
                </div>
                <div className={styles.group}>
                    <label>O campo é obrigatório?</label>
                    <input
                        type="checkbox"
                        name="required"
                        checked={field.required}
                        onChange={e => setField({ ...field, required: e.target.checked })}
                        style={{ width: "5%" }}
                    />
                </div>
                <div className={styles.groupCampo}>
                    <label>Pré-visualização:</label>
                    {localForm.fields.map((f, index) => (
                        <div key={index} className={styles.fieldItem}>
                            <p>{f.name}</p>
                            <input placeholder={f.placeholder} disabled></input>
                            <button
                                type="button"
                                onClick={() => removeField(index)}
                                className={styles.removeButton}
                            >
                                Remover
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.actions}>
                <button style={{ background: "#c72a2f", color: "white" }} type="submit">Atualizar Formulário</button>
                <button type="button" onClick={addField}>Adicionar Campo</button>
            </div>
        </form>
    );
}

export default EditFormCompornent;
