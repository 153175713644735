import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './reducers/UserReducer';
import Cookies from 'js-cookie';
import { thunk } from 'redux-thunk';

const loadUserInfo = () => {
  try {
    const userInfoFromCookies = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
    const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    return userInfoFromCookies || userInfoFromStorage || null;
  } catch (error) {
    console.error("Erro ao carregar userInfo:", error);
    return null;
  }
};

// 🔥 Estado inicial carregado corretamente
const preloadedState = {
  user: {
    userInfo: loadUserInfo(),
  },
};

const store = configureStore({
  reducer: {
    user: UserReducer,
  },
  preloadedState, // ✅ Estado inicial já vem carregado
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// 🔥 Atualiza a sessão **somente se houver mudança**
const refreshUserSession = () => {
  const currentUserInfo = store.getState().user.userInfo;
  const storedUserInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;

  if (currentUserInfo && JSON.stringify(currentUserInfo) !== JSON.stringify(storedUserInfo)) {
    console.log("✅ Atualizando sessão do usuário...");
    
    // 🔥 AUMENTANDO O TEMPO DE LOGIN
    Cookies.set('userInfo', JSON.stringify(currentUserInfo), { 
      path: '/', 
      expires: 30 // 🔥 Agora o usuário fica logado por 30 dias
    });

    localStorage.setItem('userInfo', JSON.stringify(currentUserInfo));
  }
};

const logoutIfExpired = () => {
  const storedUserInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  const storedUserInfoLS = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  if (!storedUserInfo && !storedUserInfoLS) {
    console.log("⏳ Tempo expirado, deslogando usuário...");
    store.dispatch({ type: 'USER_SIGNOUT' }); // Dispara o logout
    localStorage.removeItem('userInfo');
    Cookies.remove('userInfo');
  }
};

// 🔥 Verifica a cada 1 hora se o usuário ainda tem sessão ativa
setInterval(logoutIfExpired, 60 * 60 * 1000);

// 🔥 Atualiza a sessão somente quando o Redux mudar
store.subscribe(refreshUserSession);

export default store;
