import { useState, useEffect, useCallback } from "react";
import apiAxios from "../../api/apiAxios";

const baseUrl = "/api/agenda";

const useAgendas = (tenantId) => {
    const [agendas, setAgendas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Função para buscar todas as agendas de um tenant específico
    const fetchAgendas = useCallback(async () => {
        if (!tenantId) return;
        setLoading(true);
        try {
            const response = await apiAxios.get(`${baseUrl}/${tenantId}`);
            setAgendas(response.data);
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao buscar agendas");
        } finally {
            setLoading(false);
        }
    }, [tenantId]);

    // Buscar agendas automaticamente quando o tenantId mudar
    useEffect(() => {
        fetchAgendas();
    }, [fetchAgendas]);

    // Função para buscar agenda por ID
    const fetchAgendaById = async (agendaId) => {
        try {
            const response = await apiAxios.get(`${baseUrl}/get/${agendaId}`);
            return response.data;
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao buscar agenda");
        }
    };

    // Função para buscar agendas por data
    const fetchAgendasByDate = async (date) => {
        try {
            const response = await apiAxios.get(`${baseUrl}/by-date/${date}`, {
                params: { tenantId },
            });
            return response.data;
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao buscar agendas por data");
        }
    };

    // Criar uma nova agenda
    const createAgenda = async (agendaData) => {
        try {
            const response = await apiAxios.post(baseUrl, agendaData);
            console.log("Agenda criada com sucesso:", response.data);
            setAgendas((prev) => [...prev, response.data]);
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao criar agenda");
        }
    };

    // Atualizar uma agenda existente
    const updateAgenda = async (agendaId, updatedData) => {
        try {
            const response = await apiAxios.patch(`${baseUrl}/${agendaId}`, updatedData);
            setAgendas((prev) => prev.map((agenda) => (agenda._id === agendaId ? response.data : agenda)));
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao atualizar agenda");
        }
    };

    // Excluir uma agenda
    const deleteAgenda = async (agendaId) => {
        try {
            await apiAxios.delete(`${baseUrl}/${agendaId}`);
            setAgendas((prev) => prev.filter((agenda) => agenda._id !== agendaId));
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao deletar agenda");
        }
    };

    const toggleAgendaStatus = async (agendaId, currentStatus) => {
        try {
            const response = await apiAxios.patch(`${baseUrl}/agenda/${agendaId}/status`, {
                agendastatus: !currentStatus,
            });
            setAgendas((prev) =>
                prev.map((agenda) =>
                    agenda._id === agendaId ? response.data : agenda
                )
            );
        } catch (err) {
            throw new Error(err.response?.data?.message || "Erro ao alternar status");
        }
    };

    return {
        agendas,
        loading,
        error,
        fetchAgendas,
        fetchAgendaById,
        fetchAgendasByDate,
        createAgenda,
        updateAgenda,
        deleteAgenda,
        toggleAgendaStatus,
    };
};

export default useAgendas;