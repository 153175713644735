import React from "react";
import styles from "./AgendaComponentnew.module.css";
import CalendarioStructure2 from "../../shared/calendar/CalendarioStructure2";
import InterfaceStructure from "../../shared/InterfaceStructure";
import { itemsPagesWithExclusions } from "../../itemsPage/itemsPage";
import useAgendaState from "./useAgendaState";
import AgendaPopups from "./AgendaPopups";
import { series, turno } from "../../../DataSeries";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import Chart from '../../DashboardComponents/chart/Chart';

const AgendaComponentNew = ({ PagesList, allowSeries = series, allowTurnos = turno, accessLevel }) => {
    const {
        agendas,
        loading,
        selectedDate,
        handleDateSelect,
        handleAgendaClick,
        modalOpen,
        selectedAgenda,
        handleCloseModal,
        filteredAgendas,
        filterSerie,
        filterTurno,
        getSeriesOptions,
        getTurnosOptions,
        handleSerieChange,
        handleTurnoChange,
        openCreateAgendaModal,
        openEditAgendaModal,  // ✅ Adicionar corretamente
        createAgenda,
        updateAgenda,
        deleteAgenda,
        openDeleteAgendaModal, // ✅ Adicionar corretamente
        isEditing, // ✅ Adicionar corretamente
        isDeleting, // ✅ Adicionar corretamente
        isCreating,
        toggleAgendaStatus
    } = useAgendaState(allowSeries, allowTurnos, accessLevel);

    const navigate = useNavigate();

    const itemsPages = itemsPagesWithExclusions("Agenda", []);

    const handleCriarAgenda = () => {
        if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
            alert("Data selecionada inválida.");
            console.log("❌ selectedDate inválido:", selectedDate);
            return;
        }
    
        const dataSelecionadaFormatada = selectedDate.toLocaleDateString("pt-BR"); // "13/03/2025"
    
        const jaExisteAgenda = filteredAgendas.some((agenda, index) => {
            let dataAgenda = null;
    
            if (typeof agenda.date === "string" && agenda.date.includes("/")) {
                const [dia, mes, anoComHora] = agenda.date.split("/");
                const ano = anoComHora.split(" ")[0];
                dataAgenda = `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
            } else if (agenda.date instanceof Date) {
                dataAgenda = agenda.date.toLocaleDateString("pt-BR");
            }            
    
            const igual = dataAgenda === dataSelecionadaFormatada;
            if (igual) console.log(`✅ Encontrada agenda existente para ${dataSelecionadaFormatada}`);
            return igual;
        });
    
        if (jaExisteAgenda) {
            alert("Já existe uma agenda para este dia, série e turno.");
            console.log("🚫 Criação de agenda bloqueada.");
            return;
        }
    
        openCreateAgendaModal();
    };
    

    const leftButtons = [
        ...(accessLevel !== "low" ? [
            { text: "Gerenciar matérias", onClick: () => navigate("/agenda/materias"), backgroundColor: "white", color: "var(--primary-color)" }
        ] : []),
        { text: "Criar Agenda", onClick: handleCriarAgenda, backgroundColor: "var(--primary-color)", color: "white" },
    ];

    const leftSelects = [
        { options: getSeriesOptions(), value: filterSerie, onChange: handleSerieChange },
        { options: getTurnosOptions(), value: filterTurno, onChange: handleTurnoChange }
    ];

    const materiasContadas = {};
    const nomeCompletoMap = {};

    filteredAgendas.forEach((agenda) => {
        if (Array.isArray(agenda.materia)) {
            agenda.materia.forEach((mat) => {
                if (!mat.materia) return;
                materiasContadas[mat.materia] = (materiasContadas[mat.materia] || 0) + 1;
            });
        }
    });

    // Gera os dados do gráfico com chave abreviada, mas mapeando para o nome completo
    const chartData = Object.entries(materiasContadas).map(([fullName, qtd]) => {
        const shortName = fullName.slice(0, 3).toUpperCase();
        nomeCompletoMap[shortName] = fullName; // Mapeia abreviação pro nome completo
        return {
            name: shortName,
            materias: qtd
        };
    });

    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <div className={styles.container}>
                    {loading ? <Loading /> : (
                        <>
                            <CalendarioStructure2
                                onDateSelect={handleDateSelect}
                                leftButtons={leftButtons}
                                leftSelects={leftSelects}
                                agendas={filteredAgendas}
                                onAgendaClick={handleAgendaClick}
                            />
                            <div className={styles.categories}>
                                <h2>Categorias</h2>
                                <div className={styles.category}>
                                    <span style={{ borderLeft: '3px solid #c72a2f' }}>Não Liberado para Alunos</span>
                                </div>
                                <div className={styles.category}>
                                    <span style={{ borderLeft: '3px solid rgb(27, 168, 14)' }}>Liberado para Alunos</span>
                                </div>
                            </div>
                            <div className={styles.graphic}>
                                <Chart
                                    title={`Frequência de matérias (${filterSerie} - ${filterTurno})`}
                                    data={chartData}
                                    dataKey="materias"
                                    grid
                                />

                            </div>

                        </>
                    )}
                    <AgendaPopups
                        isOpen={modalOpen}
                        onClose={handleCloseModal}
                        agendaData={selectedAgenda}
                        isEditing={isEditing}
                        isCreating={isCreating}
                        isDeleting={isDeleting}
                        onSaveCreate={createAgenda}               // função que salva nova agenda
                        onSaveEdit={updateAgenda}                 // (caso tenha edição)
                        onEditClick={openEditAgendaModal}
                        onDelete={deleteAgenda}
                        onDeleteClick={openDeleteAgendaModal}   // 🆕 abrir popup
                        onConfirmDelete={deleteAgenda}
                        toggleAgendaStatus={toggleAgendaStatus}
                        accessLevel={accessLevel}
                    />

                </div>
            }
        />
    );
};

export default AgendaComponentNew;
