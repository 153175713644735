import React from 'react'
import BancoDeHorasComponent from '../../../components/pagesComponents/BancoDeHorasComponents/BancoDeHorasComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const BancoDeHorasPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Banco de horas', []);
    return (
        <>
            <BancoDeHorasComponent itemsPages={itemsPagesList} accessLevel="high" />
        </>
    )
}

export default BancoDeHorasPage