import { useState, useEffect } from 'react';
import apiAxios from '../../api/apiAxios';

const useRegistroAgenda = (tenantId, serie = "", turno = "") => {
    const baseUrl = '/api/agenda/registro';
    const [registros, setRegistros] = useState([]);
    const [ultimosRegistros, setUltimosRegistros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const buildQueryString = () => {
        const params = new URLSearchParams();
        if (serie) params.append("serie", serie);
        if (turno) params.append("turno", turno);
        return params.toString();
    };
    

    const fetchRegistros = async () => {
        setLoading(true);
        try {
            const query = buildQueryString();
            const url = `${baseUrl}/${tenantId}${query ? `?${query}` : ""}`;
            const response = await apiAxios.get(url);
            setRegistros(response.data);
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao listar registros de agenda");
        } finally {
            setLoading(false);
        }
    };

    const fetchUltimosQuatroRegistros = async () => {
        setLoading(true);
        try {
            const query = buildQueryString();
            const url = `${baseUrl}/${tenantId}${query ? `?${query}` : ""}`;

            const response = await apiAxios.get(url);

            const lastFourRegistros = response.data.slice(0, 4);
            setUltimosRegistros(lastFourRegistros);
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao listar os últimos quatro registros de agenda");
        } finally {
            setLoading(false);
        }
    };

    const createRegistro = async (registroData) => {
        setLoading(true);
        try {
            const response = await apiAxios.post(`${baseUrl}`, registroData);
            setRegistros((prev) => [...prev, response.data]);
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao criar registro de agenda");
        } finally {
            setLoading(false);
        }
    };

    const updateRegistro = async (registroId, updatedData) => {
        setLoading(true);
        try {
            const response = await apiAxios.patch(`${baseUrl}/${registroId}`, updatedData);
            setRegistros((prev) =>
                prev.map((registro) => (registro._id === registroId ? response.data : registro))
            );
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao atualizar registro de agenda");
        } finally {
            setLoading(false);
        }
    };

    const deleteRegistro = async (registroId) => {
        setLoading(true);
        try {
            await apiAxios.delete(`${baseUrl}/${registroId}`);
            setRegistros((prev) => prev.filter((registro) => registro._id !== registroId));
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao deletar registro de agenda");
        } finally {
            setLoading(false);
        }
    };

    const getRegistroDetalhado = async (registroId) => {
        setLoading(true);
        try {
            const response = await apiAxios.get(`${baseUrl}/get/${registroId}`);
            return response.data; // contém apenas as mudanças
        } catch (err) {
            setError(err.response?.data?.message || "Erro ao obter registro detalhado");
            return null;
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (!tenantId || !serie || !turno) return;
        if (tenantId) {
            fetchRegistros();
        }
    }, [tenantId, serie, turno]);

    useEffect(() => {
        if (!tenantId || !serie || !turno) return;
        if (tenantId) {
            fetchUltimosQuatroRegistros();
        }
    }, [tenantId, serie, turno]);

    return {
        registros,
        ultimosRegistros,
        loading,
        error,
        fetchRegistros,
        fetchUltimosQuatroRegistros,
        createRegistro,
        updateRegistro,
        deleteRegistro,
        getRegistroDetalhado,
    };
};

export default useRegistroAgenda;
