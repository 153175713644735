import React, { useEffect, useState } from 'react';
import { turno } from '../../../DataSeries';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import PlanosComponent from '../../../components/pagesComponents/planosComponents/PlanosComponent';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';
import Loading from '../../../components/Loading/Loading'

const turnos = turno;

const PlanosProfessorPage = () => {
    const [user, setUser] = useState();
    const [allowedSeries, setAllowedSeries] = useState([]); // Estado para séries permitidas
    const [allowedTurnos, setAllowedTurnos] = useState([]); // Estado para turnos permitidos
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                const userData = response.data.user;
                setUser(userData);
    
                if (userData.isProfessor && userData.professorInfo?.length > 0) {
                    const series = userData.professorInfo.map(info => info.serie);
                    setAllowedSeries(series);
    
                    const turnos = userData.professorInfo.map(info => info.turno); // Adicione lógica para turnos
                    setAllowedTurnos(turnos);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                setIsLoading(false); 
            }
        };
    
        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);
    

    if (isLoading) {
        return <Loading/>;
    }

    const itemsPagesList = itemsPagesWithExclusions('Planos', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio','Banco de horas']);

    return (
        <>
            <PlanosComponent itemsPages={itemsPagesList} accessLevel="professor" seriesAccess={allowedSeries} turnosAccess={allowedTurnos} />
        </>
    );
};

export default PlanosProfessorPage;
