const extractTextFromBlocks = (descricao) => {
    try {
        // Se a descrição já for uma string simples, retorna diretamente
        if (typeof descricao === "string" && !descricao.startsWith("{")) {
            return descricao;
        }

        // Tenta fazer o parse se for JSON
        const parsed = JSON.parse(descricao);
        if (parsed.blocks) {
            return parsed.blocks.map(block => block.text).join("\n");
        }
    } catch (error) {
        console.error("Erro ao processar descrição:", error);
    }
    
    return "Descrição indisponível";
};

export default extractTextFromBlocks;
