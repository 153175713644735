import { useEffect, useState } from "react";
import apiAxios from "../api/apiAxios";
import { useSelector } from "react-redux";

const UseUsers = (tenantId) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastFetchedTenant, setLastFetchedTenant] = useState(null); // 🔥 Evita chamadas repetidas

    // 🔥 Função para buscar usuários pelo TenantId
    const fetchUsers = async () => {
        if (!tenantId || tenantId === lastFetchedTenant) return; // ✅ Evita chamadas repetidas
        setLoading(true);

        try {
            const response = await apiAxios.get(`/api/user/users/${tenantId}`);
            setUsers(response.data.users || []);
            setError(null);
            setLastFetchedTenant(tenantId); // ✅ Atualiza o último tenant carregado
        } catch (err) {
            console.error("❌ Erro ao buscar usuários:", err);
            setError(err.response?.data?.message || "Erro ao carregar usuários.");
        } finally {
            setLoading(false);
        }
    };

    // 🔥 useEffect para buscar automaticamente os usuários do tenant ao montar
    useEffect(() => {
        fetchUsers();
    }, [tenantId]); // ✅ Apenas quando `tenantId` mudar

    return { users, loading, error, fetchUsers };
};

export default UseUsers;
