import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardContent, Typography, TextField, MenuItem, Button } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { IoIosAddCircleOutline, IoIosClose } from "react-icons/io";
import "chart.js/auto";
import apiAxios from "../../../api/apiAxios";
import InterfaceStructure from "../../shared/InterfaceStructure";
import BasicPageStructure from "../../shared/BasicPageStructure";
import useTenantId from "../../../hooks/getTenantId";
import styles from "./BancoDeHorasComponent.module.css";
import BancoDeHorasCreateComponent from "./BancoDeHorasCreateComponent";
import PopUpComponent from "../../shared/popUp/PopUpComponent";
import BancoDeHorasEditComponent from "./BancoDeHorasEditComponent";
import GetTenantId from "../../../hooks/getTenantId";

const BancoDeHorasComponent = ({ itemsPages, accessLevel }) => {
    const [registros, setRegistros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filtroStatus, setFiltroStatus] = useState("Todos");
    const [pesquisa, setPesquisa] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [registroParaEditar, setRegistroParaEditar] = useState(null);

    const tenantId = GetTenantId();
    const navigate = useNavigate();

    useEffect(() => {
        if (!tenantId) return;
        async function fetchRegistros() {
            try {
                const response = await apiAxios.get(`/api/banco_de_horas/${tenantId}`);
                setRegistros(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error("Erro ao buscar registros:", error);
                setRegistros([]);
            } finally {
                setLoading(false);
            }
        }
        fetchRegistros();
    }, [tenantId]);

    const handleDelete = async () => {
        if (selectedRows.length === 0) return;
        try {
            await Promise.all(selectedRows.map(id => apiAxios.delete(`/api/banco_de_horas/${id}`)));
            setRegistros(registros.filter(r => !selectedRows.includes(r._id)));
            setSelectedRows([]);
        } catch (error) {
            console.error("Erro ao deletar registros:", error);
        }
    };

    const handleEdit = () => {
        if (selectedRows.length !== 1) return;
        const registro = registros.find(r => r._id === selectedRows[0]);
        setRegistroParaEditar(registro);
        setIsEditPopupOpen(true);
    };

    // Configuração do gráfico 📊
    const chartData = {
        labels: registros.map(r => r.colaboradorNome || "Sem Nome"), // Garante que sempre haverá um nome
        datasets: [
            {
                label: "Horas Acumuladas",
                data: registros.map(r => parseInt(r.saldoHoras) || 0),
                backgroundColor: "#4B98FF",
                borderRadius: 8,
            }
        ]
    };


    const columns = [
        {
            field: "colaboradorNome",
            headerName: "Colaborador",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <span className={styles.link} onClick={() => navigate(`/banco_de_horas/${params.row.id}`)}>
                    {params.value}
                </span>
            )
        },
        { field: "cargo", headerName: "Cargo", flex: 1, minWidth: 120 },
        { field: "saldoHoras", headerName: "Saldo de Horas", flex: 1, minWidth: 120 },
        { field: "status", headerName: "Status", flex: 1, minWidth: 120 },
        {
            field: "atualizadoEm",
            headerName: "Última Atualização",
            flex: 1,
            minWidth: 180,
            valueFormatter: (params) => params?.value ? new Date(params.value).toLocaleString() : "—"
        }
    ];

    const horasPorColaborador = registros.reduce((acc, r) => {
        if (r.colaboradorNome && r.saldoHoras) {
            acc[r.colaboradorNome] = (acc[r.colaboradorNome] || 0) + parseFloat(r.saldoHoras) || 0;
        }
        return acc;
    }, {});

    // Extraindo rótulos e dados para o gráfico de pizza
    const horasKeys = Object.keys(horasPorColaborador);
    const horasValues = Object.values(horasPorColaborador);

    const horasChartData = {
        labels: horasKeys.length > 0 ? horasKeys : ["Sem dados"],
        datasets: [
            {
                data: horasValues.length > 0 ? horasValues : [1],
                backgroundColor: ["#4CAF50", "#FFC107", "#F44336", "#9E9E9E", "#2196F3", "#FF5722"],
                hoverOffset: 4
            }
        ]
    };


    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    content={
                        <div className={styles.container}>
                            <div className={styles.filters}>
                                <TextField
                                    label="Filtrar por status"
                                    select
                                    value={filtroStatus}
                                    onChange={(e) => setFiltroStatus(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    className={styles.filter}
                                >
                                    <MenuItem value="Todos">Todos</MenuItem>
                                    <MenuItem value="Aprovado">Aprovado</MenuItem>
                                    <MenuItem value="Pendente">Pendente</MenuItem>
                                    <MenuItem value="Rejeitado">Rejeitado</MenuItem>
                                </TextField>

                                <TextField
                                    label="Pesquisar colaborador"
                                    variant="outlined"
                                    size="small"
                                    value={pesquisa}
                                    onChange={(e) => setPesquisa(e.target.value)}
                                    className={styles.filter}
                                />
                            </div>

                            {/* Contêiner que agrupa os gráficos */}
                            <div className={styles.chartsContainer}>
                                {/* Primeiro Gráfico - Barras */}
                                <div className={styles.chartWrapper}>
                                    <Bar
                                        data={chartData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { position: 'top' }
                                            },
                                            scales: {
                                                y: { beginAtZero: true }
                                            }
                                        }}
                                    />
                                </div>

                                <div className={styles.chartWrapper}>
                                    {horasKeys.length > 1 ? (
                                        <Pie
                                            data={horasChartData}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: { position: 'right' }
                                                }
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="h6" color="textSecondary">Sem dados suficientes</Typography>
                                    )}
                                </div>


                            </div>

                            {selectedRows.length > 0 && (
                                <div className={styles.actionButtons}>
                                    <div className={styles.actionsLeft}>
                                        <span>Você selecionou {selectedRows.length} |</span>

                                        {/* Editar - Somente se UM registro estiver selecionado */}
                                        {selectedRows.length === 1 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleEdit}
                                            >
                                                Editar
                                            </Button>
                                        )}

                                        {/* Excluir - Pode excluir múltiplos */}
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleDelete}
                                        >
                                            Excluir
                                        </Button>

                                        {/* Ver - Apenas se um registro for selecionado */}
                                        {selectedRows.length === 1 && (
                                            <Button
                                                variant="contained"
                                                color="info"
                                                onClick={() => navigate(`/banco_de_horas/${selectedRows[0]}`)}
                                            >
                                                Ver
                                            </Button>
                                        )}
                                    </div>
                                    <button onClick={() => setSelectedRows([])} className={styles.closeButton}>
                                        <IoIosClose size={24} />
                                    </button>
                                </div>
                            )}

                            <div className={styles.tableContainer}>
                                <DataGrid
                                    rows={registros.map((row, index) => ({ id: row._id || index, ...row }))}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    checkboxSelection
                                    onRowSelectionModelChange={(newSelection) => {
                                        setSelectedRows(newSelection);
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>

                            <PopUpComponent
                                title="Adicionar Colaborador"
                                isOpen={isPopupOpen}
                                onClose={() => setIsPopupOpen(false)}
                            >
                                <BancoDeHorasCreateComponent
                                    onClose={() => setIsPopupOpen(false)}
                                    onRefresh={() => {/* Atualizar lista */ }}
                                />
                            </PopUpComponent>

                            <PopUpComponent
                                title="Editar Colaborador"
                                isOpen={isEditPopupOpen}
                                onClose={() => setIsEditPopupOpen(false)}
                            >
                                {registroParaEditar && (
                                    <BancoDeHorasEditComponent
                                        onClose={() => setIsEditPopupOpen(false)}
                                        onRefresh={() => {/* Atualizar lista */ }}
                                        registroParaEditar={registroParaEditar}
                                    />
                                )}
                            </PopUpComponent>
                        </div>
                    }
                    returnPage={false}
                    buttons={[
                        {
                            label: 'Adicionar Colaborador',
                            icon: IoIosAddCircleOutline,
                            onClick: () => setIsPopupOpen(true),
                        },
                    ]}
                    returnLabel="Painel"
                    namePage="Banco de Horas"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BancoDeHorasComponent;
