import React, { useEffect, useState } from "react";
import styles from "./BoletimInfantilComponent.module.css";
import apiAxios from "../../../../api/apiAxios";
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line } from "recharts";

const BoletimInfantilComponent = ({ boletimAluno, tenantId, boletimId, alunoId }) => {
    const [relatorios, setRelatorios] = useState(boletimAluno.relatorios || []);
    const [alunoBoletimId, setAlunoBoletimId] = useState(boletimAluno._id || null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [faltas, setFaltas] = useState([]);

    const [periodo, setPediodo] = useState([]);

    const fetchBoletim = async () => {
        if (!alunoBoletimId) {
            console.warn("⚠️ ID do boletim do aluno não definido. Cancelando requisição.");
            return;
        }

        try {
            const response = await apiAxios.get(`/api/boletims/boletins/aluno/${alunoBoletimId}`);
            if (response.data && response.data.data) {
                setRelatorios(response.data.data.relatorios || []);
                console.log("✅ Dados do boletim infantil atualizados!");
            }
        } catch (error) {
            console.error("❌ Erro ao buscar boletim infantil atualizado:", error);
        }
    };

    useEffect(() => {
        if (alunoBoletimId) {
            fetchBoletim();
            fetchFaltas();
        }
    }, [boletimId, alunoId]);

    const handleTipoChange = async (areaGeralIndex, especificaIndex, competenciaIndex, periodoIndex, newTipo) => {
        if (isUpdating) return;

        setIsUpdating(true);

        const updatedRelatorios = [...relatorios];
        updatedRelatorios[areaGeralIndex].areasEspecificas[especificaIndex].competencias[competenciaIndex].periodos[periodoIndex].tipo = newTipo;

        setRelatorios(updatedRelatorios);

        try {
            if (alunoBoletimId) {
                console.log("🔄 Enviando atualização:", JSON.stringify(updatedRelatorios, null, 2));

                await apiAxios.put(`/api/boletims/boletins/aluno/${alunoBoletimId}`, {
                    tenantId,
                    boletimId,
                    alunoId,
                    relatorios: updatedRelatorios,
                });

                console.log("✅ Relatório atualizado com sucesso!");

                setTimeout(async () => {
                    await fetchBoletim();
                    setIsUpdating(false);
                }, 1000);
            } else {
                console.error("❌ Erro: ID do boletim do aluno não encontrado.");
                setIsUpdating(false);
            }
        } catch (error) {
            console.error("❌ Erro ao atualizar o relatório:", error);
            setIsUpdating(false);
        }
    };

    const calculateRowSpanForGeneralArea = (generalArea) => {
        return generalArea.areasEspecificas.reduce(
            (acc, especifica) => acc + especifica.competencias.length,
            0
        );
    };

    const uniquePeriods = relatorios.length > 0
        ? [...new Set(relatorios.flatMap(area =>
            area.areasEspecificas.flatMap(especifica =>
                especifica.competencias.flatMap(comp =>
                    comp.periodos.map(p => p.periodo)
                )
            )
        ))]
        : [];

    const faltasData = faltas.map(f => ({
        name: f.periodo,
        Faltas: f.totalFaltas
    }));

    const identificarPeriodo = (arrayPeriodos) => {
        if (!Array.isArray(arrayPeriodos) || arrayPeriodos.length === 0) {
            return null; // Retorna `null` se o array estiver vazio ou inválido
        }

        // Verifica a quantidade de períodos e retorna o tipo correspondente
        switch (arrayPeriodos.length) {
            case 4:
                if (arrayPeriodos.some(p => p.bimestre)) return "bimestre";
                if (arrayPeriodos.some(p => p.trimestre)) return "trimestre";
                if (arrayPeriodos.some(p => p.quadrimestre)) return "quadrimestre";
                return "bimestre"; // Padrão para 4 períodos
            case 3:
                return "trimestre";
            case 2:
                return "semestre";
            case 1:
                return "anual";
            default:
                return null; // Caso não identifique nenhum padrão
        }
    };

        // 🔍 Buscar faltas do aluno
        const fetchFaltas = async () => {
            try {
                const response = await apiAxios.get(`/api/attendance/${tenantId}/periodo`, {
                    params: { 
                        periodType:identificarPeriodo(uniquePeriods),
                        serie:boletimAluno?.serie,
                        turno:boletimAluno?.turno,
                        ano: boletimAluno?.boletimId?.anoLetivo,
                        alunoId: alunoId
                    }
                });
    
                if (response.data) {
                    setFaltas(response.data);
                    console.log("✅ Faltas carregadas:", response.data);
                }
            } catch (error) {
                console.error("❌ Erro ao buscar faltas:", error);
            }
        };

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Relatório de Desenvolvimento</h3>
            <table className={styles.boletimTabela}>
                <thead>
                    <tr>
                        <th rowSpan={2}>Área Geral</th>
                        <th rowSpan={2}>Competência</th>
                        <th colSpan={uniquePeriods.length} className={styles.periodHeader}>Bimestres</th>
                    </tr>
                    <tr>
                        {uniquePeriods.map((period, idx) => (
                            <th key={`period-header-${idx}`}>{period}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {relatorios.map((generalArea, generalIdx) => (
                        generalArea.areasEspecificas.map((especifica, especificaIdx) =>
                            especifica.competencias.map((competencia, compIdx) => (
                                <tr key={`${generalIdx}-${especificaIdx}-${compIdx}`}>
                                    {/* Área Geral apenas na primeira competência */}
                                    {especificaIdx === 0 && compIdx === 0 && (
                                        <td rowSpan={calculateRowSpanForGeneralArea(generalArea)} className={styles.areaGeralCell}>
                                            {generalArea.areaGeral.nome || "Área Geral não definida"}
                                        </td>
                                    )}
                                    {/* Competência apenas na primeira linha do grupo de períodos */}
                                    <td>{competencia.competencia || "Competência não definida"}</td>
                                    {uniquePeriods.map((periodoNome, periodoIdx) => {
                                        const periodo = competencia.periodos.find(p => p.periodo === periodoNome) || { tipo: "EP" };
                                        return (
                                            <td key={`${generalIdx}-${especificaIdx}-${compIdx}-${periodoIdx}`}>
                                                <select
                                                    value={periodo.tipo}
                                                    onChange={(e) => handleTipoChange(generalIdx, especificaIdx, compIdx, periodoIdx, e.target.value)}
                                                >
                                                    <option value="">Selecione</option>
                                                    <option value="S">Sim</option>
                                                    <option value="N">Não</option>
                                                    <option value="EP">Em Progresso</option>
                                                </select>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        )
                    ))}
                </tbody>
            </table>

            {/* 🚀 Tabela de Faltas */}
            <h3 className={styles.title}>Faltas por Bimestre</h3>
            <table className={styles.boletimTabela}>
                <thead>
                    <tr>
                        <th>Período</th>
                        <th>Total de Faltas</th>
                    </tr>
                </thead>
                <tbody>
                    {faltas.map(f => (
                        <tr key={f.periodo}>
                            <td>{f.periodo}</td>
                            <td>{f.totalFaltas}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* 🔹 Gráfico de Linhas para visualização das faltas */}
            <div style={{ width: '100%', maxWidth: '1500px', margin: '20px auto' }}>
                <h3>Gráfico de Faltas</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={faltasData}>
                        <XAxis dataKey="name" stroke="#ed3237" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line type="monotone" dataKey="Faltas" stroke="#ed3237" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default BoletimInfantilComponent;
