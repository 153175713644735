import { useEffect, useState } from "react";
import apiAxios from "../api/apiAxios";

const UseTenants = () => {
    const [tenants, setTenants] = useState(() => {
        // 🔥 Tenta recuperar do localStorage para evitar chamadas desnecessárias
        const storedTenants = localStorage.getItem("tenants");
        return storedTenants ? JSON.parse(storedTenants) : [];
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // 🔥 Função para buscar os tenants apenas se necessário
    const fetchTenants = async () => {
        if (tenants.length > 0) return; // ✅ Evita requisição se já tiver dados

        setLoading(true);
        try {
            const response = await apiAxios.get(`/api/user/tenants`);
            setTenants(response.data.tenants);
            localStorage.setItem("tenants", JSON.stringify(response.data.tenants)); // 🏓 Salva no localStorage
            setError(null);
        } catch (err) {
            console.error("❌ Erro ao buscar tenants:", err);
            setError(err.response?.data?.message || "Erro ao carregar tenants.");
        }
        setLoading(false);
    };

    // 🔥 useEffect para buscar automaticamente apenas se necessário
    useEffect(() => {
        if (tenants.length === 0) {
            fetchTenants();
        }
    }, [tenants]); // ✅ Observa `tenants` e só recarrega se estiver vazio

    return { tenants, loading, error, fetchTenants };
};

export default UseTenants;
