import { signInWithPopup } from 'firebase/auth';
import apiAxios from '../api/apiAxios';
import Cookies from 'js-cookie';
import { auth, provider } from '../pages/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const userCache = new Map();

export const signin = (email, password, tenantId) => async (dispatch) => {
    dispatch({ type: 'USER_SIGNIN_REQUEST', payload: { email, password, tenantId } });
    try {
        const { data } = await apiAxios.post('/api/user/HighLogin', { email, password, tenantId });
        // Armazenar o token JWT como um cookie
        const userInfo = JSON.stringify(data);
        Cookies.set('userInfo', userInfo, { path: '/' });

        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: data });
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Credenciais inválidas. Por favor, insira um usuário ou senha válido.' } });
            } else if (status === 500) {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Houve um erro interno no servidor. Tente novamente mais tarde.' } });
            } else {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
            }
        } else {
            dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
        }
    }
};

export const signinWithGoogleHigh = () => async (dispatch) => {
    try {
        const result = await signInWithPopup(auth, provider);
        const idToken = await result.user.getIdToken();

        // Enviar ID Token para o backend validar
        const { data } = await apiAxios.post("/api/user/googleHigh", { idToken });

        // Armazenar token no cookie para sessão persistente
        Cookies.set('userInfo', JSON.stringify(data), { path: '/' });

        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: data });
    } catch (error) {
        handleLoginError(dispatch, error);
    }
};

const handleLoginError = (dispatch, error) => {
    if (error.response) {
        const status = error.response.status;
        const errorMessage = {
            401: '*Credenciais inválidas. Por favor, insira um usuário ou senha válido.',
            403: '*Acesso negado. Você não tem permissão para esta ação.',
            500: '*Houve um erro interno no servidor. Tente novamente mais tarde.'
        }[status] || '*Ocorreu um erro. Tente novamente mais tarde.';

        dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: errorMessage } });
    } else {
        dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
    }
};

// Ação Redux para buscar detalhes do usuário
export const getUserDetails = (userId) => async (dispatch, getState) => {
    const { user } = getState().user;

    if (user && user._id === userId) {
        console.log("Usuário já carregado, evitando requisição desnecessária.");
        return;
    }

    dispatch({ type: "USER_DETAILS_REQUEST", payload: userId });

    try {
        const { data } = await apiAxios.get(`/api/user/user/${userId}`);
        dispatch({ type: "USER_DETAILS_SUCCESS", payload: data.user });
    } catch (error) {
        dispatch({
            type: "USER_DETAILS_FAILURE",
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const useUserDetails = (userId) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user); // Pegando usuário do Redux
    const loading = useSelector(state => state.user.loading); // Verificando se está carregando

    useEffect(() => {
        if (userId && !user) {
            dispatch(getUserDetails(userId));
        }
    }, [userId, dispatch, user]);

    return { user, loading };
};


export const getTenants = async () => {
    // 🛑 Verifica se já tem dados no cache
    const cachedTenants = localStorage.getItem("tenants");
    if (cachedTenants) {
        console.log("📢 Usando tenants do cache");
        return JSON.parse(cachedTenants);
    }

    try {
        console.log("📢 Buscando tenants da API...");
        const { data } = await apiAxios.get(`/api/user/tenants`);

        // ✅ Salva no cache e define tempo de expiração
        localStorage.setItem("tenants", JSON.stringify(data.tenants));
        setTimeout(() => {
            localStorage.removeItem("tenants"); // Expira após 10 min
        }, 10 * 60 * 1000);
        
        return data.tenants;
    } catch (error) {
        console.error('❌ Error fetching tenants:', error);
        throw error;
    }
};


export const logout = () => {
    return {
        type: 'LOGOUT'
    };
};

export const clearUserInfo = () => {
    Cookies.remove('userInfo');
};


export const getAllUsers = async (tenentId) => {
    try {
        const res = await apiAxios.get(`api/user/users/${tenentId}`);
        return res.data.users;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const deleteUserById = async (userId) => {
    try {
        const res = await apiAxios.delete(`api/user/user/${userId}`);
        return res.data.users; // ou retornar res diretamente, se necessário
    } catch (err) {
        console.error("Erro ao excluir usuário:", err);
        throw err;
    }
};

export const approveRequest = async (userId) => {
    try {
        const res = await apiAxios.post(`api/user/users/${userId}`);
        return res.data.users; // ou retornar res diretamente, se necessário
    } catch (err) {
        console.error("Erro ao excluir usuário:", err);
        throw err;
    }
};

export const getUserById = async (userId) => {
    // Verifica se já temos o usuário no cache
    if (userCache.has(userId)) {
        console.log(`🔹 Retornando usuário do cache: ${userId}`);
        return userCache.get(userId);
    }

    try {
        console.log(`📡 Buscando usuário da API: ${userId}`);
        const response = await apiAxios.get(`/api/user/user/${userId}`);
        const userData = response.data.user;

        // Armazena no cache para futuras chamadas
        userCache.set(userId, userData);
        
        return userData;
    } catch (error) {
        console.error("Erro ao buscar usuário:", error);
        throw error;
    }
};