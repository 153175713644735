import React from 'react'
import BancoDeHorasDetailComponent from '../../../components/pagesComponents/BancoDeHorasComponents/BancoDeHorasDetailComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const BancoDeHorasDetailPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Banco de horas', []);
    return (
        <>
            <BancoDeHorasDetailComponent itemsPages={itemsPagesList} accessLevel="high" />
        </>
    )
}

export default BancoDeHorasDetailPage