import React from "react";
import styles from "./AgendaComponent.module.css";
import CalendarioStructure2 from "../../shared/calendar/CalendarioStructure2";
import "react-calendar/dist/Calendar.css";
import "../../shared/calendar/CalendarioStructure2.module.css";
import InterfaceStructure from "../../shared/InterfaceStructure";
import { itemsPagesWithExclusions } from "../../itemsPage/itemsPage";
import useAgendaState from "./useAgendaState";
import AgendaPopups from "./AgendaPopups";
import { series, turno } from "../../../DataSeries";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import Chart from '../../DashboardComponents/chart/Chart';
import { FaHistory } from "react-icons/fa";

const AgendaComponent = ({ PagesList, allowSeries = series, allowTurnos = turno, accessLevel }) => {
    const {
        agendas,
        loading,
        selectedDate,
        handleDateSelect,
        handleAgendaClick,
        modalOpen,
        selectedAgenda,
        handleCloseModal,
        filteredAgendas,
        filterSerie,
        filterTurno,
        getSeriesOptions,
        getTurnosOptions,
        handleSerieChange,
        handleTurnoChange,
        openCreateAgendaModal,
        openEditAgendaModal,  // ✅ Adicionar corretamente
        createAgenda,
        updateAgenda,
        deleteAgenda,
        openDeleteAgendaModal, // ✅ Adicionar corretamente
        isEditing, // ✅ Adicionar corretamente
        isDeleting, // ✅ Adicionar corretamente
        isCreating,
        toggleAgendaStatus,
        registros,
        ultimosRegistros,
        popupRegistrosAberto,
        setPopupRegistrosAberto,
        getRegistroDetalhado,
        registroSelecionado,
        popupDetalhesAberto,
        abrirDetalhesDoRegistro,
        setPopupDetalhesAberto,
        materiasAgenda,
        setMateriasAgenda,
        materiasFaltando,
        setMateriasFaltando,
        selectedImages,
        setSelectedImages,
        savedImages,
        setSavedImages,
        imagesToDelete,
        setImagesToDelete,
    } = useAgendaState(allowSeries, allowTurnos, accessLevel);

    const navigate = useNavigate();

    const itemsPages = itemsPagesWithExclusions("Agenda", []);

    const handleCriarAgenda = () => {
        if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
            alert("Data selecionada inválida.");
            console.log("❌ selectedDate inválido:", selectedDate);
            return;
        }

        const dataSelecionadaFormatada = selectedDate.toLocaleDateString("pt-BR"); // "13/03/2025"

        const jaExisteAgenda = filteredAgendas.some((agenda, index) => {
            let dataAgenda = null;

            if (typeof agenda.date === "string" && agenda.date.includes("/")) {
                const [dia, mes, anoComHora] = agenda.date.split("/");
                const ano = anoComHora.split(" ")[0];
                dataAgenda = `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
            } else if (agenda.date instanceof Date) {
                dataAgenda = agenda.date.toLocaleDateString("pt-BR");
            }

            const igual = dataAgenda === dataSelecionadaFormatada;
            if (igual) console.log(`✅ Encontrada agenda existente para ${dataSelecionadaFormatada}`);
            return igual;
        });

        if (jaExisteAgenda) {
            alert("Já existe uma agenda para este dia, série e turno.");
            console.log("🚫 Criação de agenda bloqueada.");
            return;
        }

        openCreateAgendaModal();
    };


    const leftButtons = [
        ...(accessLevel !== "low" ? [
            { text: "Gerenciar matérias", onClick: () => navigate("/agenda/materias"), backgroundColor: "white", color: "var(--primary-color)" }
        ] : []),
        { text: "Criar Agenda", onClick: handleCriarAgenda, backgroundColor: "var(--primary-color)", color: "white" },
    ];

    const leftSelects = [
        { options: getSeriesOptions(), value: filterSerie, onChange: handleSerieChange },
        { options: getTurnosOptions(), value: filterTurno, onChange: handleTurnoChange }
    ];

    const materiasContadas = {};
    const nomeCompletoMap = {};

    filteredAgendas.forEach((agenda) => {
        if (Array.isArray(agenda.materia)) {
            agenda.materia.forEach((mat) => {
                if (!mat.materia) return;
                materiasContadas[mat.materia] = (materiasContadas[mat.materia] || 0) + 1;
            });
        }
    });

    // Gera os dados do gráfico com chave abreviada, mas mapeando para o nome completo
    const chartData = Object.entries(materiasContadas).map(([fullName, qtd]) => {
        const shortName = fullName.slice(0, 3).toUpperCase();
        nomeCompletoMap[shortName] = fullName; // Mapeia abreviação pro nome completo
        return {
            name: shortName,
            materias: qtd
        };
    });

    return (
        <InterfaceStructure
            itemsPages={PagesList}
            dashboardContent={
                <div className={styles.container}>
                    {loading ? <Loading /> : (
                        <>
                            <CalendarioStructure2
                                onDateSelect={handleDateSelect}
                                leftButtons={leftButtons}
                                leftSelects={leftSelects}
                                agendas={filteredAgendas}
                                onAgendaClick={handleAgendaClick}
                            />
                            <div className={styles.categories}>
                                <h2>Categorias</h2>
                                <div className={styles.category}>
                                    <span style={{ borderLeft: '3px solid #c72a2f' }}>Não Liberado para Alunos</span>
                                </div>
                                <div className={styles.category}>
                                    <span style={{ borderLeft: '3px solid rgb(27, 168, 14)' }}>Liberado para Alunos</span>
                                </div>
                            </div>
                            <div className={styles.graphic}>
                                <Chart
                                    title={`Frequência de matérias (${filterSerie} - ${filterTurno})`}
                                    data={chartData}
                                    dataKey="materias"
                                    grid
                                />
                            </div>
                            <div className={styles.historico}>
                                <div className={styles.historicoContainer}>
                                    <h2 className={styles.historicoTitulo}>Últimos Registros</h2>
                                    <ul className={styles.listaRegistros}>

                                        {
                                            ultimosRegistros.length > 0 ? (
                                                <>
                                                    {
                                                        ultimosRegistros.map((registro, index) => (
                                                            <li key={index} className={styles.registroItem} onClick={() => abrirDetalhesDoRegistro(registro._id)}>
                                                                <strong className={styles.registroNome}>{registro.usuarioNome}</strong> realizou <strong>{registro.acao}</strong><br />
                                                                <span className={styles.registroData}>{registro.dataRegistro}</span>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <div className={styles.notFound}>
                                                        <span className={styles.icon}><FaHistory /></span>
                                                        <span className={styles.registroNome}>Nenhum registro encontrado</span>
                                                    </div>
                                                </>
                                            )
                                        }

                                    </ul>
                                    <button className={styles.botaoVerTodos} onClick={() => setPopupRegistrosAberto(true)}>
                                        Ver todos
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <AgendaPopups
                        agendas={agendas}
                        isOpen={modalOpen}
                        onClose={handleCloseModal}
                        agendaData={selectedAgenda}
                        isEditing={isEditing}
                        isCreating={isCreating}
                        isDeleting={isDeleting}
                        onSaveCreate={createAgenda}               // função que salva nova agenda
                        onSaveEdit={updateAgenda}                 // (caso tenha edição)
                        onEditClick={openEditAgendaModal}
                        onDelete={deleteAgenda}
                        onDeleteClick={openDeleteAgendaModal}   // 🆕 abrir popup
                        onConfirmDelete={deleteAgenda}
                        toggleAgendaStatus={toggleAgendaStatus}
                        accessLevel={accessLevel}
                        registros={registros}
                        popupRegistrosAberto={popupRegistrosAberto}
                        setPopupRegistrosAberto={setPopupRegistrosAberto}
                        registroSelecionado={registroSelecionado}
                        popupDetalhesAberto={popupDetalhesAberto}
                        setPopupDetalhesAberto={setPopupDetalhesAberto}
                        abrirDetalhesDoRegistro={abrirDetalhesDoRegistro}
                        materiasAgenda={materiasAgenda}
                        setMateriasAgenda={setMateriasAgenda}
                        materiasFaltando={materiasFaltando}
                        setMateriasFaltando={setMateriasFaltando}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        savedImages={savedImages}
                        setSavedImages={setSavedImages}
                        imagesToDelete={imagesToDelete}
                        setImagesToDelete={setImagesToDelete}

                    />
                </div>
            }
        />
    );
};

export default AgendaComponent;
