import React, { useState, useEffect } from 'react';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Pagination } from '@mui/material';
import styles from './InformeComponents.module.css';
import apiAxios from '../../../api/apiAxios';
import PopUpComponent from '../../shared/popUp/PopUpComponent';
import MiniPopUpComponent from '../../shared/popUp/MiniPopUpComponent';
import InformeComponentsCreate from './InformeComponentsCreate';
import InformeComponentsEdit from './InformeComponentsEdit';
import BasicPageStructure from '../../shared/BasicPageStructure';
import GetTenantId from '../../../hooks/getTenantId';

const InformeComponents = ({ itemsPages }) => {
    const tenantId = GetTenantId();
    const [informes, setInformes] = useState([]);
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedInformeId, setSelectedInformeId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [informeToDelete, setInformeToDelete] = useState(null);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchInformes();
    }, [selectedMonth, selectedYear]);

    const fetchInformes = async () => {
        try {
            const response = await apiAxios.get(`/api/inf/${tenantId}`);
            if (response && response.data) {
                const filteredInformes = response.data.filter((informe) => {
                    if (!informe.dateFormatted) {
                        console.error(`Informe sem data válida: ${JSON.stringify(informe)}`);
                        return false;
                    }
                
                    const [day, month, year] = informe.dateFormatted.split('/');
                    
                    // O JavaScript espera meses no formato 0-11, então precisamos subtrair 1
                    const informeDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
                
                    if (isNaN(informeDate.getTime())) {
                        console.error(`Data inválida encontrada: ${informe.dateFormatted}`);
                        return false;
                    }
                
                    // 🚀 Ajuste aqui: Agora a filtragem de mês e ano funcionará corretamente
                    return (
                        informeDate.getMonth() === selectedMonth && // O mês está correto?
                        informeDate.getFullYear() === selectedYear  // O ano está correto?
                    );
                });
                
                
                setInformes(filteredInformes);
            } else {
                setMessage('Erro ao buscar informes: Resposta inválida da API');
            }
        } catch (error) {
            setMessage(`Erro ao buscar informes: ${error.message}`);
        }
    };


    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value, 10));
        setPage(1);
    };

    const handleYearChange = (e) => {
        setSelectedYear(parseInt(e.target.value, 10));
        setPage(1);
    };

    const requestDelete = (id) => {
        setInformeToDelete(id);
        setShowDeletePopup(true);
    };

    const confirmDelete = async () => {
        try {
            await apiAxios.delete(`/api/inf/${tenantId}/${informeToDelete}`);
            setMessage('Informe excluído com sucesso!');
            setInformes(informes.filter((informe) => informe._id !== informeToDelete));
            setShowDeletePopup(false);
        } catch (error) {
            setMessage(`Erro ao excluir informe: ${error.message}`);
        }
    };

    const openCreatePopup = () => {
        setOpenCreateModal(true);
    };

    const closeCreatePopup = () => {
        setOpenCreateModal(false);
    };

    const openEditPopup = (informeId) => {
        setSelectedInformeId(informeId);
        setOpenEditModal(true);
    };

    const closeEditPopup = () => {
        setOpenEditModal(false);
        setSelectedInformeId(null);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const currentInformes = informes.slice(startIndex, startIndex + itemsPerPage);

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    };

    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, k) => new Date(0, k).toLocaleString('default', { month: 'long' }));

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.filters}>
                <div>
                    <label>Série:</label>
                    <select value={selectedYear} onChange={handleYearChange}>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Turno:</label>
                    <select value={selectedMonth} onChange={handleMonthChange}>
                        {months.map((month, index) => (
                            <option key={index} value={index}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={styles.informesList}>
                {currentInformes.map((informe) => (
                    <div key={informe._id} className={styles.informeItem}>
                        <div className={styles.topInformeItem}>
                            <button type="button" className={styles.updateButton} onClick={() => openEditPopup(informe._id)}>
                                <MdEdit />
                            </button>
                            <button type="button" className={styles.removeButton} onClick={() => requestDelete(informe._id)}>
                                <MdDeleteOutline />
                            </button>
                        </div>
                        <div className={styles.bottomInformeItem}>
                            <div className={styles.InfRight}>
                                <img src={informe.imagem} alt="img" />
                            </div>
                            <div className={styles.InfLeft}>
                                <div className={styles.topico}>{informe.topico}</div>
                                <div className={styles.title}>{informe.titulo}</div>
                                <div className={styles.desc}>{truncateText(informe.descricao, 50)}</div>
                                <div className={styles.date}>{informe.dateFormatted}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.pagination}>
                <Pagination
                    count={Math.ceil(informes.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </div>
            <PopUpComponent title="Adicionar Informe" isOpen={openCreateModal} onClose={closeCreatePopup}>
                <InformeComponentsCreate onClose={closeCreatePopup} />
            </PopUpComponent>
            <PopUpComponent title="Editar Informe" isOpen={openEditModal} onClose={closeEditPopup}>
                {selectedInformeId && <InformeComponentsEdit informeId={selectedInformeId} onClose={closeEditPopup} />}
            </PopUpComponent>
            {showDeletePopup && (
                <MiniPopUpComponent
                    title="Confirmar Exclusão"
                    message="Tem certeza de que deseja excluir este informe?"
                    onConfirm={confirmDelete}
                    onCancel={() => setShowDeletePopup(false)}
                />
            )}
        </div>
    );

    const buttons = [
        {
            label: 'Adicionar Informe',
            icon: IoIosAddCircleOutline,
            onClick: () => openCreatePopup(),
        },
    ];


    return <InterfaceStructure itemsPages={itemsPages} dashboardContent={
        <BasicPageStructure
            content={dashboardContent}
            buttons={buttons}
            returnPage={false}
            namePage="Informes"
            accessType={"Administrador"}
        />
    } />;
};

export default InformeComponents;
