import { useState, useEffect } from "react";
import apiAxios from "../../api/apiAxios";

const BaseURL = "/api/boletims";

const useBoletins = (tenantId) => {
    const [boletins, setBoletins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Carrega boletins automaticamente quando tenantId muda
    useEffect(() => {
        const fetchBoletins = async () => {
            if (!tenantId) return;
            setLoading(true);
            try {
                const res = await apiAxios.get(`${BaseURL}/boletins`, {
                    params: { tenantId }
                });
                setBoletins(res.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBoletins();
    }, [tenantId]);

    const getBoletimById = async (id) => {
        try {
            const res = await apiAxios.get(`${BaseURL}/boletins/${id}`);
            return res.data.data;
        } catch (err) {
            setError(err);
        }
    };

    const criarBoletim = async (data) => {
        try {
            const res = await apiAxios.post(`${BaseURL}/boletins`, data);
            return res.data.data;
        } catch (err) {
            setError(err);
        }
    };

    const atualizarBoletim = async (id, data) => {
        try {
            const res = await apiAxios.put(`${BaseURL}/boletins/${id}`, data);
            return res.data.data;
        } catch (err) {
            setError(err);
        }
    };

    const deletarBoletim = async (id) => {
        try {
            const res = await apiAxios.delete(`${BaseURL}/boletins/${id}`);
            return res.data.message;
        } catch (err) {
            setError(err);
        }
    };

    const sincronizarBoletimAluno = async ({ tenantId, boletimId, alunoId }) => {
        try {
            const res = await apiAxios.put(
                `${BaseURL}/boletins/aluno/sincronizar-boletim/sync`,
                { tenantId, boletimId, alunoId }
            );
            return res.data.data;
        } catch (err) {
            setError(err);
        }
    };

    const verificarSincronizacaoBoletimAluno = async ({ tenantId, boletimId, alunoId }) => {
        try {
            const res = await apiAxios.get(
                `${BaseURL}/boletins/aluno/verificar-sincronizacao/sync`,
                {
                    params: { tenantId, boletimId, alunoId }
                }
            );
            return res.data.precisaSincronizar; // true ou false
        } catch (err) {
            setError(err);
        }
    };

    return {
        boletins,
        loading,
        error,
        getBoletimById,
        criarBoletim,
        atualizarBoletim,
        deletarBoletim,
        sincronizarBoletimAluno,
        verificarSincronizacaoBoletimAluno,
    };
};

export default useBoletins;
