import React from 'react'
import BoletimComponent from '../../../components/pagesComponents/BoletimComponents/BoletimComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const BoletimCoordenacaoPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['agenda','Informes','Atividades','Chat','Cardápio','Banco de horas']);
    return (
        <BoletimComponent PagesList={itemsPagesList} />
    )
}

export default BoletimCoordenacaoPage