import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Button, CircularProgress, Paper } from "@mui/material";
import apiAxios from "../../../api/apiAxios";
import styles from "./BancoDeHorasCreateComponent.module.css";
import Message from "../tools/Message";
import { getAllUsers, getUserById } from "../../../actions/UserActions";
import useUserId from "../../useUserId";
import GetTenantId from "../../../hooks/getTenantId";

const BancoDeHorasCreateComponent = ({ onClose, onRefresh }) => {

    const [formData, setFormData] = useState({
        colaboradorNome: "",
        cargo: "",
        status: "Pendente",
    });

    const tenantId = GetTenantId();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: "", text: "" });
    const [professores, setProfessores] = useState([]); // Apenas os professores
    const [loadingUsers, setLoadingUsers] = useState(true); // Estado para exibir o loading ao buscar usuários

    const UserId = useUserId();
    const [User, setUser] = useState(null); // Alterado para null para facilitar a verificação
    
    useEffect(() => {
        if (!UserId) return; // Se UserId for null ou undefined, não executa a chamada
    
        const fetchUser = async () => {
            try {
                const userData = await getUserById(UserId);
                setUser(userData);
            } catch (error) {
                console.error("❌ Erro ao buscar usuário:", error);
            }
        };
    
        fetchUser();
    }, [UserId]);

    // 🚀 Buscar a lista de professores ao carregar o componente
    useEffect(() => {
        const fetchProfessores = async () => {
            setLoadingUsers(true);
            try {
                const response = await getAllUsers(tenantId);

                if (Array.isArray(response)) {
                    const professoresFiltrados = response.filter(user => user.isProfessor); // Filtra apenas professores
                    setProfessores(professoresFiltrados);
                    if (professoresFiltrados.length > 0) {
                        setProfessores(professoresFiltrados);
                    }                    

                } else {
                    setProfessores([]);
                }
            } catch (error) {
                console.error("❌ Erro ao buscar professores:", error);
                setMessage({ type: "error", text: "Erro ao buscar lista de professores." });
            } finally {
                setLoadingUsers(false);
            }
        };

        fetchProfessores();
    }, [tenantId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCancel = () => {
        if (typeof onClose === "function") {
            console.log("Fechando popup...");
            onClose();
        } else {
            console.warn("A função onClose não foi passada corretamente.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return;

        setLoading(true);
        setMessage({ type: "", text: "" });

        if (!formData.colaboradorNome || !formData.cargo) {
            setMessage({ type: "warning", text: "Preencha todos os campos obrigatórios." });
            setLoading(false);
            return;
        }

        try {
            await apiAxios.post("/api/banco_de_horas", {
                tenantId,
                colaboradorNome: formData.colaboradorNome,
                cargo: formData.cargo,
                status: formData.status,
                criadoPor: User.firstName + " " + User.lastName,
            });

            setMessage({ type: "success", text: "Colaborador cadastrado com sucesso!" });

            if (onRefresh) onRefresh();

            setTimeout(() => {
                if (typeof onClose === "function") {
                    onClose();
                }
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error("Erro ao criar colaborador:", error);
            setMessage({ type: "error", text: "Erro ao cadastrar colaborador." });
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
        <Paper style={{ border: "none", boxShadow: "none" }} className={styles.container} elevation={3}>
            {message.text && <Message type={message.type} message={message.text} />}

            <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                    label="Nome do Colaborador"
                    name="colaboradorNome"
                    value={formData.colaboradorNome}
                    onChange={handleChange} // Permite escrever manualmente
                    fullWidth
                    required
                    placeholder="Digite ou selecione um nome"
                    InputProps={{
                        endAdornment: (
                            loadingUsers ? (
                                <CircularProgress size={20} />
                            ) : null
                        ),
                    }}
                />

                <TextField
                    label="Selecione um Professor (Opcional)"
                    name="colaboradorNome"
                    select
                    fullWidth
                    value={formData.colaboradorNome} // Começa como ""
                    onChange={handleChange}
                    SelectProps={{
                        MenuProps: { disablePortal: true },
                    }}
                >
                    {/* Opção inicial sem seleção */}
                    <MenuItem value="">Nenhum selecionado</MenuItem>

                    {loadingUsers ? (
                        <MenuItem disabled>
                            <CircularProgress size={20} />
                            &nbsp; Carregando...
                        </MenuItem>
                    ) : professores.length > 0 ? (
                        professores.map((user) => (
                            <MenuItem key={user._id} value={`${user.firstName} ${user.lastName}`}>
                                {user.firstName} {user.lastName}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Nenhum professor encontrado</MenuItem>
                    )}
                </TextField>


                <TextField
                    label="Cargo"
                    name="cargo"
                    value={formData.cargo}
                    onChange={handleChange}
                    fullWidth
                    required
                />

                <TextField
                    label="Status"
                    name="status"
                    select
                    value={formData.status}
                    onChange={handleChange}
                    fullWidth
                    required
                    SelectProps={{
                        MenuProps: { disablePortal: true },
                    }}
                >
                    <MenuItem value="Aprovado">Aprovado</MenuItem>
                    <MenuItem value="Pendente">Pendente</MenuItem>
                    <MenuItem value="Rejeitado">Rejeitado</MenuItem>
                </TextField>

                <div className={styles.actions}>
                    <Button onClick={handleCancel} variant="outlined" className={styles.cancelBtn}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : "Cadastrar"}
                    </Button>
                </div>
            </form>
        </Paper>
    );
};

export default BancoDeHorasCreateComponent;
