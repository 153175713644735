import React from 'react';
import styles from './BasicPageStructure.module.css';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaFilter, FaSearch } from "react-icons/fa";
import DefaultButton from './buttons/DefaultButton';
import CustomSelect from './selects/CustomSelect';
import CustomInput from './inputs/CustomInput';
import { MdOutlineFilterList } from 'react-icons/md';

const BasicPageStructure = ({
    content,
    buttons = [],
    returnPage = false,
    returnPath = '/',
    returnLabel = 'Painel',
    namePage = 'Painel',
    accessType = "administrador",
    areaBottomType = {
        status: false,
        left: {
            select: false,
            selectItems: [
                { options: ['Opção 1', 'Opção 2'], placeholder: 'Filtro 1' },
                { options: ['Opção A', 'Opção B'], placeholder: 'Filtro 2' }
            ],
            filterButton: false,

        }
        ,
        right: {
            searchBar: false,
            placeholder: 'Buscar...',
            value: '',
            onChange: () => { }
        }
    }

}) => {

    console.log(areaBottomType)
    return (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={`${styles.topAreaInfo} ${buttons.length === 0 ? styles.noButtons : ''}`}>
                    <div className={styles.container}>
                        {/* 🔙 Renderiza o link de retorno apenas se returnPage for true */}
                        {returnPage && (
                            <div className={styles.returnPainelArrow}>
                                <Link to={returnPath} className={styles.returnLink}>
                                    <FaArrowLeft className={styles.returnIcon} />
                                </Link>
                            </div>
                        )}

                        <div className={styles.painel}>
                            <div className={styles.start}>
                                <h1 style={{ padding: "0px", margin: "0px" }}>{namePage}</h1>
                            </div>

                            {/* 🆕 Renderiza os botões utilizando o DefaultButton */}
                            <div className={styles.end}>
                                {buttons.map((button, index) => (
                                    <DefaultButton
                                        key={index}
                                        text={button.label}
                                        icon={button.icon}
                                        backgroundColor={index === buttons.length - 1 ? "var(--primary-color)" : "white"}
                                        color={index === buttons.length - 1 ? "white" : "var(--primary-color)"}
                                        size="medium"
                                        onClick={button.onClick}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    {areaBottomType?.status === true && (
                        <div className={styles.container2}>
                            <div className={styles.painel}>
                                {/* 🟦 Lado direito: barra de pesquisa */}
                                {areaBottomType.right?.searchBar && (
                                    <div className={styles.rightArea}>
                                        <div className={styles.inputWrapper}>
                                            <FaSearch className={styles.icon} />
                                            <input
                                                type="text"
                                                className={styles.input}
                                                placeholder={areaBottomType.right?.placeholder}
                                                value={areaBottomType.right?.value}
                                                onChange={areaBottomType.right?.onChange}
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* 🟩 Lado esquerdo: botão e filtros */}
                                <div className={styles.leftArea}>
                                    {areaBottomType.left?.select && (
                                        Array.isArray(areaBottomType.left?.selectItems) &&
                                        areaBottomType.left.selectItems.slice(0, 2).map((selectData, index) => (
                                            <CustomSelect
                                                key={index}
                                                options={selectData.options}
                                                placeholder={selectData.placeholder}
                                                value={selectData.value}
                                                onChange={selectData.onChange}
                                            />

                                        ))
                                    )}
                                    {areaBottomType.left?.filterButton && (
                                        <DefaultButton
                                            text="Filtrar"
                                            backgroundColor="var(--primary-color)"
                                            color="white"
                                            size="small"
                                            onClick={() => console.log("🔍 Filtro ativado")}
                                            icon={MdOutlineFilterList}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                {/* Conteúdo da página */}
                <div className={styles.bottomAreaInfo}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default BasicPageStructure;
