import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import MateriasComponent from '../../../components/pagesComponents/materiasComponents/MateriasComponent';

const MateriasPlanosCoordenacao = () => {
  const itemsPagesList = itemsPagesWithExclusions('', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio','Banco de horas']);
  return (
    <>
      <MateriasComponent itemsPages={itemsPagesList} returnLink={"/planos"} returnLinkName={"Planos"} />
    </>
  )
}

export default MateriasPlanosCoordenacao;