import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import FichasComponent from '../../../components/pagesComponents/fichasComponents/FichasComponent';
import GetTenantId from '../../../hooks/getTenantId';

const FichasCoordenacaoPage = () => {
    const tenantId = GetTenantId();  // Obtendo tenantId
    const itemsPagesList = itemsPagesWithExclusions('Fichas',['agenda','Informes','Atividades','Chat','Cardápio','Banco de horas']);

    return (
        <FichasComponent PagesList={itemsPagesList} tenantId={tenantId} acessType={"low"}/>
    );
};

export default FichasCoordenacaoPage;