import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import HorariosPageComponent from '../../../components/pagesComponents/HorariosComponents/HorariosPageComponent';
import GetTenantId from '../../../hooks/getTenantId';

const HorariosProfessorPage = () => {
    const tenantId = GetTenantId();
    const itemsPagesList = itemsPagesWithExclusions('Horários', ['agenda','Informes','Atividades','Chat','Fichas','Cardápio','Banco de horas']);

    return (
        <HorariosPageComponent tenantId={tenantId} pagesList={itemsPagesList}  accessLevel="lowAccess" />
    );
};

export default HorariosProfessorPage;
