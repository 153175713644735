import React from 'react';
import useTenantId from '../../../hooks/getTenantId';
import HorariosPageComponent from '../../../components/pagesComponents/HorariosComponents/HorariosPageComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import GetTenantId from '../../../hooks/getTenantId';

const HorariosPage = () => {
    const tenantId = GetTenantId();
    const itemsPagesList = itemsPagesWithExclusions('Horários', []);

    return (
        <HorariosPageComponent tenantId={tenantId} pagesList={itemsPagesList} accessLevel="highAccess" />
    );
};

export default HorariosPage;
