import React from "react";
import styles from "./popUp3Component.module.css";
import { X } from "lucide-react";
import DefaultButton from "../buttons/DefaultButton";

const PopUp3Component = ({
    isOpen,
    title,
    description,
    data = {},
    buttons = [],
    onClose,
    renderCustomContent // <- adicionado aqui
}) => {
    if (!isOpen) return null;

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
                {/* Cabeçalho */}
                <div className={styles.header}>
                    <h2>{title}</h2>
                    <button className={styles.closeButton} onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                {/* Descrição opcional */}
                {description && <p className={styles.description}>{description}</p>}

                {/* Conteúdo da visualização */}
                <div className={styles.contentContainer}>
                    {Object.entries(data).map(([key, value], index) => (
                        <div key={index} className={styles.dataRow}>
                            <strong>{key}:</strong> <span>{value}</span>
                        </div>
                    ))}

                    {/* Aqui renderiza os detalhes extras das matérias, se houver */}
                    {typeof renderCustomContent === "function" && (
                        <div style={{ marginTop: "20px" }}>
                            {renderCustomContent()}
                        </div>
                    )}
                </div>

                {/* Botões dinâmicos */}
                {buttons.length > 0 && (
                    <div className={styles.footer}>
                        {buttons.map((button, index) => (
                            <DefaultButton key={index} {...button} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};


export default PopUp3Component;
