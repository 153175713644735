import React, { useEffect, useRef, useState } from 'react';
import styles from "./InterfaceStructure.module.css";
import { clearUserInfo, logout } from '../../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import imgLogo from "../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png";
import imgProfile from "../../assets/profile/user.png";
import { IoCloseCircleOutline, IoMenu, IoSearch } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import GetUserId from '../../hooks/getUserId';
import GetTenantId from '../../hooks/getTenantId';
import UseTenantById from '../../hooks/useTenantById';
import UseUserById from '../../hooks/useUserById';
import { backgroundImages } from 'polished';
import { HiOutlineMenu } from "react-icons/hi";

const InterfaceStructure = ({ itemsPages, dashboardContent }) => {
    const [isRightHeaderVisible, setIsRightHeaderVisible] = useState(window.innerWidth >= 700);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const profileMenuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = GetUserId();
    const tenantId = GetTenantId();
    const [searchText, setSearchText] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [filteredItems, setFilteredItems] = useState(itemsPages);

    useEffect(() => {
        if (searchText.trim() === "") {
            setFilteredItems(itemsPages); // Se o campo estiver vazio, mostra todos os itens
        } else {
            setFilteredItems(
                itemsPages.filter((item) =>
                    item.name.toLowerCase().includes(searchText.toLowerCase()) // Filtra os itens pelo nome
                )
            );
        }
    }, [searchText, itemsPages]);

    // Atualiza o tamanho da tela ao redimensionar a janela
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    // 🔥 Obtém detalhes do usuário
    const { user, loading: loadingUser } = UseUserById(userId);

    // 🔥 Obtém os detalhes do Tenant do usuário logado
    const { tenant, loading: loadingTenant } = UseTenantById(tenantId);

    // 🔥 Detecta clique fora do menu do perfil para fechá-lo
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setIsProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleRightHeaderVisibility = () => setIsRightHeaderVisible(!isRightHeaderVisible);
    const toggleProfileMenu = () => setIsProfileMenuOpen(!isProfileMenuOpen);

    const handleLogout = () => {
        dispatch(logout());
        clearUserInfo();
    };

    const handleClearSearch = () => {
        setSearchText(""); // Limpa o input
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadingTimer = setTimeout(() => {
            if (!loadingUser && !loadingTenant) {
                setIsLoading(false);
            }
        }, 2000); // Garante que o carregamento dure no mínimo 6 segundos

        return () => clearTimeout(loadingTimer);
    }, [loadingUser, loadingTenant]);


    return (
        <div className={styles.all}>
            {isLoading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingBar}></div>
                </div>
            )}
            <div className={styles.screen} style={isRightHeaderVisible ? null : { width: "100%" }}>
                <header>
                    <div className={styles.rightSide}>
                        <div className={styles.logo}>
                            {loadingTenant ? (
                                <p>Carregando...</p>
                            ) : tenant ? (
                                <>
                                    <div className={styles.menuButtom} onClick={toggleRightHeaderVisibility}><HiOutlineMenu  /></div>
                                    <img src={tenant.imageUrl} alt='logo' onClick={() => navigate('/')} />
                                </>
                            ) : (
                                <p>Erro ao carregar tenant</p>
                            )}
                        </div>
                    </div>
                    <div className={styles.leftSide}>
                        {loadingUser ? (
                            <p>Carregando usuário...</p>
                        ) : user ? (
                            <div className={styles.profile} onClick={toggleProfileMenu} ref={profileMenuRef}>
                                <img src={user.img ? user.img : imgProfile} alt='profile' />
                                <p>{user.firstName}</p>
                                <p className={styles.arrowProfile}>{isProfileMenuOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</p>
                                {isProfileMenuOpen && (
                                    <div className={styles.profileMenu}>
                                        <Link to={`/perfil/${userId}`}>Perfil</Link>
                                        <Link to={'/'} onClick={handleLogout}>Sair</Link>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>Erro ao carregar usuário</p>
                        )}
                    </div>
                </header>
                <div className={styles.all_bottom_page}>
                    {isRightHeaderVisible ? (
                        <div className={`${styles.rightHeader} ${isRightHeaderVisible ? styles.open : styles.closed} ${window.innerWidth >= 700 ? styles.hidden : ""}`} onClick={isRightHeaderVisible < 700 ? toggleRightHeaderVisibility : undefined}>
                            <div className={styles.searchContainer} onClick={isRightHeaderVisible < 700 ? (e) => e.stopPropagation() : undefined}>
                                {/* 🔍 Barra de pesquisa com lupa e botão de limpar */}
                                <div className={styles.searchBox}>
                                    {/* Ícone de busca */}
                                    <IoSearch className={styles.searchIcon} />

                                    {/* Campo de busca */}
                                    <input
                                        type="text"
                                        className={styles.searchInput}
                                        placeholder="Buscar..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />

                                    {/* Ícone de limpar texto (aparece apenas quando há texto) */}
                                    {searchText && (
                                        <IoCloseCircleOutline className={styles.clearIcon} onClick={handleClearSearch} />
                                    )}
                                </div>
                            </div>
                            <div className={styles.itemsContent} onClick={isRightHeaderVisible < 700 ? (e) => e.stopPropagation() : undefined}>
                                {filteredItems.map((item, index) => (
                                    <div
                                        key={`${item.id}-${index}`}
                                        onClick={() => navigate(item.link)}
                                        className={styles.itemElement}
                                        style={item.isSelect ? { background: "var(--hover-button-1)", color: "var(--text-color-1)", borderLeft: "solid 5px var(--tertiary-color)" } : null}
                                    >
                                        <p className={styles.icon}>{item.icon}</p>
                                        <p className={styles.text}>{item.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        windowWidth >= 700 ? (
                            <div className={`${styles.rightHeader} ${isRightHeaderVisible ? styles.open : styles.closed} ${window.innerWidth >= 700 ? styles.hidden : ""}`}>
                                <div className={styles.searchContainer}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "rgba(255,255,255,0.356)", padding: "10px", cursor: "pointer" }}>
                                        <IoSearch onClick={toggleRightHeaderVisibility} className={styles.searchIcon} style={{ color: "white", margin: "0px", padding: "0px" }} />
                                    </div>
                                </div>
                                <div className={styles.itemsContent} onClick={isRightHeaderVisible < 700 ? (e) => e.stopPropagation() : undefined}>
                                    {itemsPages.map((item, index) => (
                                        <div
                                            key={`${item.id}-${index}`}
                                            onClick={() => navigate(item.link)}
                                            className={styles.itemElement}
                                            style={item.isSelect ? { background: "var(--hover-button-1)", color: "var(--text-color-1)", borderLeft: "solid 5px var(--tertiary-color)" } : null}
                                        >
                                            <p className={styles.icon}>{item.icon}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (<></>)
                    )}
                    <div className={styles.contentScrennFull} >
                        <div className={styles.contentScrennDash}>
                            {dashboardContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterfaceStructure;
