import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import AgendaComponent from '../../../components/pagesComponents/AgendaComponents/AgendaComponent';
import useUserRoles from '../../../components/useUserRoles/useUserRoles';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';
import Loading from '../../../components/Loading/Loading';

const AgendaProfessorPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Agenda', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio', 'Banco de horas']);
    const [user, setUser] = useState();
    const [allowedSeries, setAllowedSeries] = useState([]);
    const [allowedTurnos, setAllowedTurnos] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = useMemo(() => {
        return userInfo?.token ? JSON.parse(atob(userInfo.token.split('.')[1])) : null;
    }, [userInfo?.token]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                const userData = response.data.user;
                setUser(userData);
    
                if (userData.isProfessor && userData.professorInfo?.length > 0) {
                    const series = userData.professorInfo.map(info => info.serie);
                    const turnos = userData.professorInfo.map(info => info.turno);
                    setAllowedSeries(series);
                    setAllowedTurnos(turnos);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                setIsLoading(false); 
            }
        };
    
        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);
    

    if (isLoading) {
        return <Loading />;
    }

    console.log(allowedSeries);
    console.log(allowedTurnos);

    return (
        <AgendaComponent PagesList={itemsPagesList} allowSeries={allowedSeries} allowTurnos={allowedTurnos} accessLevel="low" />
    );
}

export default AgendaProfessorPage