// src/pages/TenantManagementPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { itemsPagesWithExclusionsAdminMaster } from '../../../components/itemsPage/itemsPage';
import apiAxios from '../../../api/apiAxios';
import styles from './TenantManagementPage.module.css';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { FaEdit, FaTrashAlt, FaPlus, FaEye } from 'react-icons/fa';
import AddTenant from './AddTenant';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const TenantManagementPage = () => {
  const navigate = useNavigate();
  const itemsPagesList = itemsPagesWithExclusionsAdminMaster('Tenants', []);
  const [tenants, setTenants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = useSelector(state => state.user.userInfo?.token);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const res = await apiAxios.get('/api/user/tenants');
        setTenants(res?.data?.tenants);
      } catch (error) {
        console.error('Erro ao buscar tenants:', error);
      }
    };
    fetchTenants();
  }, []);

  const handleAddTenant = async (newTenant) => {
    try {
      const response = await apiAxios.post('/api/user/createnant', newTenant);
      setTenants([...tenants, response.data.tenant]);
      alert('Tenant adicionado com sucesso!');
    } catch (error) {
      console.error('Erro ao adicionar tenant:', error);
      alert('Erro ao adicionar tenant.');
    }
  };

  const handleDeleteTenant = async (tenantId) => {
    try {
      await apiAxios.delete(`/api/user/tenants/${tenantId}`);
      setTenants(tenants.filter((tenant) => tenant._id !== tenantId));
      alert('Tenant deletado com sucesso!');
    } catch (error) {
      console.error('Erro ao deletar tenant:', error);
      alert('Erro ao deletar tenant.');
    }
  };

  // Função para mostrar o popup de confirmação com SweetAlert2
  const confirmDelete = (tenantId) => {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteTenant(tenantId);
        MySwal.fire(
          'Deletado!',
          'O tenant foi deletado com sucesso.',
          'success'
        );
      }
    });
  };

  const dashboardContent = (
    <div className={styles.all}>
      <div className={styles.home}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Gerenciamento de Tenants</h1>
            <button className={styles.addButton} onClick={() => setIsModalOpen(true)}>
              <FaPlus /> Adicionar Tenant
            </button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Plano</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {tenants.length > 0 ? (
                  tenants.map((tenant) => (
                    <tr key={tenant._id}>
                      <td>{tenant.SchoolName}</td>
                      <td>{tenant.email}</td>
                      <td>{tenant.planType}</td>
                      <td>{tenant.isLifetime ? 'Vitalício' : tenant.subscriptionStatus}</td>
                      <td className={styles.actions}>
                        <button
                          className={styles.viewButton}
                          onClick={() => navigate(`/tenant/${tenant._id}`)}
                        >
                          <FaEye /> Visualizar
                        </button>
                        <button
                          className={styles.editButton}
                          onClick={() => navigate(`/edit-tenant/${tenant._id}`)}
                        >
                          <FaEdit /> Editar
                        </button>
                        <button
                          className={styles.deleteButton}
                          onClick={() => confirmDelete(tenant._id)} // Use a função de confirmação
                        >
                          <FaTrashAlt /> Deletar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className={styles.noData}>
                      Nenhum tenant encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isModalOpen && <AddTenant onClose={() => setIsModalOpen(false)} onAddTenant={handleAddTenant} />}
    </div>
  );

  return <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />;
};

export default TenantManagementPage;
