import React from 'react';
import { Plus } from 'lucide-react';
import useCalendario from '../../../hooks/calendar/useCalendario';
import useCategoryCalendario from '../../../hooks/calendar/useCategoryCalendario';
import InterfaceStructure from '../../shared/InterfaceStructure';
import GetTenantId from '../../../hooks/getTenantId';
import useCalendarioState from './useCalendarioState';
import formatarEventos from './formatarEventos';
import PopupsCalendario from './PopupsCalendario';
import CalendarioStructure from '../../shared/calendar/CalendarioStructure';
import { BiSolidCategory } from "react-icons/bi";
import { MdPictureAsPdf } from 'react-icons/md';

const CalendarioComponent = ({ itemsPages, accessLevel = "low" }) => {
    const tenantId = GetTenantId();
    const calendarioState = useCalendarioState(tenantId, useCalendario, useCategoryCalendario);
    const eventosFormatados = formatarEventos(calendarioState.eventos);

    const botoesCalendario = [
        ...(accessLevel === "high"
            ? [
                {
                    text: "Baixar PDF",
                    icon: MdPictureAsPdf,
                    size: "medium",
                    backgroundColor: "white",
                    borderColor: "",
                    color: "#1f2937",
                    onClick: calendarioState.handleBaixarCalendario
                }, {
                    text: "Gerenciar categorias",
                    icon: BiSolidCategory,
                    size: "medium",
                    backgroundColor: "white",
                    borderColor: "#1f2937",
                    color: "#1f2937",
                    onClick: () => calendarioState.setIsPopupGerenciarCategoriaOpen(true)
                }, {
                    text: "Criar Evento",
                    icon: Plus,
                    size: "medium",
                    backgroundColor: "#1f2937",
                    borderColor: "#1f2937",
                    color: "#ffffff",
                    onClick: () => calendarioState.setIsPopupOpen(true)
                }]
            : [])
    ];

    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <div>
                    {calendarioState.loading && <p>Carregando eventos...</p>}
                    {calendarioState.error && <p style={{ color: 'red' }}>Erro ao carregar eventos</p>}

                    <CalendarioStructure
                        eventos={eventosFormatados}
                        onDateClick={calendarioState.setDataSelecionada}
                        onEventClick={calendarioState.handleVisualizarEvento}
                        botoes={botoesCalendario}
                    />

                    {/* Passa handleEditarEvento corretamente para PopupsCalendario */}
                    <PopupsCalendario {...calendarioState} accessLevel={accessLevel} />
                </div>
            }
        />
    );
};

export default CalendarioComponent;
