import React, { useState, useEffect, useCallback } from 'react';
import styles from './CalendarioEditPage.module.css';
import { MdKeyboardArrowLeft, MdOutlineClass, MdOutlineSpaceDashboard } from "react-icons/md";
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { Link, useNavigate, useParams } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../hooks/getTenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const CalendarioEditPage = () => {
    const { id } = useParams(); // Obtém o ID do evento dos parâmetros da URL
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState(EditorState.createEmpty()); // Utilizando EditorState para o estado da descrição
    const [categoriaId, setCategoriaId] = useState(null);
    const [categoriaName, setCategoriaName] = useState('');
    const [categoriaColor, setCategoriaColor] = useState('');
    const [categorias, setCategorias] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const itemsPagesList = itemsPagesWithExclusions('Calendario', []);

    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
                setCategorias(response.data);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };

        const fetchEvento = async () => {
            try {
                const response = await apiAxios.get(`/api/calendar/${id}`);
                const evento = response.data;
                setTitulo(evento.Titulo);
                setStartDate(moment(evento.date, "DD/MM/YYYY HH:mm:ss").toDate());
                setCategoriaId(evento.categoria); // Definindo o ID da categoria
                setCategoriaName(evento.categoriaName); // Definindo o nome da categoria
                setCategoriaColor(evento.categoriaColor); // Definindo a cor da categoria
                setDescricao(EditorState.createWithContent(convertFromRaw(JSON.parse(evento.descricao))));
            } catch (error) {
                console.error('Erro ao buscar evento:', error);
            }
        };

        fetchCategorias();
        fetchEvento();
    }, [tenantId, id]);

    // Utilizando useCallback para otimizar o onChange do react-draft-wysiwyg
    const handleDescricaoChange = useCallback((editorState) => {
        setDescricao(editorState);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoriaId) {
            console.error('ID da Categoria não pode ser vazio');
            return;
        }

        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");

        // Convertendo o conteúdo para JSON para salvar no banco de dados
        const contentState = descricao.getCurrentContent();
        const rawContent = convertToRaw(contentState);

        const eventoAtualizado = {
            tenantId: tenantId,
            Titulo: titulo,
            descricao: JSON.stringify(rawContent), // Salvando como JSON
            categoria: categoriaId,  // Garantindo que o ID da categoria esteja correto
            categoriaName: categoriaName,
            categoriaColor: categoriaColor,
            date: formattedDate
        };

        console.log('Evento Atualizado:', eventoAtualizado); // Log do evento atualizado

        try {
            const response = await apiAxios.put(`/api/calendar/${id}`, eventoAtualizado);
            console.log(response);
            navigate('/calendario'); // Redireciona após a atualização
        } catch (error) {
            console.error('Erro ao atualizar evento no calendário:', error);
        }
    };

    // Criando uma opção padrão selecionada
    const defaultSelectedOption = categorias.find(categoria => categoria._id === categoriaId) || null;

    const handleCategoriaChange = (option) => {
        setCategoriaId(option ? option.value : '');
        setCategoriaName(option ? option.label.props.children[1] : '');
        setCategoriaColor(option ? option.label.props.children[0].props.style.backgroundColor : '');
        console.log('Categoria Alterada:', { categoriaId: option ? option.value : '', categoriaName: option ? option.label.props.children[1] : '', categoriaColor: option ? option.label.props.children[0].props.style.backgroundColor : '' });
    };

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/calendario'><MdKeyboardArrowLeft />Calendário</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Editar Evento do Calendário</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.BasicInfoForm}>
                        <p>Informações Básicas do Evento</p>
                        <input
                            type="text"
                            placeholder="Título do Evento"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            required
                        />
                        <Select
                            value={defaultSelectedOption ? {
                                value: defaultSelectedOption._id,
                                label: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                backgroundColor: defaultSelectedOption.category.color,
                                                marginRight: 10
                                            }}
                                        ></span>
                                        {defaultSelectedOption.category.name}
                                    </div>
                                )
                            } : null}
                            onChange={handleCategoriaChange}
                            options={categorias.map(categoria => ({
                                value: categoria._id,
                                label: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                backgroundColor: categoria.category.color,
                                                marginRight: 10
                                            }}
                                        ></span>
                                        {categoria.category.name}
                                    </div>
                                )
                            }))}
                            placeholder="Selecione uma categoria"
                            className={styles.reactSelect}
                            isClearable
                        />
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="dd/MM/yyyy HH:mm"
                            placeholderText="Selecione a data e hora"
                            className={styles.datePicker}
                            required
                        />
                        <Editor
                            editorState={descricao}
                            onEditorStateChange={handleDescricaoChange}
                            wrapperClassName={styles.editorWrapper}
                            editorClassName={styles.editor}
                            toolbarClassName={styles.toolbar}
                            placeholder="Digite a descrição do evento..."
                            toolbar={{
                                options: ['inline', 'fontSize', 'list'],
                                inline: { options: ['bold', 'italic', 'underline'] },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                list: { options: ['unordered', 'ordered'] }
                            }}
                        />
                        <div className={styles.botoesDeControle}>
                            <button type="submit">Salvar Alterações</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </div>
    );
}

export default CalendarioEditPage;
