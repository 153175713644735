import React, { useEffect, useRef, useState } from 'react';
import styles from './ChatSecretariaPage.module.css';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { PiPencilSimpleLineBold } from 'react-icons/pi';
import { RiMegaphoneLine } from 'react-icons/ri';
import { LuApple } from 'react-icons/lu';
import { MdCalendarToday, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from 'react-icons/md';
import { TfiAgenda } from 'react-icons/tfi';
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import apiAxios from '../../../api/apiAxios';
import { useSelector } from 'react-redux';
import useUserRoles from '../../../components/useUserRoles/useUserRoles';
import { IoIosArrowBack } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import Loading from '../../../components/Loading/Loading';

const ChatSecretariaPage = () => {
    const itemsPages = [
        { id: 1, name: "Painel", icon: <MdOutlineSpaceDashboard />, isSelect: false, link: "/" },
        { id: 2, name: "Agenda", icon: <TfiAgenda />, isSelect: false, link: "/agenda" },
        { id: 3, name: "Planos", icon: <MdOutlineClass />, isSelect: false, link: "/planos" },
        { id: 4, name: "Calendário", icon: <MdCalendarToday />, isSelect: false, link: "/calendario" },
        { id: 5, name: "Cardápio", icon: <LuApple />, isSelect: false, link: "/cardapio" },
        { id: 6, name: "Informes", icon: <RiMegaphoneLine />, isSelect: false, link: "/informes" },
        { id: 7, name: "Atividades", icon: <PiPencilSimpleLineBold />, isSelect: false, link: "/atividades" },
        { id: 8, name: "Chat", icon: <IoChatboxEllipsesOutline />, isSelect: true, link: "/chat" },
        { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    // 
    const { roles } = useUserRoles();

    const isAuthenticated = useSelector(state => state.user.userInfo?.token);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = isAuthenticated && JSON.parse(atob(userInfo.token.split('.')[1]));

    const tenantId = tokenPayload?.tenantId;
    const userId = tokenPayload?._id;

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isEndVisible, setIsEndVisible] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const [fillChat, setFillChat] = useState();
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filter, setFilter] = useState('all');

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [fillChat?.messages]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await apiAxios.get(`/api/user/users/${tenantId}`);
                const allUsers = response?.data?.users || [];
                const filteredUsers = allUsers.filter(user => user._id !== userId); // Filtra para excluir o próprio usuário
                setUsers(filteredUsers);
                setLoading(false);
            } catch (error) {
                console.error("Error retrieving users:", error);
                setError("Error retrieving users");
                setLoading(false);
            }
        };

        fetchUsers();
    }, [tenantId, userId]);

    const fetchChats = async () => {
        try {
            const responseChat = await apiAxios.get(`/api/messages/${userId}`);
            const messages = responseChat?.data || [];
            const userChat = messages.find(user =>
                (user?.receiver?._id === selectedUser?._id && user?.sender?._id === userId) ||
                (user?.receiver?._id === userId && user?.sender?._id === selectedUser?._id)
            );
            setFillChat(userChat);
        } catch (error) {
            console.error("Error retrieving chats:", error);
        }
    };

    useEffect(() => {
        // Função para buscar chats
        const intervalId = setInterval(() => {
            fetchChats();
        }, 3000); // Atualiza a cada 5 segundos

        // Executa fetchChats imediatamente na montagem do componente
        fetchChats();

        // Limpa o intervalo quando o componente for desmontado ou quando userId ou selectedUser mudar
        return () => clearInterval(intervalId);
    }, [userId, selectedUser]);

    const notificationChats = async () => {
        try {
            const responseChat = await apiAxios.get(`/api/messages/${userId}`);
            const messages = responseChat?.data || [];
            const unreadMessagesGroupedByUser = {};

            messages.forEach(message => {
                const otherUserId = message?.sender?._id === userId ? message?.receiver?._id : message?.sender?._id;
                if (!unreadMessagesGroupedByUser[otherUserId]) {
                    unreadMessagesGroupedByUser[otherUserId] = [];
                }
                unreadMessagesGroupedByUser[otherUserId].push(message);
            });

            setUnreadMessages(unreadMessagesGroupedByUser);
        } catch (error) {
            console.error("Error retrieving chats:", error);
        }
    };

    useEffect(() => {
        notificationChats();
    }, [tenantId, userId, selectedUser]);


    useEffect(() => {
        if (selectedUser) {
            const fetchMessages = async () => {
                try {
                    const response = await apiAxios.get(`/api/messages/${userId}`);
                    const allMessages = response?.data || [];
                    setMessages(allMessages);
                } catch (error) {
                    console.error("Error retrieving messages:", error);
                    setError("Error retrieving messages");
                }
            };
            fetchMessages();
        }
    }, [selectedUser, userId]);

    const role = () => {
        if (roles.isAdmin === true) {
            return 'Admin'
        } else if (roles.isCoordenaçao === true) {
            return 'Coordenacao'
        } else if (roles.isDiretor === true) {
            return 'Diretor'
        } else if (roles.isNutricionista === true) {
            return 'Nutricionista'
        } else if (roles.isProfessor === true) {
            return 'Professor'
        } else if (roles.isSecretaria === true) {
            return 'Secretaria'
        } else if (roles.isAluno === true) {
            return 'Aluno'
        } else if (roles.isResponsavel === true) {
            return 'Responsavel'
        } else if (roles.isAdminMaster === true) {
            return 'Admin Master'
        } else {
            return null
        }
    }

    const roleSelectedUser = () => {
        if (selectedUser.isAdmin === true) {
            return 'Admin'
        } else if (selectedUser.isCoordenaçao === true) {
            return 'Coordenacao'
        } else if (selectedUser.isDiretor === true) {
            return 'Diretor'
        } else if (selectedUser.isNutricionista === true) {
            return 'Nutricionista'
        } else if (selectedUser.isProfessor === true) {
            return 'Professor'
        } else if (selectedUser.isSecretaria === true) {
            return 'Secretaria'
        } else if (selectedUser.isAluno === true) {
            return 'Aluno'
        } else if (selectedUser.isResponsavel === true) {
            return 'Responsavel'
        } else if (selectedUser.isAdminMaster === true) {
            return 'Admin Master'
        } else {
            return null
        }
    }

    const updateMessagesToRead = async () => {
        try {
            // Filtrar mensagens não lidas enviadas por outros usuários
            const messagesToUpdate = fillChat.messages.filter(message => message.sender.toString() !== userId && !message.isView);

            // Iterar sobre cada mensagem a ser marcada como lida
            for (const message of messagesToUpdate) {
                try {
                    // Fazer a requisição PUT para marcar a mensagem como lida
                    await apiAxios.put(`/api/messages/mark-as-read/${fillChat?._id}`, { idmessages: [message._id] });

                } catch (error) {
                    console.error(`Error marking message ${message._id} as read:`, error);
                    // Tratar erros específicos, se necessário
                }
            }

        } catch (error) {
            console.error("Error updating message read status:", error);
            // Tratar erros gerais aqui, se necessário
        }
    };

    // Efeito para chamar a função de atualização quando o usuário ou o chat preenchido forem alterados
    useEffect(() => {
        if (selectedUser && fillChat) {
            updateMessagesToRead();
        }
    }, [selectedUser, fillChat, userId, setFillChat]);


    const handleSendMessage = async () => {
        try {
            const response = await apiAxios.post('/api/messages/send', {
                senderId: userId,
                receiverId: selectedUser._id,
                message: { content: newMessage },
                senderRole: role(),
                receiverRole: roleSelectedUser()
            });
            const updatedMessages = [...messages];
            const lastMessage = updatedMessages.find(
                (message) => (message?.receiver?._id === selectedUser?._id && message?.sender?._id === userId) || (message?.receiver?._id === userId && message?.sender?._id === selectedUser?._id)
            );
            if (lastMessage) {
                lastMessage.messages.push({ content: newMessage });
            } else {
                updatedMessages.push(response.data);
            }
            setMessages(updatedMessages);
            await fetchChats();
            setNewMessage('');
        } catch (error) {
            console.error("Error sending message:", error);
            setError("Error sending message");
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 700 && selectedUser) {
                setIsEndVisible(true);
            } else {
                setIsEndVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedUser]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 700);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {
        return <Loading />;
    }

    const handleBackClick = () => {
        setSelectedUser(null);
        setIsEndVisible(false);
    };

    const filteredUsers = users.filter(user => {
        const nameMatches = user.firstName.toLowerCase().includes(searchText.toLowerCase());
        const unreadCount = unreadMessages[user._id]
            ? unreadMessages[user._id]
                .flatMap((use) => use.messages)
                .filter((message) => message?.isView === false && message?.sender !== userId).length
            : 0;

        if (filter === 'unread' && unreadCount === 0) {
            return false;
        }

        if (filter === 'read' && unreadCount > 0) {
            return false;
        }

        return nameMatches;
    });
    // 

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.Area}>
                    <div className={styles.start}>
                        <div className={styles.searchUser}>
                            <div>
                                <FiSearch />
                                <input
                                    type="text"
                                    placeholder="Pesquisar usuários..."
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    className={styles.searchInput}
                                />
                            </div>
                        </div>
                        <div className={styles.filterUser}>
                            <div style={{ background: filter === 'all' ? "#ec3237" : 'transparent', color: filter === 'all' ? 'white' : 'black' }} onClick={() => setFilter('all')}>Todas</div>
                            <div style={{ background: filter === 'unread' ? "#ec3237" : 'transparent', color: filter === 'unread' ? 'white' : 'black' }} onClick={() => setFilter('unread')}>Não lidas</div>
                            <div style={{ background: filter === 'read' ? "#ec3237" : 'transparent', color: filter === 'read' ? 'white' : 'black' }} onClick={() => setFilter('read')}>Lidas</div>
                        </div>
                        {filteredUsers.map((user) => {
                            const unreadCount = unreadMessages[user._id] ? unreadMessages[user._id]
                                .flatMap(use => use.messages)
                                .filter(message => message.isView === false && message.sender !== userId)
                                .length : 0;

                            return (
                                <div
                                    style={{ background: selectedUser?._id === user._id ? 'rgb(207, 207, 207)' : 'transparent' }}
                                    className={styles.Perfischats}
                                    key={user._id}
                                    onClick={() => setSelectedUser(user)}
                                >
                                    <img src={user?.img} alt='img' />
                                    <h5>{user?.firstName}</h5>
                                    {unreadCount > 0 && (
                                        <span className={styles.unreadBadge}>{unreadCount}</span>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {selectedUser ?
                        <>
                            <div className={styles.end} style={{ display: isEndVisible ? 'flex' : 'none', }}>
                                {isMobileView ?
                                    <div className={styles.leaveChat} >
                                        <button onClick={handleBackClick}><IoIosArrowBack /></button>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className={styles.messages} ref={messagesEndRef}>
                                    <div className={styles.message}>
                                        <>
                                            {fillChat?.messages.map((mess, index) => (
                                                <div style={userId === mess.sender ? { background: "#ec3237", color: "white", marginLeft: "50%" } : {}} className={`${styles.messChat} ${fillChat.senderRole === 'coordenação' ? styles.sent : styles.received}`} key={index}>{mess.content}</div>
                                            ))}
                                        </>
                                    </div>
                                </div>
                                <div className={styles.chat}>
                                    <div>
                                        <input
                                            placeholder='Digite sua mensagem'
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                        />
                                        <button onClick={handleSendMessage}><IoSend /></button>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );

    return (
        <>
            <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
        </>
    );
};

export default ChatSecretariaPage;
