import React from 'react';
import styles from './IconButton.module.css';

const IconButton = ({ icon: Icon, onClick, color = '#374151', backgroundColor = '#ffffff', borderColor = '#d1d5db', size = 'medium' }) => {
  return (
    <button 
      className={`${styles.iconButton} ${styles[size]}`} 
      style={{ color, backgroundColor, border: `1px solid ${borderColor}` }} 
      onClick={onClick}
    >
      {Icon && <Icon className={styles.icon} style={{ color }} />} 
    </button>
  );
};

export default IconButton;
