import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InterfaceStructure from '../../shared/InterfaceStructure';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useSelector } from 'react-redux';
import styles from './CardapioComponentCreate.module.css';
import Select from 'react-select';
import apiAxios from '../../../api/apiAxios';
import moment from 'moment';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaRegTrashAlt } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegSave } from "react-icons/fa";

const getTextColor = (bgColor) => {
  const hex = bgColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminosity > 186 ? '#000000' : '#FFFFFF';
};

const getOptionStyle = (bgColor) => ({
  backgroundColor: bgColor,
  color: getTextColor(bgColor),
  padding: '5px',
  borderRadius: '5px'
});

const CardapioComponentCreate = ({ pagesList }) => {
  const diasDaSemana = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'];
  const initialAlimentacao = [
    {
      refeicao: '',
      conteudo: diasDaSemana.map(dia => ({ diaDaSemana: dia, itensCardapio: [] }))
    }
  ];

  const [Titulo, setTitulo] = useState('');
  const [date, setDate] = useState('');
  const [dateFormattedEnd, setDateFormattedEnd] = useState('');
  const [observacao, setObservacao] = useState(EditorState.createEmpty());
  const [alimentacao, setAlimentacao] = useState(initialAlimentacao);
  const [itensDisponiveis, setItensDisponiveis] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload.tenantId;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchItensCardapio = async () => {
      try {
        const response = await apiAxios.get(`/api/card/item/items/${tenantId}`);
        const itens = response.data.map(item => ({
          value: item._id,
          label: item.titulo,
          categoriaColor: item.group.Color
        }));
        setItensDisponiveis(itens);
      } catch (error) {
        console.error('Erro ao buscar itens do cardápio:', error);
      }
    };

    fetchItensCardapio();
  }, [tenantId]);

  const handleRefeicaoChange = (index, event) => {
    const newAlimentacao = [...alimentacao];
    newAlimentacao[index].refeicao = event.target.value;
    setAlimentacao(newAlimentacao);
  };

  const handleItemCardapioChange = (index, conteudoIndex, selectedItems) => {
    const newAlimentacao = [...alimentacao];
    newAlimentacao[index].conteudo[conteudoIndex].itensCardapio = selectedItems ? selectedItems.map(item => item.value) : [];
    setAlimentacao(newAlimentacao);
  };

  const handleAddRefeicao = () => {
    if (alimentacao.length < 7) {
      setAlimentacao([
        ...alimentacao,
        {
          refeicao: '',
          conteudo: diasDaSemana.map(dia => ({ diaDaSemana: dia, itensCardapio: [] }))
        }
      ]);
    } else {
      alert('Você só pode adicionar no máximo 7 refeições.');
    }
  };

  const handleRemoveRefeicao = (index) => {
    if (alimentacao.length > 1) {
      const newAlimentacao = alimentacao.filter((_, i) => i !== index);
      setAlimentacao(newAlimentacao);
    } else {
      alert('Deve haver pelo menos uma refeição.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const formattedDateEnd = moment(dateFormattedEnd).format('YYYY-MM-DD');
      const rawObservacao = JSON.stringify(convertToRaw(observacao.getCurrentContent()));

      const newCardapio = {
        Titulo,
        date: formattedDate,
        dateFormattedEnd: formattedDateEnd,
        alimentacao,
        observacao: rawObservacao
      };

      const response = await apiAxios.post(`/api/card/${tenantId}`, newCardapio);
      console.log('Cardápio criado com sucesso:', response.data);
      alert('Cardápio salvo com sucesso!');
      navigate('/cardapio');
    } catch (error) {
      console.error('Erro ao criar cardápio:', error.response?.data || error.message || error);
      alert('Erro ao salvar o cardápio. Confira os logs para mais informações.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const dashboardContent = (
    <div className={styles.all}>
      <div className={styles.home}>
        <div className={styles.top}>
          <div className={styles.returnPainelArrow}>
            <Link to='/cardapio'><MdKeyboardArrowLeft />Painel</Link>
          </div>
          <div className={styles.topAreaInfo}>
            <div className={styles.start}>
              <h1>Adicionar Cardápio</h1>
              <p>Administrador</p>
            </div>
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <h4>Título:</h4>
            <input
              type="text"
              value={Titulo}
              onChange={(e) => setTitulo(e.target.value)}
              placeholder='Título'
              required
            />
            <h4>Data do Calendário:</h4>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
            <h4>Data Final do Calendário:</h4>
            <input
              type="date"
              value={dateFormattedEnd}
              onChange={(e) => setDateFormattedEnd(e.target.value)}
              required
            />
          </div>
          <div className={styles.alimentacaoContainer}>
            {alimentacao.map((item, index) => (
              <div key={index} className={styles.refeicaoContainer}>
                <button type="button" onClick={() => handleRemoveRefeicao(index)} className={styles.removeButton}>
                  <FaRegTrashAlt />
                </button>
                <h3>{item.refeicao === "" ? `Refeição ${index + 1}` : `Refeição: ${item.refeicao}`}</h3>
                <input
                  type="text"
                  value={item.refeicao}
                  onChange={(e) => handleRefeicaoChange(index, e)}
                  placeholder="Café | Almoço | Janta | etc..."
                  className={styles.inputRefeicao}
                  required
                />
                {item.conteudo.map((conteudo, conteudoIndex) => (
                  <div key={conteudoIndex} className={styles.diaContainer}>
                    <h4>{conteudo.diaDaSemana}</h4>
                    <Select
                      isMulti
                      options={itensDisponiveis}
                      getOptionLabel={(item) => (
                        <div style={getOptionStyle(item.categoriaColor)}>
                          {item.label}
                        </div>
                      )}
                      value={itensDisponiveis.filter(option => conteudo.itensCardapio.includes(option.value))}
                      onChange={(selectedItems) => handleItemCardapioChange(index, conteudoIndex, selectedItems)}
                      placeholder="Selecione os itens do cardápio"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.formGroup}>
            <h4>Observação (opcional):</h4>
            <Editor
              editorState={observacao}
              onEditorStateChange={setObservacao}
              wrapperClassName={styles.editorWrapper}
              editorClassName={styles.editor}
              toolbarClassName={styles.toolbar}
              placeholder="Escreva a observação do cardápio..."
              toolbar={{
                options: ['inline', 'list', 'textAlign', 'history'],
                inline: { options: ['bold', 'italic', 'underline'] },
                list: { options: ['unordered', 'ordered'] },
              }}
              editorStyle={{ padding: '10px', minHeight: '150px', border: '1px solid #e0e0e0', borderRadius: '4px' }}
            />
          </div>
          <div className={styles.botoesDeControle}>
            <button className={styles.addButton} type="submit"><FaRegSave /> Salvar Cardápio</button>
            <button type="button" onClick={handleAddRefeicao}>
              <IoMdAddCircleOutline /> Adicionar Refeição
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return <InterfaceStructure itemsPages={pagesList} dashboardContent={dashboardContent} />;
};

export default CardapioComponentCreate;
