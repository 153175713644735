import React from 'react';
import useTenantId from '../../../hooks/getTenantId';
import CardapioComponent from '../../../components/pagesComponents/CardapioComponents/CardapioComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import GetTenantId from '../../../hooks/getTenantId';

const CardapioNutricionistaPage = () => {
  const tenantId = GetTenantId();
  const pagesList = itemsPagesWithExclusions('Horários', ['Agenda', 'Informes', 'Atividades', 'Fichas', 'Planos','Banco de horas']);

  return (
    <>
      <CardapioComponent tenantId={tenantId} accessLevel="high" PagesList={pagesList}/>
    </>
  );
}

export default CardapioNutricionistaPage;
