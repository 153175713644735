import { useEffect, useState } from "react";
import apiAxios from "../api/apiAxios";

const UseTenantById = (tenantId) => {
    const [tenant, setTenant] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchTenant = async () => {
            if (!tenantId) {
                console.warn("⚠️ Nenhum tenantId fornecido, não chamando a API");
                return;
            }

            setLoading(true);
            try {
                const response = await apiAxios.get(`/api/user/tenants`);
                const foundTenant = response.data.tenants.find(t => t._id === tenantId);

                if (foundTenant) {
                    console.log("✅ Tenant encontrado");
                    setTenant(foundTenant);
                } else {
                    console.warn("❌ Tenant não encontrado.");
                }
            } catch (err) {
                console.error("❌ Erro ao buscar tenant:", err);
                setError(err.response?.data?.message || "Erro ao carregar tenant.");
            }
            setLoading(false);
        };

        fetchTenant();
    }, [tenantId]); // ✅ Só executa quando o `tenantId` muda

    return { tenant, loading, error };
};

export default UseTenantById;
