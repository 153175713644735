import React, { useEffect, useState } from "react";
import PopUpComponent from "../../shared/popUp/PopUpComponent";
import PopUp2Component from "../../shared/popUp/popUp2Component";
import PopUp3Component from "../../shared/popUp/popUp3Component";
import PopUp4Component from "../../shared/popUp/popUp4Component";
import useMaterias from "../../../hooks/Materias/useMaterias";
import GetTenantId from "../../../hooks/getTenantId";
import PopUp5Component from "../../shared/popUp/popUp5Component";
import CustomInput from "../../shared/inputs/CustomInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./AgendaPopups.module.css";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { app } from '../../../pages/firebase';
import { FaHistory } from "react-icons/fa";

const AgendaPopups = ({
    agendas,
    isOpen,
    onClose,
    agendaData,
    isCreating,
    isEditing,
    isDeleting,
    onSaveCreate,
    onSaveEdit,
    onEditClick,
    onDelete,
    onDeleteClick,
    onConfirmDelete,
    toggleAgendaStatus,
    accessLevel,
    popupRegistrosAberto,
    setPopupRegistrosAberto,
    registros,
    popupDetalhesAberto,
    setPopupDetalhesAberto,
    registroSelecionado,
    abrirDetalhesDoRegistro,
}) => {


    const tenantId = GetTenantId();
    const serieAtual = agendaData?.serie || "";
    const [materiasAgenda, setMateriasAgenda] = useState([]);
    const [materiasFaltando, setMateriasFaltando] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [savedImages, setSavedImages] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const agendaCorrespondente = agendas.find(agenda => agenda?._id === registroSelecionado?.agendaId);
    const dataDaAgenda = agendaCorrespondente?.date || "Data não encontrada";

    const {
        materias,
        fetchMaterias,
        loading: loadingMaterias
    } = useMaterias({ tenantId, serie: serieAtual });


    useEffect(() => {
        if (isEditing && agendaData?.imgUrls) {
            setSavedImages(agendaData.imgUrls);
        }
    }, [isEditing, agendaData?.imgUrls]);

    useEffect(() => {
        if (isCreating && materias.length && Array.isArray(materias[0]?.materia)) {
            const materiasGeradas = materias[0].materia.map((mat) => ({
                materia: mat.materia,
                color: mat.color || "#000", // padrão preto se não tiver cor
                description: mat.description || "",
                objetivoConhecimento: "",
                habilidades: "",
                metodologia: "",
                material: ""
            }));

            setMateriasAgenda(materiasGeradas);
        }
    }, [isCreating, materias]);

    useEffect(() => {
        if (
            isEditing &&
            agendaData?.materia &&
            Array.isArray(agendaData.materia) &&
            materias.length &&
            Array.isArray(materias[0]?.materia)
        ) {
            const materiasAgendaAtual = agendaData.materia.map((mat) => ({
                materia: mat.materia,
                description: mat.description || "",
                color: mat.color || "#000",
                objetivoConhecimento: mat.objetivoConhecimento || "",
                habilidades: mat.habilidades || "",
                metodologia: mat.metodologia || "",
                material: mat.material || ""
            }));


            // Lista de nomes já na agenda
            const nomesJaExistentes = materiasAgendaAtual.map((m) => m.materia);

            // Matérias da série vinda do hook que ainda não estão na agenda
            const materiasFaltando = materias[0].materia.filter((mat) => !nomesJaExistentes.includes(mat.materia));

            // Cria os objetos das matérias faltando com campos em branco
            const materiasParaAdicionar = materiasFaltando.map((mat) => ({
                materia: mat.materia,
                description: mat.description || "",
                color: mat.color || "#000",
                objetivoConhecimento: "",
                habilidades: "",
                metodologia: "",
                material: ""
            }));

            // Junta tudo
            setMateriasAgenda(materiasAgendaAtual);
            setMateriasFaltando(materiasParaAdicionar);

        }
    }, [isEditing, agendaData?.materia, materias]);

    const renderMateriaOptions = () => {
        if (loadingMaterias) return [{ value: "", label: "Carregando..." }];
        if (!materias.length || !Array.isArray(materias[0]?.materia)) return [{ value: "", label: "Nenhuma matéria encontrada" }];

        return materias[0].materia.map((mat) => ({
            value: mat.materia,
            label: mat.materia,
        }));
    };

    const gerarMateriasParaAgenda = () => {
        if (!materias.length || !Array.isArray(materias[0]?.materia)) return [];

        return materias[0].materia.map((mat) => ({
            materia: mat.materia,
            description: mat.description || "",
            color: mat.color || "",
            objetivoConhecimento: "",
            habilidades: "",
            metodologia: "",
            material: "",
        }));
    };

    const adicionarMateriaFaltante = (materiaNome) => {
        const materia = materiasFaltando.find(m => m.materia === materiaNome);
        if (!materia) return;

        setMateriasAgenda(prev => [...prev, materia]);

        // Remove do select
        setMateriasFaltando(prev => prev.filter(m => m.materia !== materiaNome));
    };

    const handleImageUpload = async () => {
        console.log("🔄 Iniciando upload das imagens...");
        setUploading(true);
        const storage = getStorage(app);
        const urls = [];

        try {
            for (let i = 0; i < selectedImages.length; i++) {
                const image = selectedImages[i];
                console.log(`📦 Upload da imagem: ${image.name}`);

                const imageRef = ref(storage, `agendas/${Date.now()}_${image.name}`);
                const snapshot = await uploadBytes(imageRef, image);
                console.log(`✅ Upload concluído para: ${image.name}`);

                const downloadURL = await getDownloadURL(snapshot.ref);
                console.log(`🌐 URL obtida: ${downloadURL}`);

                urls.push(downloadURL);
            }
        } catch (error) {
            console.error("❌ Erro durante upload:", error);
        }

        setUploading(false);
        console.log("✅ Upload finalizado. URLs geradas:", urls);
        return urls;
    };

    const handleToggleStatus = async () => {
        try {
            await toggleAgendaStatus(agendaData._id, agendaData.agendastatus);
            onClose();
            window.location.reload(); // 👈 Força reload
        } catch (err) {
            console.error("Erro ao alternar status da agenda:", err);
        }
    };

    const handleRemoveSavedImage = (urlToDelete, index) => {
        setImagesToDelete((prev) => [...prev, urlToDelete]);
        const novas = savedImages.filter((_, i) => i !== index);
        setSavedImages(novas);
    };

    const deleteMarkedImages = async () => {
        const storage = getStorage(app);
        for (const url of imagesToDelete) {
            try {
                const path = decodeURIComponent(new URL(url).pathname.split("/o/")[1]).split("?")[0];
                const imageRef = ref(storage, path);
                await deleteObject(imageRef);
                console.log("🗑️ Imagem removida do Firebase:", url);
            } catch (error) {
                console.error("❌ Erro ao remover imagem do Firebase:", error);
            }
        }
    };


    const renderMateriasInputs = () => (
        <div>
            {/* Se houver matérias faltando, exibe o seletor */}
            {materiasFaltando.length > 0 && (
                <div style={{ marginBottom: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "5px", flexWrap: "wrap", width: "100%" }}>
                        <CustomInput
                            label="Restaurar matéria"
                            type="select"
                            name="restaurarMateria"
                            value=""
                            fullWidth={true}
                            options={materiasFaltando.map((m) => ({
                                value: m.materia,
                                label: m.materia
                            }))}
                            onChange={({ target }) => {
                                if (!target.value) return;
                                adicionarMateriaFaltante(target.value);
                            }}
                        />
                        <button
                            onClick={() => {
                                setMateriasAgenda(prev => [...prev, ...materiasFaltando]);
                                setMateriasFaltando([]);
                            }}
                            style={{
                                backgroundColor: "var(--primary-color)",
                                color: "#fff",
                                padding: "6px 12px",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                height: "40px"
                            }}
                        >
                            Restaurar Todas
                        </button>
                    </div>
                </div>
            )}

            {/* Matérias existentes */}
            {materiasAgenda.map((item, index) => (
                <div key={index} className={styles.materia}>
                    <strong className={styles.topMateria} style={{ borderLeft: `${item.color} 5px solid` }}>
                        {item.materia}
                        <div style={{ textAlign: "right", marginTop: "10px" }}>
                            <button
                                onClick={() => handleRemoverMateria(index)}
                                style={{
                                    backgroundColor: "#e74c3c",
                                    color: "#fff",
                                    padding: "6px 12px",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer"
                                }}
                            >
                                Remover Matéria
                            </button>
                        </div>
                    </strong>
                    <CustomInput
                        label="Objetos de Conhecimento"
                        value={item.objetivoConhecimento}
                        onChange={(e) => handleChangeMateria(index, "objetivoConhecimento", e.target.value)}
                        fullWidth
                    />
                    <div style={{ paddingTop: "10px" }} />
                    <CustomInput
                        label="Habilidades"
                        value={item.habilidades}
                        onChange={(e) => handleChangeMateria(index, "habilidades", e.target.value)}
                        fullWidth
                    />
                    <div style={{ paddingTop: "10px" }} />
                    <CustomInput
                        label="Metodologia"
                        value={item.metodologia}
                        onChange={(e) => handleChangeMateria(index, "metodologia", e.target.value)}
                        fullWidth
                    />
                    <div className={styles.material}>
                        <label style={{ fontWeight: "bold", marginBottom: "4px", display: "block" }}>Material:</label>
                        <ReactQuill
                            value={item.material}
                            onChange={(value) => handleChangeMateria(index, "material", value)}
                            theme="snow"
                            style={{ backgroundColor: "#fff", borderRadius: "6px", height: "150px" }}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ["bold", "italic", "underline", "link"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["clean"],
                                ],
                            }}
                        />
                    </div>
                </div>
            ))}

            <div style={{ marginBottom: "1rem" }}>
                <label style={{ fontWeight: "bold" }}>Imagens (máx. 5):</label>
                <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => {
                        const files = Array.from(e.target.files).slice(0, 5);
                        setSelectedImages(files);
                    }}
                />
                {selectedImages.length > 0 && (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
                        {selectedImages.map((file, index) => (
                            <div key={index} style={{ position: "relative", width: "100px", height: "100px", border: "1px solid #ccc", borderRadius: "6px", overflow: "hidden" }}>
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Imagem ${index + 1}`}
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                                <button
                                    onClick={() => {
                                        const novas = [...selectedImages];
                                        novas.splice(index, 1);
                                        setSelectedImages(novas);
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: 2,
                                        right: 2,
                                        backgroundColor: "#e74c3c",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        lineHeight: "18px"
                                    }}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {isEditing && savedImages.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                        <label style={{ fontWeight: "bold" }}>Imagens já salvas:</label>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
                            {savedImages.map((url, index) => (
                                <div key={index} style={{ position: "relative", width: "100px", height: "100px", border: "1px solid #ccc", borderRadius: "6px", overflow: "hidden" }}>
                                    <img
                                        src={url}
                                        alt={`Imagem salva ${index + 1}`}
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                    <button
                                        onClick={() => handleRemoveSavedImage(url, index)}
                                        style={{
                                            position: "absolute",
                                            top: 2,
                                            right: 2,
                                            backgroundColor: "#e74c3c",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "50%",
                                            width: "20px",
                                            height: "20px",
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                            lineHeight: "18px"
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

            </div>

        </div>
    );


    const handleChangeMateria = (index, campo, valor) => {
        const novasMaterias = [...materiasAgenda];
        novasMaterias[index][campo] = valor;
        setMateriasAgenda(novasMaterias);
    };

    const handleRemoverMateria = (index) => {
        const novasMaterias = [...materiasAgenda];
        const materiaRemovida = novasMaterias.splice(index, 1)[0];
        setMateriasAgenda(novasMaterias);

        // Se estiver no modo criação, precisamos verificar se a matéria removida está entre as cadastradas
        if (isCreating && materias.length && Array.isArray(materias[0]?.materia)) {
            const todasMaterias = materias[0].materia;
            const jaTem = novasMaterias.some(m => m.materia === materiaRemovida.materia);
            const existeNaLista = todasMaterias.some(m => m.materia === materiaRemovida.materia);

            if (!jaTem && existeNaLista) {
                setMateriasFaltando(prev => [...prev, {
                    materia: materiaRemovida.materia,
                    description: materiaRemovida.description || "",
                    color: materiaRemovida.color || "#000",
                    objetivoConhecimento: "",
                    habilidades: "",
                    metodologia: "",
                    material: ""
                }]);
            }
        }
    };

    useEffect(() => {
        if (!agendaData?._id) return;

        setMateriasAgenda([]);
        setMateriasFaltando([]);
        setSelectedImages([]);
        setSavedImages([]);
        setImagesToDelete([]);

        // Você pode logar pra ver se está limpando corretamente
        console.log("🧹 Limpando estados para nova edição:", agendaData._id);
    }, [agendaData?._id]);

    if (!isOpen && !popupRegistrosAberto && !popupDetalhesAberto) return null;
    return (
        <>
            {/* Criar Agenda */}
            <PopUp5Component
                isOpen={isOpen && isCreating}
                title="Criar Agenda"
                description="Preencha os detalhes da nova agenda"
                inputs={[
                    { label: "Data", type: "date", value: agendaData?.date || "", onChange: (e) => console.log(e.target.value) },
                    { label: "Série", type: "text", value: agendaData?.serie || "", onChange: (e) => console.log(e.target.value), disabled: true },
                    { label: "Turno", type: "text", value: agendaData?.turno || "", onChange: (e) => console.log(e.target.value), disabled: true },
                    { label: "Matérias", type: "custom", render: renderMateriasInputs } // 👈 custom tipo novo
                ]}
                buttons={[
                    { text: "Cancelar", onClick: onClose, backgroundColor: "white", color: "var(--primary-color)" },
                    {
                        text: "Criar",
                        onClick: async () => {
                            const imageUrls = await handleImageUpload();

                            const novaAgenda = {
                                ...agendaData,
                                materia: materiasAgenda,
                                tenantId,
                                imgUrls: imageUrls
                            };

                            console.log("📄 Criar nova agenda com imagens:", novaAgenda);
                            await onSaveCreate(novaAgenda);
                            onClose();
                        },
                        backgroundColor: "var(--primary-color)",
                        color: "#fff"
                    }
                ]}
                onClose={onClose}
            />


            {/* Editar Agenda */}
            <PopUp5Component
                isOpen={isOpen && isEditing}
                title="Editar Agenda"
                description="Atualize os dados da agenda"
                inputs={[
                    { label: "Data", type: "date", value: agendaData?.date || "", onChange: (e) => console.log(e.target.value) },
                    { label: "Série", type: "text", value: agendaData?.serie || "", onChange: (e) => console.log(e.target.value), disabled: true },
                    { label: "Turno", type: "text", value: agendaData?.turno || "", onChange: (e) => console.log(e.target.value), disabled: true },
                    {
                        label: "Matérias",
                        type: "custom",
                        render: renderMateriasInputs
                    }
                ]}
                buttons={[
                    { text: "Cancelar", onClick: onClose, backgroundColor: "white", color: "var(--primary-color)" },
                    {
                        text: "Salvar Alterações",
                        onClick: async () => {
                            const novosLinks = await handleImageUpload();

                            await deleteMarkedImages(); // <-- aqui!

                            const agendaAtualizada = {
                                _id: agendaData._id,
                                tenantId,
                                date: agendaData.date,
                                materia: materiasAgenda,
                                serie: agendaData.serie,
                                turno: agendaData.turno,
                                agendastatus: agendaData.agendastatus || false,
                                imgUrls: [...savedImages, ...novosLinks].slice(0, 5)
                            };

                            const { _id, ...dadosSemId } = agendaAtualizada;
                            await onSaveEdit(_id, dadosSemId);
                            onClose();
                        },
                        backgroundColor: "var(--primary-color)",
                        color: "#fff"
                    }
                ]}
                onClose={onClose}
            />
            <PopUp4Component
                isOpen={isOpen && agendaData?.isDeleting}
                title="Excluir Agenda"
                message="Tem certeza que deseja excluir esta agenda?"
                onConfirm={async () => {
                    try {
                        await onConfirmDelete(agendaData._id); // agora sim deletando
                        onClose();
                    } catch (error) {
                        console.error("Erro ao excluir agenda:", error);
                    }
                }}
                onCancel={onClose}
            />


            {/* PopUp de Detalhes */}
            <PopUp3Component
                isOpen={isOpen && !isCreating && !isEditing && !isDeleting}
                title="Detalhes da Agenda"
                description="Veja os detalhes do evento agendado abaixo:"
                data={{
                    "Data": agendaData?.date || "Não disponível",
                    "Status": agendaData?.agendastatus ? "Ativo" : "Inativo",
                    "Série": agendaData?.serie || "Não informado",
                    "Turno": agendaData?.turno || "Não informado",
                }}
                renderCustomContent={() => (
                    <div style={{ padding: "10px 0" }}>
                        <h4 style={{ marginBottom: "10px" }}>📚 Detalhes das Matérias:</h4>
                        {Array.isArray(agendaData?.materia) && agendaData.materia.length > 0 ? (
                            agendaData.materia.map((m, index) => (
                                <div key={index} style={{ padding: "10px", marginBottom: "10px", borderLeft: `4px solid ${m.color || "#333"}`, backgroundColor: "#f8f8f8", borderRadius: "4px" }}>
                                    <strong>{m.materia}</strong>
                                    <p><b>Objetivo:</b> {m.objetivoConhecimento || "-"}</p>
                                    <p><b>Habilidades:</b> {m.habilidades || "-"}</p>
                                    <p><b>Metodologia:</b> {m.metodologia || "-"}</p>
                                    <p><b>Material:</b> {
                                        m.material ? (
                                            <div dangerouslySetInnerHTML={{ __html: m.material }} />
                                        ) : "-"
                                    }</p>
                                </div>
                            ))
                        ) : (
                            <p>Nenhuma matéria cadastrada.</p>
                        )}
                    </div>
                )}
                buttons={[
                    ...(accessLevel !== "low" ? [{
                        text: agendaData?.agendastatus ? "Ocultar Agenda" : "Liberar Agenda",
                        onClick: handleToggleStatus,
                        backgroundColor: agendaData?.agendastatus ? "#f39c12" : "green",
                        color: "#fff"
                    }] : []),
                    {
                        text: "Editar",
                        onClick: () => {
                            if (agendaData) onEditClick(agendaData);
                        },
                        backgroundColor: "#FFFFFF",
                        color: "var(--primary-color)"
                    },
                    {
                        text: "Excluir",
                        onClick: () => onDeleteClick(agendaData),
                        backgroundColor: "#FFFFFF",
                        color: "var(--primary-color)"
                    },
                    {
                        text: "Fechar",
                        onClick: onClose,
                        backgroundColor: "var(--primary-color)",
                        color: "#ffffff"
                    },
                ]}
                onClose={onClose}
            />


            {popupRegistrosAberto && (
                <PopUpComponent
                    isOpen={popupRegistrosAberto}
                    onClose={() => setPopupRegistrosAberto(false)}
                    title="Todos os Registros"
                >
                    <div className={styles.popupRegistros}>
                        <ul className={styles.listaRegistros}>
                            {Array.isArray(registros) && registros.length > 0 ? (() => {
                                const hoje = new Date();
                                const diaHoje = hoje.getDate().toString().padStart(2, "0");
                                const mesHoje = (hoje.getMonth() + 1).toString().padStart(2, "0");
                                const anoHoje = hoje.getFullYear().toString();

                                const registrosHoje = registros.filter(registro => {
                                    if (!registro.dataRegistro) return false;

                                    const [dia, mes, anoComHora] = registro.dataRegistro.split("/");
                                    const [ano] = anoComHora.split(" ");

                                    return dia === diaHoje && mes === mesHoje && ano === anoHoje;
                                });

                                const registrosAnteriores = registros.filter(registro => {
                                    if (!registro.dataRegistro) return false;

                                    const [dia, mes, anoComHora] = registro.dataRegistro.split("/");
                                    const [ano] = anoComHora.split(" ");

                                    return !(dia === diaHoje && mes === mesHoje && ano === anoHoje);
                                });

                                return (
                                    <>
                                        {registrosHoje.length > 0 && (
                                            <>
                                                <li className={styles.registroSeparador}>📅 Registros de Hoje</li>
                                                {registrosHoje.map((registro, index) => (
                                                    <li key={`hoje-${index}`} className={styles.registroItem} onClick={() => abrirDetalhesDoRegistro(registro._id)}>
                                                        <strong className={styles.registroNome}>{registro.usuarioNome}</strong> realizou <strong>{registro.acao}</strong><br />
                                                        <span className={styles.registroData}>{registro.dataRegistro}</span>
                                                    </li>
                                                ))}
                                            </>
                                        )}

                                        {registrosAnteriores.length > 0 && (
                                            <>
                                                <li className={styles.registroSeparador}>📂 Registros Anteriores</li>
                                                {registrosAnteriores.map((registro, index) => (
                                                    <li key={`anteriores-${index}`} className={styles.registroItem} onClick={() => abrirDetalhesDoRegistro(registro._id)}>
                                                        <strong className={styles.registroNome}>{registro.usuarioNome}</strong> realizou <strong>{registro.acao}</strong><br />
                                                        <span className={styles.registroData}>{registro.dataRegistro}</span>
                                                    </li>
                                                ))}
                                            </>
                                        )}

                                        {registrosHoje.length === 0 && registrosAnteriores.length === 0 && (
                                            <div className={styles.notFound}>
                                                <span className={styles.icon}><FaHistory /></span>
                                                <span className={styles.registroNome}>Nenhum registro encontrado</span>
                                            </div>
                                        )}
                                    </>
                                );
                            })() : (
                                <>
                                    <div className={styles.notFound}>
                                        <span className={styles.icon}><FaHistory /></span>
                                        <span className={styles.registroNome}>Nenhum registro encontrado</span>
                                    </div>
                                </>
                            )}
                        </ul>
                    </div>
                </PopUpComponent>
            )}

            {popupDetalhesAberto && registroSelecionado && (
                <PopUpComponent
                    isOpen={popupDetalhesAberto}
                    onClose={() => setPopupDetalhesAberto(false)}
                    title={`Detalhes da Alteração (${registroSelecionado.acao})`}
                >
                    <div style={{ padding: "1rem", fontFamily: "Arial, sans-serif", fontSize: "14px" }}>
                        {registroSelecionado.acao === "delete" ? (
                            <>
                                <h3 style={{ color: "#c0392b", marginBottom: "1rem" }}>Conteúdo excluído</h3>
                                {Object.entries(registroSelecionado.alteracoes || {}).map(([campo, valores], index) => (
                                    <div
                                        key={index}
                                        style={{
                                            marginBottom: "16px",
                                            padding: "12px",
                                            borderLeft: "4px solid #e74c3c",
                                            backgroundColor: "#fef1f1",
                                            borderRadius: "6px"
                                        }}
                                    >
                                        <h4 style={{ marginBottom: "8px", color: "#c0392b" }}>{campo}</h4>
                                        {Array.isArray(valores) ? (
                                            valores.map((item, i) => (
                                                <div key={i} style={{ marginBottom: '12px' }}>
                                                    <strong style={{ display: "block", marginBottom: "4px" }}>{item?.materia || `Item ${i + 1}`}</strong>
                                                    <p><b>Objetivo:</b> {item.objetivoConhecimento || "-"}</p>
                                                    <p><b>Habilidades:</b> {item.habilidades || "-"}</p>
                                                    <p><b>Metodologia:</b> {item.metodologia || "-"}</p>
                                                </div>
                                            ))
                                        ) : (
                                            <pre style={{ backgroundColor: "#f4f4f4", padding: "10px", borderRadius: "4px" }}>
                                                {JSON.stringify(valores, null, 2)}
                                            </pre>
                                        )}
                                    </div>
                                ))}
                            </>
                        ) : (
                            Object.entries(registroSelecionado.alteracoes || {}).map(([campo, valores], index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginBottom: "24px",
                                        padding: "12px",
                                        borderLeft: "4px solid #3498db",
                                        backgroundColor: "#f0f7ff",
                                        borderRadius: "2px"
                                    }}
                                >
                                    <h4 style={{ marginBottom: "10px", color: "#2980b9" }}>{campo}</h4>
                                    <div style={{ marginBottom: '16px', padding: "10px", backgroundColor: "#fff", borderRadius: "4px", boxShadow: "0 1px 2px rgba(0,0,0,0.05)", color: "var(--primary-color)" }}>
                                        <strong style={{ display: "block", marginBottom: "6px", color: "#2c3e50" }}>
                                            Data da Agenda:
                                        </strong>
                                        <strong style={{ display: "block", marginBottom: "6px", color: "#2c3e50", fontSize: "16px", color: "#3498db" }}>
                                            {dataDaAgenda}
                                        </strong>
                                    </div>
                                    {Array.isArray(valores.antes) && Array.isArray(valores.depois) ? (
                                        valores.antes.map((itemAntes, i) => {
                                            const itemDepois = valores.depois[i];
                                            return (
                                                <div key={i} style={{ marginBottom: '16px', padding: "10px", backgroundColor: "#fff", borderRadius: "4px", boxShadow: "0 1px 2px rgba(0,0,0,0.05)", color: "var(--primary-color)" }}>
                                                    <strong style={{ display: "block", marginBottom: "6px", color: "#2c3e50" }}>
                                                        {itemAntes?.materia || itemDepois?.materia || `Matéria ${i + 1}`}
                                                    </strong>
                                                    <p><b>Antes - Objetivo:</b> {itemAntes?.objetivoConhecimento || "-"}</p>
                                                    <p><b>Depois - Objetivo:</b> {itemDepois?.objetivoConhecimento || "-"}</p>
                                                    <p><b>Antes - Habilidades:</b> {itemAntes?.habilidades || "-"}</p>
                                                    <p><b>Depois - Habilidades:</b> {itemDepois?.habilidades || "-"}</p>
                                                    <p><b>Antes - Metodologia:</b> {itemAntes?.metodologia || "-"}</p>
                                                    <p><b>Depois - Metodologia:</b> {itemDepois?.metodologia || "-"}</p>
                                                    <p><b>Antes - Material:</b></p>
                                                    <div style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: itemAntes?.material || "<i>-</i>" }} />
                                                    <p><b>Depois - Material:</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: itemDepois?.material || "<i>-</i>" }} />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div style={{ paddingLeft: "10px" }}>
                                            <p><b>Antes:</b> {JSON.stringify(valores.antes)}</p>
                                            <p><b>Depois:</b> {JSON.stringify(valores.depois)}</p>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </PopUpComponent>
            )}
        </>
    );
};

export default AgendaPopups;
