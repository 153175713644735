import { backgrounds, size } from "polished";
import PopUp2Component from "../../shared/popUp/popUp2Component";
import PopUp3Component from "../../shared/popUp/popUp3Component";
import PopUp4Component from "../../shared/popUp/popUp4Component";
import { useState } from "react";

const PopupsCalendario = ({
    isPopupOpen, setIsPopupOpen, novoEvento = {}, handleInputChange, handleSalvarEvento,
    novaCategoria = {}, handleCategoriaChange, handleSalvarCategoria,
    isPopupVisualizacaoOpen, setIsPopupVisualizacaoOpen, eventoSelecionado = {},
    isPopupEdicaoOpen, setIsPopupEdicaoOpen, eventoEditando = {}, handleEditarEvento, handleSalvarEdicao,
    isPopupConfirmacaoOpen, setIsPopupConfirmacaoOpen, eventoParaDeletar = {}, handleDeletarEvento, confirmarDelecao,
    categorias = [],
    accessLevel,
    isPopupGerenciarCategoriaOpen, setIsPopupGerenciarCategoriaOpen, // 🔹 Adicionado
    isPopupCriarCategoriaOpen, setIsPopupCriarCategoriaOpen // 🔹 Adicionado
}) => {

    const [selectedCategories, setSelectedCategories] = useState([]);

    // Alterna a seleção dos itens
    const handleCategorySelection = (catId) => {
        setSelectedCategories((prevSelected) => {
            const alreadySelected = prevSelected.includes(catId);
            if (alreadySelected) {
                return prevSelected.filter(id => id !== catId);
            } else {
                return [...prevSelected, catId];
            }
        });
    };


    return (
        <>
            <PopUp2Component
                isOpen={isPopupOpen}
                title="Criar Evento"
                onClose={() => setIsPopupOpen(false)}
                inputs={[
                    { label: "Título", type: "text", name: "Titulo", value: novoEvento.Titulo || "", onChange: handleInputChange },
                    { label: "Descrição", type: "text", name: "descricao", value: novoEvento.descricao || "", onChange: handleInputChange },
                    { label: "Data", type: "date", name: "date", value: novoEvento.date || "", onChange: handleInputChange },
                    { label: "Hora", type: "time", name: "time", value: novoEvento.time || "00:00", onChange: handleInputChange },
                    {
                        label: "Categoria",
                        type: "select",
                        name: "categoria",
                        value: novoEvento.categoria || "",
                        onChange: handleInputChange,
                        options: categorias.length > 0
                            ? categorias.map(cat => ({
                                label: cat.category?.name || "Sem Nome",
                                value: cat._id
                            }))
                            : [{ label: "Nenhuma categoria disponível", value: "" }]
                    }
                ]}
                buttons={[
                    { text: "Cancelar", onClick: () => setIsPopupOpen(false) },
                    { text: "Salvar", onClick: handleSalvarEvento }
                ]}
            />

            {/* Popup de Visualização de Evento (Agora com botões de Editar e Excluir) */}
            <PopUp3Component
                isOpen={isPopupVisualizacaoOpen}
                title="Detalhes do Evento"
                description="Veja as informações do evento selecionado."
                onClose={() => setIsPopupVisualizacaoOpen(false)}
                data={{
                    "Título": eventoSelecionado?.Titulo || "Sem título",
                    "Descrição": eventoSelecionado?.descricao || "Sem descrição",
                    "Data": eventoSelecionado?.dataFormatada || "Sem data",
                    "Hora": eventoSelecionado?.horaFormatada || "00:00",
                    "Categoria": eventoSelecionado?.categoriaName || "Sem categoria"
                }}
                buttons={[
                    ...(accessLevel === "high"
                        ? [{ text: "Excluir", onClick: () => handleDeletarEvento(eventoSelecionado), backgroundColor: "white", color: "var(--primary-color)" }]
                        : []),
                    ...(accessLevel === "high" || accessLevel === "medium"
                        ? [{ text: "Editar", onClick: () => handleEditarEvento(eventoSelecionado), backgroundColor: "white", color: "var(--primary-color)" }]
                        : []),
                    { text: "Fechar", onClick: () => setIsPopupVisualizacaoOpen(false) }
                ]}
            />

            <PopUp2Component
                isOpen={isPopupEdicaoOpen}
                title="Editar Evento"
                onClose={() => setIsPopupEdicaoOpen(false)}
                inputs={[
                    { label: "Título", type: "text", name: "Titulo", value: eventoEditando?.Titulo || "", onChange: handleInputChange },
                    { label: "Descrição", type: "text", name: "descricao", value: eventoEditando?.descricao || "", onChange: handleInputChange },
                    { label: "Data", type: "date", name: "date", value: eventoEditando?.date || "", onChange: handleInputChange },
                    { label: "Hora", type: "time", name: "time", value: eventoEditando?.time || "00:00", onChange: handleInputChange },
                    {
                        label: "Categoria",
                        type: "select",
                        name: "categoria",
                        value: eventoEditando?.categoria || "",
                        onChange: handleInputChange,
                        options: categorias.length > 0
                            ? categorias.map(cat => ({ label: cat.category?.name || "Sem Nome", value: cat._id }))
                            : [{ label: "Nenhuma categoria disponível", value: "" }]
                    }
                ]}
                buttons={[
                    { text: "Cancelar", onClick: () => setIsPopupEdicaoOpen(false), backgroundColor: "white", color: "var(--primary-color)" },
                    { text: "Salvar Alterações", onClick: handleSalvarEdicao }
                ]}
            />

            {/* Popup de Confirmação para Deletar */}
            <PopUp4Component
                isOpen={isPopupConfirmacaoOpen}
                title="Confirmação de Exclusão"
                message={`Tem certeza que deseja excluir o evento "${eventoParaDeletar?.title || "Desconhecido"}"? Digite "DELETAR" para confirmar.`}
                onConfirm={confirmarDelecao}
                onCancel={() => setIsPopupConfirmacaoOpen(false)}
            />

            <PopUp3Component
                isOpen={isPopupGerenciarCategoriaOpen}
                title="Gerenciar Categorias"
                description="Edite, exclua ou crie novas categorias para o calendário."
                onClose={() => setIsPopupGerenciarCategoriaOpen(false)}
                data={categorias.map(cat => (
                    <div key={cat._id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <input
                            type="checkbox"
                            onChange={() => handleCategorySelection(cat._id)}
                            checked={selectedCategories.includes(cat._id)}
                        />
                        <span>{cat?.category?.name || "Sem Nome"} - Cor: {cat?.category?.color || "#000000"}</span>
                    </div>
                ))}
                buttons={[
                    ...(selectedCategories.length === 1 ? [{
                        text: "Editar",
                        onClick: () => console.log("Editar categoria:", selectedCategories[0]),
                        backgroundColor: "white",
                        size:"small",
                        color: "var(--primary-color)"
                    }] : []),
                    ...(selectedCategories.length > 0 ? [{
                        text: "Excluir",
                        onClick: () => console.log("Excluir categorias:", selectedCategories),
                        backgroundColor: "white",
                        size:"small",
                        color: "var(--primary-color)"
                    }] : []),
                    {
                        text: "Criar Nova",
                        onClick: () => {
                            setIsPopupGerenciarCategoriaOpen(false);
                            setIsPopupCriarCategoriaOpen(true);
                        },
                        backgroundColor: "white",
                        size:"small",
                        color: "var(--primary-color)"
                    },
                    { text: "Fechar", onClick: () => setIsPopupGerenciarCategoriaOpen(false),size:"small", }
                ]}
            />

            <PopUp2Component
                isOpen={isPopupCriarCategoriaOpen}
                title="Nova Categoria"
                onClose={() => setIsPopupCriarCategoriaOpen(false)}
                inputs={[
                    { label: "Nome da Categoria", type: "text", name: "name", value: novaCategoria.name || "", onChange: handleCategoriaChange },
                    { label: "Cor", type: "color", name: "color", value: novaCategoria.color || "#000000", onChange: handleCategoriaChange }
                ]}
                buttons={[
                    { text: "Cancelar", onClick: () => setIsPopupCriarCategoriaOpen(false) },
                    { text: "Salvar", onClick: handleSalvarCategoria }
                ]}
            />

        </>
    );
};

export default PopupsCalendario;
