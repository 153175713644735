import React from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import styles from "./CustomInput.module.css";

const CustomInput = ({
  label,
  type = "text",
  name = "", // 🔥 Garante que name sempre tenha um valor
  value = "", // 🔥 Garante que o value nunca seja undefined
  onChange = () => {}, // 🔥 Evita erro caso onChange não seja passado
  options = [],
  placeholder = "",
  size = "medium",
  disabled = false,
  loading = false,
  icon: Icon,
  fullWidth = false,
  multiline = false,
  rows = 3,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (onChange) {
      onChange({ target: { name, value } }); // 🔥 Garante que a estrutura do evento seja mantida
    }
  };

  const renderInput = () => {
    switch (type) {
      case "select":
        return (
          <TextField
            select
            label={label}
            name={name} // 🔥 Adiciona o nome do campo corretamente
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth={fullWidth}
            disabled={disabled}
            size={size}
            SelectProps={{
              MenuProps: {
                disablePortal: true, // 🔥 Mantém o menu dentro do popup
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );

      case "textarea":
        return (
          <TextField
            label={label}
            name={name} // 🔥 Adiciona o nome do campo corretamente
            type="text"
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            variant="outlined"
            fullWidth={fullWidth}
            disabled={disabled}
            size={size}
            multiline
            rows={rows}
          />
        );

      case "date":
        return (
          <TextField
            label={label}
            name={name}
            type="date"
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth={fullWidth}
            disabled={disabled}
            size={size}
            InputLabelProps={{
              shrink: true, // 🔥 Garante que o label não sobreponha o input
            }}
          />
        );

      case "time":
        return (
          <TextField
            label={label}
            name={name}
            type="time"
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth={fullWidth}
            disabled={disabled}
            size={size}
            InputLabelProps={{
              shrink: true, // 🔥 Garante que o label não sobreponha o input
            }}
          />
        );

      default:
        return (
          <TextField
            label={label}
            name={name} // 🔥 Adiciona o nome do campo corretamente
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            variant="outlined"
            fullWidth={fullWidth}
            disabled={disabled}
            size={size}
          />
        );
    }
  };

  return (
    <div className={`${styles.inputContainer} ${styles[size]}`} style={{ width: fullWidth ? "100%" : "auto" }}>
      {loading ? (
        <CircularProgress size={24} className={styles.loadingIndicator} />
      ) : (
        <>
          {Icon && <Icon className={styles.icon} />}
          {renderInput()}
        </>
      )}
    </div>
  );
};

export default CustomInput;
