import React, { useState } from 'react';
import InterfaceStructure from '../../../components/shared/InterfaceStructure';
import { MdCalendarToday, MdKeyboardArrowLeft, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { LuApple } from 'react-icons/lu';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../hooks/getTenantId';
import styles from './CalendarioSecretariaCategoryCreatePage.module.css';
import { PiPencilSimpleLineBold } from 'react-icons/pi';
import { RiMegaphoneLine } from 'react-icons/ri';
import { TfiAgenda } from 'react-icons/tfi';
import GetTenantId from '../../../hooks/getTenantId';

const CalendarioSecretariaCategoryCreatePage = () => {
    const [nome, setNome] = useState('');
    const [cor, setCor] = useState('#000000'); // Cor padrão preta
    const tenantId = GetTenantId();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const novaCategoria = {
            tenantId: tenantId,
            category: {
                name: nome,
                color: cor,
            },
        };

        try {
            const response = await apiAxios.post('/api/calendar/category', novaCategoria);
            console.log(response);
            navigate('/calendario');
        } catch (error) {
            console.error('Erro ao criar categoria:', error);
        }
    };

    const itemsPages = [
        {
            id: 1,
            name: "Painel",
            icon: <MdOutlineSpaceDashboard />,
            isSelect: false,
            link: "/"
        },
        {
            id: 2,
            name: "Agenda",
            icon: <TfiAgenda />,
            isSelect: false,
            link: "/agenda"
        },
        {
            id: 3,
            name: "Planos",
            icon: <MdOutlineClass />,
            isSelect: false,
            link: "/planos"
        },
        {
            id: 4,
            name: "Calendário",
            icon: <MdCalendarToday />,
            isSelect: false,
            link: "/calendario"
        },
        {
            id: 5,
            name: "Cardápio",
            icon: <LuApple />,
            isSelect: false,
            link: "/cardapio"
        },
        {
            id: 6,
            name: "Informes",
            icon: <RiMegaphoneLine />,
            isSelect: false,
            link: "/informes"
        },
        {
            id: 7,
            name: "Atividades",
            icon: <PiPencilSimpleLineBold />,
            isSelect: false,
            link: "/atividades"
        },
        { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/calendario'><MdKeyboardArrowLeft />Calendário</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Criar Categoria</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.BasicInfoForm}>
                        <p>Informações Básicas da Categoria</p>
                        <input
                            type="text"
                            placeholder="Nome da Categoria"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                        />
                        <input
                            type="color"
                            value={cor}
                            onChange={(e) => setCor(e.target.value)}
                            className={styles.color}
                            required
                        />
                        <div className={styles.botoesDeControle}>
                            <button type="submit">Criar Categoria</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <>
            <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
        </>
    );
};

export default CalendarioSecretariaCategoryCreatePage;
