import React, { useState, useEffect } from 'react';
import styles from './MateriasComponent.module.css';
import { MdDeleteOutline, MdEdit, MdKeyboardArrowLeft } from "react-icons/md";
import InterfaceStructure from '../../shared/InterfaceStructure';
import { Link } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import { series } from '../../../DataSeries';
import { IoIosAddCircleOutline } from 'react-icons/io';
import img from '../../../assets/notFound/OIP.jpg';
import PopUpComponent from '../../shared/popUp/PopUpComponent';
import MateriasCreateComponent from './MateriasCreateComponent';
import MateriasEditComponent from './MateriasEditComponent';
import Loading from '../../Loading/Loading';
import BasicPageStructure from '../../shared/BasicPageStructure';
import GetTenantId from '../../../hooks/getTenantId';

const MateriasComponent = ({ returnLink, returnLinkName, itemsPages }) => {
    const [allMaterias, setAllMaterias] = useState();
    const [materias, setMaterias] = useState([]);
    const [filteredMaterias, setFilteredMaterias] = useState([]);
    const [selectedSerie, setSelectedSerie] = useState('1º ano');
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        materias: [{
            materia: '',
            description: '',
            color: '#000000',
        }]
    });
    const [editData, setEditData] = useState(null);
    const tenantId = GetTenantId();
    const itemsPagesList = itemsPages;

    useEffect(() => {
        const fetchAllMaterias = async () => {
            setLoading(true);
            try {
                if (!tenantId) {
                    console.error('tenantId or selectedSerie is missing');
                    return;
                }
                const response = await apiAxios.get(`/api/mat/materias/full?tenantId=${tenantId}`);
                setAllMaterias(response.data);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setAllMaterias([]);
                } else {
                    console.error('Erro ao buscar matérias:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchAllMaterias();
    }, [tenantId]);

    useEffect(() => {
        const fetchMaterias = async () => {
            setLoading(true);
            try {
                if (!tenantId || !selectedSerie) {
                    console.error('tenantId or selectedSerie is missing');
                    return;
                }
                const response = await apiAxios.get(`/api/mat/materias?tenantId=${tenantId}&serie=${selectedSerie}`);
                const allMaterias = response.data;
                const filtered = allMaterias.filter(materia => materia.serie === selectedSerie);
                setMaterias(allMaterias);
                setFilteredMaterias(filtered);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setMaterias([]);
                    setFilteredMaterias([]);
                } else {
                    console.error('Erro ao buscar matérias:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMaterias();
    }, [tenantId, selectedSerie]);

    const handleSerieChange = (event) => {
        setSelectedSerie(event.target.value);
    };

    const handleOpenCreateModal = () => setOpenCreateModal(true);

    const handleCloseCreateModal = () => {
        setOpenCreateModal(false);
        setFormData({
            materias: [{
                materia: '',
                description: '',
                color: '#000000',
            }]
        });
    };

    const handleOpenEditModal = (materia) => {
        setEditData(materia);
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditData(null);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const newMateria = {
                tenantId,
                materia: formData.materias.map(materia => ({
                    materia: materia.materia,
                    description: materia.description,
                    color: materia.color,
                })),
                serie: selectedSerie,
            };
            const response = await apiAxios.post('/api/mat/materias', newMateria);
            setMaterias([...materias, response.data]);
            setFilteredMaterias([...filteredMaterias, response.data]);
            handleCloseCreateModal();
        } catch (error) {
            console.error('Erro ao adicionar matérias:', error);
        }
    };

    const handleEditSubmit = async (updatedMateria) => {
        try {
            const updatedMateriaData = {
                tenantId,
                materia: updatedMateria.materia,
                serie: updatedMateria.serie
            };

            const response = await apiAxios.patch(`/api/mat/materias/${updatedMateria._id}`, updatedMateriaData);
            const updatedData = response.data;

            const updatedMateriasList = materias.map(materia =>
                materia._id === updatedData._id ? updatedData : materia
            );
            setMaterias(updatedMateriasList);
            setFilteredMaterias(updatedMateriasList.filter(materia => materia.serie === selectedSerie));

            handleCloseEditModal();
        } catch (error) {
            console.error('Erro ao atualizar matéria:', error);
        }
    };

    const handleDeleteMateria = async (id) => {
        try {
            await apiAxios.delete(`/api/mat/materias/${id}`);
            const updatedMaterias = materias.filter(materia => materia._id !== id);
            setMaterias(updatedMaterias);
            setFilteredMaterias(updatedMaterias.filter(materia => materia.serie === selectedSerie));
        } catch (error) {
            console.error('Erro ao deletar matéria:', error);
        }
    };

    const dashboardContent = (
        <>
            <div className={styles.seriesList}>
                <div>
                    <label>Selecione a Série: </label>
                    <select value={selectedSerie} onChange={handleSerieChange} className={styles.selectSerie}>
                        {series.map((serie, index) => (
                            <option key={index} value={serie}>{serie}</option>
                        ))}
                    </select>
                </div>
            </div>
            {loading ? (
                <Loading />
            ) : (
                filteredMaterias.length === 0 ? (
                    <div className={styles.notFound}>
                        <p>Nenhuma Matéria Encontrada!</p>
                        <img src={img} alt='Nenhuma Matéria Encontrada' />
                        <button className={styles.addButton} onClick={handleOpenCreateModal}>
                            <IoIosAddCircleOutline /> Adicionar Matéria
                        </button>
                    </div>
                ) : (
                    <div className={styles.materiasList}>
                        {filteredMaterias.map((materiaItem) => (
                            <div key={materiaItem._id} className={styles.materiaCard}>
                                <div className={styles.actionsList}>
                                    <button type="button" className={styles.updateButton} onClick={() => handleOpenEditModal(materiaItem)}>
                                        <MdEdit />
                                    </button>
                                    <button type="button" className={styles.removeButton} onClick={() => handleDeleteMateria(materiaItem._id)}>
                                        <MdDeleteOutline />
                                    </button>
                                </div>
                                {materiaItem.materia.map((item, index) => (
                                    <div className={styles.card} key={item._id || index}>
                                        <div className={styles.right}>
                                            <div className={styles.colorBoll} style={{ background: item.color }}></div>
                                            <h2>{item.materia}</h2>
                                            <p>({item.description})</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )
            )}
            <PopUpComponent
                title="Adicionar Matéria"
                isOpen={openCreateModal}
                onClose={handleCloseCreateModal}
            >
                <MateriasCreateComponent
                    formData={formData}
                    setFormData={setFormData}
                    handleFormSubmit={handleFormSubmit}
                    selectedSerie={selectedSerie}
                    setSelectedSerie={setSelectedSerie}
                    seriesDisponiveis={series}
                />
            </PopUpComponent>
            {editData && (
                <PopUpComponent
                    title="Editar Matéria"
                    isOpen={openEditModal}
                    onClose={handleCloseEditModal}
                >
                    <MateriasEditComponent
                        materia={editData}
                        handleEditSubmit={handleEditSubmit}
                        handleCloseModal={handleCloseEditModal}
                        seriesDisponiveis={series}
                        tenantId={tenantId}
                    />
                </PopUpComponent>
            )}
        </>
    );

    const buttons = [
    ];

    return (
        <InterfaceStructure
            itemsPages={itemsPagesList}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={true}
                    returnPath={returnLink}
                    returnLabel={returnLinkName}
                    namePage="Materias"
                    accessType='administrador'
                />
            }
        />
    );
};

export default MateriasComponent;
