import React from 'react';
import { useParams } from 'react-router-dom';
import useTenantId from '../../../../hooks/getTenantId';
import FichaReceivedComponent from '../../../../components/pagesComponents/fichasComponents/responseComponent/FichaReceivedComponent';
import GetTenantId from '../../../../hooks/getTenantId';

const FichaSecretariaReceivedPage = () => {
    const tenantId = GetTenantId(); // Obtém tenantId usando o hook
    const { formId } = useParams(); // Obtém o ID do formulário da URL

    return (
        <FichaReceivedComponent formId={formId} tenantIdProp={tenantId} />
    );
};

export default FichaSecretariaReceivedPage;
