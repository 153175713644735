import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import moment from 'moment';
import styles from './CalendarioStructure.module.css';
import DefaultButton from '../buttons/DefaultButton';
import IconButton from '../buttons/IconButton';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CalendarioStructure = ({ eventos, botoes = [], onEventClick }) => {
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(window.innerWidth < 768 ? 'timeGridWeek' : 'dayGridMonth');

  useEffect(() => {
    const handleResize = () => {
      setView(window.innerWidth < 768 ? 'timeGridWeek' : 'dayGridMonth');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigate = (direction) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      if (direction === 'prev') {
        calendarApi.prev();
      } else if (direction === 'next') {
        calendarApi.next();
      } else {
        calendarApi.today();
      }

      // Apenas atualizar o estado com a nova data sem modificar manualmente o mês
      setDate(moment(calendarApi.getDate()).toDate());
    }
  };

  const handleViewChange = (newView) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  return (
    <div className={styles.calendarioContainer}>
      <div className={styles.headerControls}>
        <div className={styles.headerContainer}>
          <div className={styles.leftControls}>
            <DefaultButton
              text="Hoje"
              onClick={() => handleNavigate('today')}
              size="medium"
              backgroundColor="#ffffff"
              borderColor="#d1d5db"
              color="#374151"
            />
            <div className={styles.arrowSection}>
              <IconButton
                icon={ChevronLeft}
                onClick={() => handleNavigate('prev')}
                size="medium"
                backgroundColor="#ffffff"
                borderColor="#d1d5db"
                color="#374151"
              />
              <h3 className={styles.mesAno}>
                {moment(date).format('MMMM YYYY').charAt(0).toUpperCase() + moment(date).format('MMMM YYYY').slice(1)}
              </h3>
              <IconButton
                icon={ChevronRight}
                onClick={() => handleNavigate('next')}
                size="medium"
                backgroundColor="#ffffff"
                borderColor="#d1d5db"
                color="#374151"
              />
            </div>
          </div>

          <div className={styles.rightControls}>
            <select onChange={(e) => handleViewChange(e.target.value)} value={view} className={styles.viewSelector}>
              <option value="dayGridMonth">Mês</option>
              <option value="timeGridWeek">Semana</option>
              <option value="timeGridDay">Dia</option>
              <option value="listMonth">Agenda</option>
            </select>

            {botoes.map((botao, index) => (
              <DefaultButton
                key={index}
                text={botao.text}
                icon={botao.icon}
                size={botao.size || "small"}
                backgroundColor={botao.backgroundColor || "#1f2937"}
                borderColor={botao.borderColor || "#1f2937"}
                color={botao.color || "#ffffff"}
                onClick={botao.onClick}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.calendarioContent}>
        <FullCalendar
          ref={calendarRef}
          locale={ptLocale}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView={view}
          headerToolbar={false}
          width="max-content"
          height="100vh"
          events={eventos}
          editable={true}
          selectable={true}
          dayMaxEventRows={true}

          /* Clique em um evento -> Abre Popup */
          eventClick={(info) => {
            console.log("Evento clicado:", info.event); // Depuração
            if (onEventClick) {
              onEventClick({
                id: info.event.id,
                title: info.event.title,
                descricao: info.event.extendedProps.descricao,
                dataFormatada: moment(info.event.start).format("DD/MM/YYYY"),
                categoriaName: info.event.extendedProps.categoriaName,
              });
            }
          }}

          /* Clique em um dia -> Abre o calendário diário */
          dateClick={(info) => {
            console.log("Data clicada:", info.dateStr); // Depuração
            if (calendarRef.current) {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('timeGridDay', info.dateStr);
              setDate(new Date(info.dateStr));
            }
          }}
        />
      </div>
    </div>
  );
};

export default CalendarioStructure;
