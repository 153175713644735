import React, { useState, useEffect } from 'react';
import apiAxios from '../api/apiAxios';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import styles from './fichaReponsePage.module.css';
import { getTenants } from '../actions/UserActions';
import Loading from '../components/Loading/Loading';
import Confetti from 'react-confetti';
import { useSnackbar } from 'notistack';

const FichaResponsePage = () => {
    const { formId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState(null);
    const [tenants, setTenants] = useState([]);
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const pass = process.env.REACT_APP_FICHA_RESPONSE_PASS;
    const secretKey = pass;
    const [tenantId, setTenantId] = useState(null);
    const [responseCount, setResponseCount] = useState(0);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // Inicia com falso

    const decryptId = (encryptedId) => {
        const restored = encryptedId.replace(/_/g, '/').replace(/-/g, '+');
        const bytes = CryptoJS.AES.decrypt(restored, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
        const fetchFormAndResponses = async () => {
            try {
                const id = decryptId(formId);
                const formResponse = await apiAxios.get(`/api/forms/${id}`);
                setForm(formResponse.data);
                setTenantId(formResponse?.data?.tenantId);

                if (formResponse.data.PublicCampaign) {
                    const responsesResponse = await apiAxios.get(`/api/formresponse/form/${id}`);
                    const count = responsesResponse.data.length;
                    setResponseCount(count);
                    setIsSubmitDisabled(count >= formResponse.data.CampaignLimit);
                }
            } catch (error) {
                console.error('Erro ao buscar formulário ou respostas:', error);
                enqueueSnackbar('Erro ao buscar formulário ou respostas. Por favor, tente novamente.', { variant: 'error' });
            }
        };

        fetchFormAndResponses();
    }, [formId, tenantId]);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                if (form?.tenantId) {
                    const response = await getTenants();
                    const foundTenant = response.find(tenant => tenant?._id === form.tenantId);
                    setTenants(foundTenant ? [foundTenant] : []);
                }
            } catch (error) {
                console.error('Erro ao buscar inquilinos:', error);
                enqueueSnackbar('Erro ao buscar inquilinos. Por favor, tente novamente.', { variant: 'error' });
            }
        };

        fetchTenants();
    }, [form]);

    useEffect(() => {
        if (showConfetti) {
            const timer = setTimeout(() => setShowConfetti(false), 10000);
            return () => clearTimeout(timer);
        }
    }, [showConfetti]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnswers({ ...answers, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitDisabled) {
            enqueueSnackbar('Limite de respostas atingido. Não é possível enviar mais respostas.', { variant: 'warning' });
            return;
        }

        // Desabilitar o botão ao submeter
        setIsSubmitDisabled(true);

        if (!form?._id || !tenantId || !Object.keys(answers).length) {
            enqueueSnackbar('Todos os campos são obrigatórios.', { variant: 'error' });
            setIsSubmitDisabled(false); // Reabilitar se houver erro
            return;
        }

        try {
            const response = await apiAxios.post('/api/formresponse', {
                form: form._id,
                answers: Object.entries(answers).map(([fieldId, answer]) => ({ fieldId, answer })),
                tenantId: tenantId
            });

            if (response.status === 201 || response.data != null) {
                setSubmitted(true);
                setShowConfetti(true);
                enqueueSnackbar('Resposta enviada com sucesso!', { variant: 'success' });
            }
        } catch (error) {
            console.error('Erro ao enviar resposta:', error);
            enqueueSnackbar('Erro ao enviar resposta. Por favor, tente novamente.', { variant: 'error' });
            setIsSubmitDisabled(false); // Reabilitar o botão em caso de erro
        }
    };

    if (submitted) {
        return (
            <div className={styles.all}>
                <div className={styles.content}>
                    <div style={{ margin: "15px" }}>
                        <Confetti
                            width={windowSize.width}
                            height={windowSize.height}
                            recycle={false}
                            numberOfPieces={200}
                        />
                        <h1>Sucesso!</h1>
                        <p>Sua resposta foi enviada com sucesso.🎊🎊</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        form ? (
            <div className={styles.all}>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        {tenants.map((tenant) => (
                            tenant ? (
                                <img key={tenant._id} src={tenant.imageUrl} alt='logo' />
                            ) : null
                        ))}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.TopForm}>
                            <h1>{form.title}</h1>
                            {form.PublicCampaign && (
                                <p>Limite de envios {responseCount}/{form.CampaignLimit}.</p>
                            )}
                            <p>{form.description}</p>
                        </div>
                        {form.fields.map((field) => (
                            <div className={styles.group} key={field._id}>
                                <label>{field.name}</label>
                                <input
                                    type="text"
                                    name={field._id}
                                    placeholder={field.placeholder}
                                    onChange={handleChange}
                                />
                            </div>
                        ))}
                        <button type="submit" disabled={isSubmitDisabled}>Enviar Resposta</button>
                    </form>
                </div>
            </div>
        ) : (
            <Loading />
        )
    );
};

export default FichaResponsePage;
