import React from 'react';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import RequestsComponent from '../../components/pagesComponents/UsersComponents/requests/RequestsComponent';

const RequestsCoordenacao= () => {
  const itemsPagesList = itemsPagesWithExclusions('', ['agenda','Informes','Atividades','Chat','Cardápio','Banco de horas']);

  return (
    <RequestsComponent itemsPages={itemsPagesList}/>
  );
};

export default RequestsCoordenacao;
