import React from 'react'
import FrequenciaComponent from '../../../components/pagesComponents/FrequenciaComponents/FrequenciaComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const FrequenciaCoordenacaoPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Frequência', ['agenda','Informes','Atividades','Chat','Cardápio','Banco de horas']);
  return (
    <>
      <FrequenciaComponent itemsPages={itemsPagesList} accessLevel={"high"} accessName={"administrador"}/>
    </>
  )
}

export default FrequenciaCoordenacaoPage