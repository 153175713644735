import { useState, useEffect } from "react";
import useAgendas from "../../../hooks/agenda/useAgenda";
import GetTenantId from "../../../hooks/getTenantId";
import { filterTurnosBySerie } from "../../../utils/filterTurnosBySerie";
import { series, turno } from "../../../DataSeries";
import useRegistroAgenda from "../../../hooks/agenda/useRegistroAgenda";
import { useSnackbar } from "notistack";

const useAgendaState = (allowSeries = series, allowTurnos = turno, accessLevel) => {
    const tenantId = GetTenantId();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAgenda, setSelectedAgenda] = useState(null);

    // Estados para filtros
    const [filterSerie, setFilterSerie] = useState("");
    const [filterTurno, setFilterTurno] = useState("");
    const [filteredAgendas, setFilteredAgendas] = useState([]);

    // Estados para criação e edição
    const [isCreating, setIsCreating] = useState(false);
    const [editingAgenda, setEditingAgenda] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { agendas, loading, updateAgenda, createAgenda, deleteAgenda, toggleAgendaStatus } = useAgendas(tenantId, filterSerie, filterTurno);
    const { registros, ultimosRegistros, getRegistroDetalhado } = useRegistroAgenda(tenantId, filterSerie, filterTurno);

    const [popupRegistrosAberto, setPopupRegistrosAberto] = useState(false);

    const [registroSelecionado, setRegistroSelecionado] = useState(null);
    const [popupDetalhesAberto, setPopupDetalhesAberto] = useState(false);

    const [lastAgendaOpenedId, setLastAgendaOpenedId] = useState(null);
    const [materiasAgenda, setMateriasAgenda] = useState([]);
    const [materiasFaltando, setMateriasFaltando] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [savedImages, setSavedImages] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const abrirDetalhesDoRegistro = async (idRegistro) => {
        const detalhes = await getRegistroDetalhado(idRegistro);
        setRegistroSelecionado(detalhes);
        setPopupDetalhesAberto(true);
    };

    // Se for "high", sempre usa todas as séries e turnos
    if (accessLevel === "high") {
        allowSeries = series;
        allowTurnos = turno;
    } else {
        allowSeries = Array.isArray(allowSeries) && allowSeries.length > 0 ? allowSeries : series;
        allowTurnos = Array.isArray(allowTurnos) && allowTurnos.length > 0 ? allowTurnos : turno;
    }

    const seriesTurnoMap = allowSeries.reduce((acc, serie, index) => {
        if (!acc[serie]) acc[serie] = []; // Inicializa o array de turnos

        if (accessLevel === "high") {
            acc[serie] = turno; // Se for "high", todos os turnos disponíveis
        } else {
            acc[serie] = acc[serie].concat(allowTurnos[index] || []).filter((t, i, arr) => arr.indexOf(t) === i);
            // 🔥 Adiciona turnos sem repetir
        }
        return acc;
    }, {});

    // Atualiza as agendas filtradas
    useEffect(() => {
        if (!filterSerie || !filterTurno) {
            setFilteredAgendas([]);
            return;
        }
        const safeToLower = (str) => (str ?? "").toLowerCase();

        const filtered = agendas.filter(agenda =>
            safeToLower(agenda.serie) === safeToLower(filterSerie) &&
            safeToLower(agenda.turno) === safeToLower(filterTurno)
        );

        setFilteredAgendas(filtered);
    }, [agendas, filterSerie, filterTurno]);

    // Define valores iniciais ao carregar o componente
    useEffect(() => {
        if (allowSeries.length > 0) {
            const defaultSerie = allowSeries[0];
            setFilterSerie(defaultSerie);

            const turnosDisponiveis = filterTurnosBySerie(defaultSerie, seriesTurnoMap);
            setFilterTurno(turnosDisponiveis.length > 0 ? turnosDisponiveis[0] : "");
        }
    }, [allowSeries, allowTurnos]);

    const getSeriesOptions = () => allowSeries.map(serie => ({ value: serie, label: serie }));
    const getTurnosOptions = () => {
        if (!filterSerie || !seriesTurnoMap[filterSerie]) return [];
        return seriesTurnoMap[filterSerie].map(t => ({ value: t, label: t }));
    };

    const handleSerieChange = (value) => {
        setFilterSerie(value);
        const turnosDisponiveis = filterTurnosBySerie(value, seriesTurnoMap) || [];
        setFilterTurno(turnosDisponiveis.length > 0 ? turnosDisponiveis[0] : "");
    };

    const handleTurnoChange = (value) => setFilterTurno(value);

    // Atualiza as agendas filtradas
    useEffect(() => {
        setSelectedAgenda(null);
    }, [agendas]);

    const handleAgendaClick = (agenda) => {

        setSelectedAgenda(agenda);
        setIsCreating(false);
        setIsEditing(false);
        setIsDeleting(false);
        setModalOpen(true);
    };

    const openCreateAgendaModal = () => {
        setSelectedAgenda({
            date: selectedDate.toISOString().split("T")[0],
            serie: filterSerie,
            turno: filterTurno,
        });

        setIsCreating(true);
        setIsEditing(false);
        setIsDeleting(false);
        setModalOpen(true);
    };

    const openEditAgendaModal = (agenda) => {
        if (agenda.agendastatus === true && accessLevel === "low") {
            enqueueSnackbar("Agenda já liberada. Você não pode mais editar.", {
                variant: "warning",
                autoHideDuration: 4000
            });
            return;
        }

        if (!agenda || !agenda._id) {
            console.warn("⚠️ Agenda inválida passada para edição:", agenda);
            return;
        }
    
        if (agenda._id !== lastAgendaOpenedId) {
            setMateriasAgenda([]);
            setMateriasFaltando([]);
            setSelectedImages([]);
            setSavedImages([]);
            setImagesToDelete([]);
        }
    
        let date = agenda.date;
    
        if (typeof date === "string" && date.includes("/")) {
            const [day, month, yearWithTime] = date.split("/");
            const year = yearWithTime.split(" ")[0];
            date = `${year}-${month}-${day}`;
        }
    
        const formattedAgenda = {
            ...agenda,
            date,
            materias: agenda.materias || [],
        };
    
        setSelectedAgenda(formattedAgenda);
        setLastAgendaOpenedId(agenda._id);
        setIsEditing(true);
        setIsDeleting(false);
        setIsCreating(false);
        setModalOpen(true);
    };
    
    const openDeleteAgendaModal = (agenda) => {
        if (agenda.agendastatus === true && accessLevel === "low") {
            enqueueSnackbar("Agenda já liberada. Você não pode excluir.", {
                variant: "warning",
                autoHideDuration: 4000
            });
            return;
        }
        setSelectedAgenda({ ...agenda, isDeleting: true });
        setIsEditing(false);
        setIsDeleting(true);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setIsCreating(false);
        setIsEditing(false);
        setIsDeleting(false);
        setSelectedAgenda(null);
    };


    return {
        agendas,
        loading,
        selectedDate,
        handleDateSelect: setSelectedDate,
        handleAgendaClick,  // 🔥 Agora corretamente atribuído
        modalOpen,
        selectedAgenda,
        handleCloseModal: () => {
            setModalOpen(false);
            setSelectedAgenda(null);
        },
        filteredAgendas,
        filterSerie,
        filterTurno,
        setFilterSerie,
        setFilterTurno,
        getSeriesOptions,
        getTurnosOptions,
        handleSerieChange,
        handleTurnoChange,
        handleCloseModal,
        openCreateAgendaModal,
        openEditAgendaModal,
        openDeleteAgendaModal,
        isCreating,
        isEditing,
        isDeleting,
        updateAgenda,
        createAgenda,
        deleteAgenda,
        toggleAgendaStatus,
        registros,
        ultimosRegistros,
        popupRegistrosAberto,
        setPopupRegistrosAberto,
        getRegistroDetalhado,
        registroSelecionado,
        popupDetalhesAberto,
        setPopupDetalhesAberto,
        abrirDetalhesDoRegistro,
        materiasAgenda,
        setMateriasAgenda,
        materiasFaltando,
        setMateriasFaltando,
        selectedImages,
        setSelectedImages,
        savedImages,
        setSavedImages,
        imagesToDelete,
        setImagesToDelete,
    };

};

export default useAgendaState;
